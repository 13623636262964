import {Button, Flex, FloatButton, Space} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import React, {FC} from "react";
import {MapCloudProto} from "../proto/real_time_visualization";
import {Connect} from "../Connect";

type ZoomBarProps = {
    style?: React.CSSProperties;
    timerMapInfo: MapCloudProto.ITimerMapInfo,
};

const ZoomBar: FC<ZoomBarProps> = ({style, timerMapInfo}) => {
    return (
        <div style={style}>
            <div style={{
                position: 'relative',
            }}>
                <Flex justify={"flex-end"} vertical gap={'small'}>
                    <Space.Compact direction="vertical">
                        <Button icon={<PlusOutlined/>} onClick={() => {
                            return handleZoomIn(timerMapInfo ? timerMapInfo.zoomLevel : null);
                        }}/>
                        <Button icon={<MinusOutlined/>} onClick={() => {
                            return handleZoomOut(timerMapInfo ? timerMapInfo.zoomLevel : null);
                        }}/>
                    </Space.Compact>
                </Flex>
                <div style={{
                    padding: "0px 6px",
                    background: "white",
                    color: "black",
                    borderRadius: 2,
                    position: 'absolute',
                    bottom: '28px',
                    right: '40px',
                    opacity: 0.5,
                    fontSize: "16px",
                    whiteSpace: "nowrap"

                }}>{timerMapInfo ? timerMapInfo.zoomLevel.toFixed(1) + "级" : null}
                </div>
                <div style={{
                    padding: "0px 6px",
                    background: "white",
                    color: "black",
                    borderRadius: 2,
                    position: 'absolute',
                    bottom: '2px',
                    right: '40px',
                    opacity: 0.5,
                    fontSize: "16px",
                    whiteSpace: "nowrap"
                }}>{timerMapInfo ? getZoomString(timerMapInfo.zoomLevel) : null}
                </div>
            </div>
        </div>
    )
}

function getZoomString(zoomLevel: number): string {
    const zoomStringArray = ["", "", "2000公里", "1000公里", "500公里",
        "200公里", "100公里", "50公里", "30公里", "20公里", "10公里", "5公里", "2公里", "1公里",
        "500米", "200米", "100米", "50米", "25米", "10米", "5米", "2米", "1米"]
    const roundedIndex: number = Math.floor(zoomLevel);
    if (roundedIndex > zoomStringArray.length) {
        return null
    }

    return zoomStringArray[roundedIndex];
}

function handleZoomIn(currentZoomLevel: number) {
    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = 0;
    msg.command = MapCloudProto.CommandEnum.CommandSetZoomLevel;
    if (currentZoomLevel) {
        msg.zoomLevelData = new MapCloudProto.ZoomLevelData({
            level: Math.floor(currentZoomLevel) + 1.0,
            animate: true
        });
    } else {
        msg.zoomLevelData = new MapCloudProto.ZoomLevelData({
            increment: 1.0,
            animate: true
        });
    }
    Connect.getInstance().send(msg)
}

function handleZoomOut(currentZoomLevel: number) {
    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = 0;
    msg.command = MapCloudProto.CommandEnum.CommandSetZoomLevel;
    if (currentZoomLevel) {
        msg.zoomLevelData = new MapCloudProto.ZoomLevelData({
            level: Math.floor(currentZoomLevel) - 1.0,
            animate: true
        });
    } else {
        msg.zoomLevelData = new MapCloudProto.ZoomLevelData({
            increment: -1.0,
            animate: true
        });
    }
    Connect.getInstance().send(msg)
}

export default ZoomBar