import {Button, Divider, Flex, Popover, Segmented, Select, Space, Switch, Tooltip} from "antd";
import React, {FC, SetStateAction, useEffect, useState} from "react";
import {QuestionCircleOutlined, SettingOutlined} from "@ant-design/icons";
import {MapCloudProto} from "../proto/real_time_visualization";
import {Connect} from "../Connect";

enum BitrateEnum {
    Medium,
    Low,
    High
}

type SettingBarProps = {
    style?: React.CSSProperties;
    onResolutionChange?: () => void;
    resolutionBar?: boolean;
    settingBar?: boolean;
};

const SettingBar: FC<SettingBarProps> = (props) => {
    const [styleIsDay, setStyleIsDay] = useState<boolean>(true);
    const [bitrate, setBitrate] = useState<number>(BitrateEnum.Medium);
    const [resolution, setResolution] = useState<string>(null);
    const [tmc, setTmc] = useState<boolean>(false);

    useEffect(() => {
        // 初始分辨率
        let resolution = localStorage.getItem("resolution") || '{"width":10000,"height":10000}';
        setResolution(resolution);

        // 初始化白天黑夜
        let styleIsDayString = localStorage.getItem("styleIsDay") || 'true';
        setStyleIsDay(styleIsDayString === 'true');

        // 初始化码率
        let bitrateString = localStorage.getItem("bitrate") || BitrateEnum.Medium.toString();
        setBitrate(Number(bitrateString));

        // 初始路况
        let tmcString = localStorage.getItem("tmc") || 'false';
        setTmc(tmcString === 'true');
    }, [])
    return (
        <Space style={props.style}>
            {props.resolutionBar ?
                <Space.Compact>
                    <Button style={{padding: "4px 8px"}}>
                        <div>分辨率:</div>
                    </Button>
                    <Select
                        value={resolution}
                        style={{width: 160}}
                        onChange={handleResolutionChange.bind(null, setResolution, props.onResolutionChange)}
                        options={[
                            {value: '{"width":10000,"height":10000}', label: '全屏自适应'},
                            {
                                label: <span>pc</span>,
                                title: 'pc',
                                options: [
                                    {value: '{"width":720,"height":480}', label: '720 x 480'},
                                    {value: '{"width":1280,"height":720}', label: '1280 x 720'},
                                    {value: '{"width":1920,"height":1080}', label: '1920 x 1080'}
                                ]
                            },
                            {
                                label: <span>手机</span>,
                                title: '手机',
                                options: [
                                    {value: '{"width":402,"height":874}', label: 'iPhone16 Pro'},
                                    {value: '{"width":874,"height":402}', label: 'iPhone16 Pro横屏'}
                                ]
                            }
                        ]}
                    />
                </Space.Compact> : null}
            {props.settingBar ?
                <Popover
                    overlayInnerStyle={{margin: '0px 8px 0px 0px', minWidth: '220px'}}
                    trigger="click"
                    content={(
                        <div>
                            <Flex justify={"space-between"} align={"center"}>
                                <span>样式</span>
                                <Switch checkedChildren="白天" unCheckedChildren="黑夜"
                                        checked={styleIsDay}
                                        onChange={handleDayNightChange.bind(null, setStyleIsDay)}/>
                            </Flex>
                            <Divider style={{margin: "8px 0px"}}/>
                            <Flex justify={"space-between"} align={"center"}>
                                <span>清晰度</span>
                                <Segmented<number>
                                    value={bitrate}
                                    options={[
                                        {label: '超清', value: BitrateEnum.High},
                                        {label: '高清', value: BitrateEnum.Medium},
                                        {label: '普通', value: BitrateEnum.Low}
                                    ]}
                                    onChange={handleBitrateChange.bind(null, setBitrate)}
                                />
                            </Flex>
                            <Divider style={{margin: "8px 0px"}}/>
                            <Flex justify={"space-between"} align={"center"}>
                                <span>路况</span>
                                <Switch
                                    checked={tmc}
                                    onChange={handleTmcChange.bind(null, setTmc)}/>
                            </Flex>
                        </div>)} arrow={false}>
                    <Button icon={<SettingOutlined/>} style={{padding: "4px 8px"}}>
                        设置
                    </Button>
                </Popover> : null}
        </Space>
    )
}

function handleDayNightChange(setStyleIsDay: React.Dispatch<SetStateAction<boolean>>, checked: boolean) {
    setStyleIsDay(checked);
    localStorage.setItem("styleIsDay", String(checked));

    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = 0;
    msg.command = MapCloudProto.CommandEnum.CommandSetMapModeStateTime;
    msg.setMapModeStateTime = new MapCloudProto.SetMapModeStateTime({
        mapMode: 0,
        mapState: 0,
        mapTime: checked ? 0 : 1
    });
    Connect.getInstance().send(msg)
}

function handleBitrateChange(setBitrate: React.Dispatch<SetStateAction<number>>, value: number) {
    setBitrate(Number(value));
    localStorage.setItem("bitrate", String(value));

    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = 0;
    msg.command = MapCloudProto.CommandEnum.CommandSetWebRtcParameter;
    msg.webRtcParameterData = new MapCloudProto.SetWebRtcParameterData({
        fluency: value,
    });
    Connect.getInstance().send(msg)
}

function handleResolutionChange(setResolution: React.Dispatch<SetStateAction<string>>,
                                onResolutionChange: () => void,
                                value: string) {
    setResolution(value);
    localStorage.setItem("resolution", value);

    if (onResolutionChange) {
        onResolutionChange();
    }

}

function handleTmcChange(setTmc: React.Dispatch<SetStateAction<boolean>>, checked: boolean) {
    setTmc(checked);
    localStorage.setItem("tmc", String(checked));

    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = 0;
    msg.command = MapCloudProto.CommandEnum.CommandSetControllerStates;
    msg.mapControllerData = new MapCloudProto.MapControllerStates({
        type: MapCloudProto.MapControllerStatesType.MAP_CONTROLLER_ONOFF_TRAFFIC_STATE,
        value: checked ? 1 : 0
    });
    Connect.getInstance().send(msg)
}


export default SettingBar