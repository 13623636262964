/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const MapCloudProto = $root.MapCloudProto = (() => {

    /**
     * Namespace MapCloudProto.
     * @exports MapCloudProto
     * @namespace
     */
    const MapCloudProto = {};

    /**
     * CommandEnum enum.
     * @name MapCloudProto.CommandEnum
     * @enum {number}
     * @property {number} CommandLogin=0 CommandLogin value
     * @property {number} CommandSetMapMode=1 CommandSetMapMode value
     * @property {number} CommandSetMapCenter=2 CommandSetMapCenter value
     * @property {number} CommandSetMapZoomIn=3 CommandSetMapZoomIn value
     * @property {number} CommandSetMapZoomOut=4 CommandSetMapZoomOut value
     * @property {number} CommandUpdateStyle=5 CommandUpdateStyle value
     * @property {number} CommandUpdateIcon=6 CommandUpdateIcon value
     * @property {number} CommandZoomTo=7 CommandZoomTo value
     * @property {number} CommandSurfaceChange=8 CommandSurfaceChange value
     * @property {number} CommandMouseEvent=9 CommandMouseEvent value
     * @property {number} CommandSetMapParameter=10 CommandSetMapParameter value
     * @property {number} CommandRequestParameter=12 CommandRequestParameter value
     * @property {number} CommandMapBaseInfo=13 CommandMapBaseInfo value
     * @property {number} CommandLogout=14 CommandLogout value
     * @property {number} CommandGesture=15 CommandGesture value
     * @property {number} CommandConfigUpdate=16 CommandConfigUpdate value
     * @property {number} CommandModifyModelProperties=17 CommandModifyModelProperties value
     * @property {number} CommandSetControllerStates=18 CommandSetControllerStates value
     * @property {number} CommandSetWebRtcParameter=19 CommandSetWebRtcParameter value
     * @property {number} CommandSetMapStatus=20 CommandSetMapStatus value
     * @property {number} CommandSetShellCmd=21 CommandSetShellCmd value
     * @property {number} CommandWebRtcOffer=22 CommandWebRtcOffer value
     * @property {number} CommandWebRtcAnswer=23 CommandWebRtcAnswer value
     * @property {number} CommandWebRtcCandidateFromService=24 CommandWebRtcCandidateFromService value
     * @property {number} CommandWebRtcCandidateFromClient=25 CommandWebRtcCandidateFromClient value
     * @property {number} CommandSetZoomLevel=30 CommandSetZoomLevel value
     * @property {number} CommandSetPitchAngle=31 CommandSetPitchAngle value
     * @property {number} CommandSetRollAngle=32 CommandSetRollAngle value
     * @property {number} CommandSetMapModeStateTime=33 CommandSetMapModeStateTime value
     * @property {number} CommandImmersiveBusiness=53 CommandImmersiveBusiness value
     * @property {number} CommandOssKeyResponse=100 CommandOssKeyResponse value
     * @property {number} CommandImmersiveResponse=1053 CommandImmersiveResponse value
     * @property {number} CommandOssKeyRequest=1100 CommandOssKeyRequest value
     * @property {number} CommandSendVideo=2001 CommandSendVideo value
     * @property {number} CommandConsumerFocus=2002 CommandConsumerFocus value
     * @property {number} CommandTimerMapInfo=2003 CommandTimerMapInfo value
     * @property {number} CommandLog=2004 CommandLog value
     */
    MapCloudProto.CommandEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CommandLogin"] = 0;
        values[valuesById[1] = "CommandSetMapMode"] = 1;
        values[valuesById[2] = "CommandSetMapCenter"] = 2;
        values[valuesById[3] = "CommandSetMapZoomIn"] = 3;
        values[valuesById[4] = "CommandSetMapZoomOut"] = 4;
        values[valuesById[5] = "CommandUpdateStyle"] = 5;
        values[valuesById[6] = "CommandUpdateIcon"] = 6;
        values[valuesById[7] = "CommandZoomTo"] = 7;
        values[valuesById[8] = "CommandSurfaceChange"] = 8;
        values[valuesById[9] = "CommandMouseEvent"] = 9;
        values[valuesById[10] = "CommandSetMapParameter"] = 10;
        values[valuesById[12] = "CommandRequestParameter"] = 12;
        values[valuesById[13] = "CommandMapBaseInfo"] = 13;
        values[valuesById[14] = "CommandLogout"] = 14;
        values[valuesById[15] = "CommandGesture"] = 15;
        values[valuesById[16] = "CommandConfigUpdate"] = 16;
        values[valuesById[17] = "CommandModifyModelProperties"] = 17;
        values[valuesById[18] = "CommandSetControllerStates"] = 18;
        values[valuesById[19] = "CommandSetWebRtcParameter"] = 19;
        values[valuesById[20] = "CommandSetMapStatus"] = 20;
        values[valuesById[21] = "CommandSetShellCmd"] = 21;
        values[valuesById[22] = "CommandWebRtcOffer"] = 22;
        values[valuesById[23] = "CommandWebRtcAnswer"] = 23;
        values[valuesById[24] = "CommandWebRtcCandidateFromService"] = 24;
        values[valuesById[25] = "CommandWebRtcCandidateFromClient"] = 25;
        values[valuesById[30] = "CommandSetZoomLevel"] = 30;
        values[valuesById[31] = "CommandSetPitchAngle"] = 31;
        values[valuesById[32] = "CommandSetRollAngle"] = 32;
        values[valuesById[33] = "CommandSetMapModeStateTime"] = 33;
        values[valuesById[53] = "CommandImmersiveBusiness"] = 53;
        values[valuesById[100] = "CommandOssKeyResponse"] = 100;
        values[valuesById[1053] = "CommandImmersiveResponse"] = 1053;
        values[valuesById[1100] = "CommandOssKeyRequest"] = 1100;
        values[valuesById[2001] = "CommandSendVideo"] = 2001;
        values[valuesById[2002] = "CommandConsumerFocus"] = 2002;
        values[valuesById[2003] = "CommandTimerMapInfo"] = 2003;
        values[valuesById[2004] = "CommandLog"] = 2004;
        return values;
    })();

    /**
     * ServiceEnum enum.
     * @name MapCloudProto.ServiceEnum
     * @enum {number}
     * @property {number} ServiceMap=0 ServiceMap value
     */
    MapCloudProto.ServiceEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ServiceMap"] = 0;
        return values;
    })();

    /**
     * PlatformEnum enum.
     * @name MapCloudProto.PlatformEnum
     * @enum {number}
     * @property {number} PlatformUnknown=0 PlatformUnknown value
     * @property {number} PlatformIos=1 PlatformIos value
     * @property {number} PlatformAndroid=2 PlatformAndroid value
     * @property {number} PlatformPc=3 PlatformPc value
     */
    MapCloudProto.PlatformEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PlatformUnknown"] = 0;
        values[valuesById[1] = "PlatformIos"] = 1;
        values[valuesById[2] = "PlatformAndroid"] = 2;
        values[valuesById[3] = "PlatformPc"] = 3;
        return values;
    })();

    /**
     * StyleTypeEnum enum.
     * @name MapCloudProto.StyleTypeEnum
     * @enum {number}
     * @property {number} StyleTypeNormal=0 StyleTypeNormal value
     * @property {number} StyleTypeSenior=1 StyleTypeSenior value
     */
    MapCloudProto.StyleTypeEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "StyleTypeNormal"] = 0;
        values[valuesById[1] = "StyleTypeSenior"] = 1;
        return values;
    })();

    /**
     * MouseEventTypeEnum enum.
     * @name MapCloudProto.MouseEventTypeEnum
     * @enum {number}
     * @property {number} Down=0 Down value
     * @property {number} Up=1 Up value
     * @property {number} Drag=2 Drag value
     * @property {number} Enter=3 Enter value
     * @property {number} Leave=4 Leave value
     * @property {number} Move=5 Move value
     */
    MapCloudProto.MouseEventTypeEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Down"] = 0;
        values[valuesById[1] = "Up"] = 1;
        values[valuesById[2] = "Drag"] = 2;
        values[valuesById[3] = "Enter"] = 3;
        values[valuesById[4] = "Leave"] = 4;
        values[valuesById[5] = "Move"] = 5;
        return values;
    })();

    /**
     * MouseButtonEnum enum.
     * @name MapCloudProto.MouseButtonEnum
     * @enum {number}
     * @property {number} MOUSE_BUTTON_LEFT=0 MOUSE_BUTTON_LEFT value
     * @property {number} MOUSE_BUTTON_RIGHT=1 MOUSE_BUTTON_RIGHT value
     */
    MapCloudProto.MouseButtonEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MOUSE_BUTTON_LEFT"] = 0;
        values[valuesById[1] = "MOUSE_BUTTON_RIGHT"] = 1;
        return values;
    })();

    /**
     * RequestParameterEnum enum.
     * @name MapCloudProto.RequestParameterEnum
     * @enum {number}
     * @property {number} RequestMapBaseInfo=0 RequestMapBaseInfo value
     */
    MapCloudProto.RequestParameterEnum = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RequestMapBaseInfo"] = 0;
        return values;
    })();

    /**
     * ImmersiveRecordStatus enum.
     * @name MapCloudProto.ImmersiveRecordStatus
     * @enum {number}
     * @property {number} Begin=0 Begin value
     * @property {number} Doing=1 Doing value
     * @property {number} End=2 End value
     * @property {number} Error=3 Error value
     */
    MapCloudProto.ImmersiveRecordStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Begin"] = 0;
        values[valuesById[1] = "Doing"] = 1;
        values[valuesById[2] = "End"] = 2;
        values[valuesById[3] = "Error"] = 3;
        return values;
    })();

    /**
     * MapGestureType enum.
     * @name MapCloudProto.MapGestureType
     * @enum {number}
     * @property {number} MapGestureTypeNone=0 MapGestureTypeNone value
     * @property {number} MapGestureTypeTapZoomIn=1 MapGestureTypeTapZoomIn value
     * @property {number} MapGestureTypeTapZoomOut=2 MapGestureTypeTapZoomOut value
     * @property {number} MapGestureTypeMove=3 MapGestureTypeMove value
     * @property {number} MapGestureTypePinchZoom=4 MapGestureTypePinchZoom value
     * @property {number} MapGestureTypeRotation=5 MapGestureTypeRotation value
     * @property {number} MapGestureTypeCameraHeader=6 MapGestureTypeCameraHeader value
     * @property {number} MapGestureTypeLongPress=7 MapGestureTypeLongPress value
     * @property {number} MapGestureTypeSingleTap=8 MapGestureTypeSingleTap value
     * @property {number} MapGestureTypeSingleZoom=9 MapGestureTypeSingleZoom value
     */
    MapCloudProto.MapGestureType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MapGestureTypeNone"] = 0;
        values[valuesById[1] = "MapGestureTypeTapZoomIn"] = 1;
        values[valuesById[2] = "MapGestureTypeTapZoomOut"] = 2;
        values[valuesById[3] = "MapGestureTypeMove"] = 3;
        values[valuesById[4] = "MapGestureTypePinchZoom"] = 4;
        values[valuesById[5] = "MapGestureTypeRotation"] = 5;
        values[valuesById[6] = "MapGestureTypeCameraHeader"] = 6;
        values[valuesById[7] = "MapGestureTypeLongPress"] = 7;
        values[valuesById[8] = "MapGestureTypeSingleTap"] = 8;
        values[valuesById[9] = "MapGestureTypeSingleZoom"] = 9;
        return values;
    })();

    /**
     * MapGestureState enum.
     * @name MapCloudProto.MapGestureState
     * @enum {number}
     * @property {number} MapGestureStatePossible=0 MapGestureStatePossible value
     * @property {number} MapGestureStateBegan=1 MapGestureStateBegan value
     * @property {number} MapGestureStateChanged=2 MapGestureStateChanged value
     * @property {number} MapGestureStateEnded=3 MapGestureStateEnded value
     * @property {number} MapGestureStateCancelled=4 MapGestureStateCancelled value
     * @property {number} MapGestureStateFailed=5 MapGestureStateFailed value
     * @property {number} MapGestureStateRecognized=6 MapGestureStateRecognized value
     */
    MapCloudProto.MapGestureState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MapGestureStatePossible"] = 0;
        values[valuesById[1] = "MapGestureStateBegan"] = 1;
        values[valuesById[2] = "MapGestureStateChanged"] = 2;
        values[valuesById[3] = "MapGestureStateEnded"] = 3;
        values[valuesById[4] = "MapGestureStateCancelled"] = 4;
        values[valuesById[5] = "MapGestureStateFailed"] = 5;
        values[valuesById[6] = "MapGestureStateRecognized"] = 6;
        return values;
    })();

    /**
     * MapControllerStatesType enum.
     * @name MapCloudProto.MapControllerStatesType
     * @enum {number}
     * @property {number} MAP_CONTROLLER_ONOFF_TRAFFIC_STATE=1 MAP_CONTROLLER_ONOFF_TRAFFIC_STATE value
     * @property {number} MAP_CONTROLLER_TRAFFIC_HIGHLIGHT=2 MAP_CONTROLLER_TRAFFIC_HIGHLIGHT value
     * @property {number} MAP_CONTROLLER_TRAFFIC_COLORBLIND=3 MAP_CONTROLLER_TRAFFIC_COLORBLIND value
     */
    MapCloudProto.MapControllerStatesType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "MAP_CONTROLLER_ONOFF_TRAFFIC_STATE"] = 1;
        values[valuesById[2] = "MAP_CONTROLLER_TRAFFIC_HIGHLIGHT"] = 2;
        values[valuesById[3] = "MAP_CONTROLLER_TRAFFIC_COLORBLIND"] = 3;
        return values;
    })();

    /**
     * MapParameterDataId enum.
     * @name MapCloudProto.MapParameterDataId
     * @enum {number}
     * @property {number} MAP_CONTROLLER_LOGIC_FUNCTION=0 MAP_CONTROLLER_LOGIC_FUNCTION value
     * @property {number} MAP_CONTROLLER_PARAMETER=1 MAP_CONTROLLER_PARAMETER value
     * @property {number} SRS_CONTROLLER_PARAMETER=2 SRS_CONTROLLER_PARAMETER value
     */
    MapCloudProto.MapParameterDataId = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MAP_CONTROLLER_LOGIC_FUNCTION"] = 0;
        values[valuesById[1] = "MAP_CONTROLLER_PARAMETER"] = 1;
        values[valuesById[2] = "SRS_CONTROLLER_PARAMETER"] = 2;
        return values;
    })();

    /**
     * MapParameterDataType enum.
     * @name MapCloudProto.MapParameterDataType
     * @enum {number}
     * @property {number} RENDER=0 RENDER value
     * @property {number} FPS_SHOW_ON_OFF=1 FPS_SHOW_ON_OFF value
     * @property {number} PROJ_CENTER=2 PROJ_CENTER value
     * @property {number} PROJ_CENTER_PERCENT=3 PROJ_CENTER_PERCENT value
     */
    MapCloudProto.MapParameterDataType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RENDER"] = 0;
        values[valuesById[1] = "FPS_SHOW_ON_OFF"] = 1;
        values[valuesById[2] = "PROJ_CENTER"] = 2;
        values[valuesById[3] = "PROJ_CENTER_PERCENT"] = 3;
        return values;
    })();

    /**
     * SRSParameterDataType enum.
     * @name MapCloudProto.SRSParameterDataType
     * @enum {number}
     * @property {number} SRS_STREAM_STATUS=0 SRS_STREAM_STATUS value
     */
    MapCloudProto.SRSParameterDataType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SRS_STREAM_STATUS"] = 0;
        return values;
    })();

    MapCloudProto.DeviceInfo = (function() {

        /**
         * Properties of a DeviceInfo.
         * @memberof MapCloudProto
         * @interface IDeviceInfo
         * @property {MapCloudProto.PlatformEnum} platform DeviceInfo platform
         * @property {string|null} [name] DeviceInfo name
         * @property {string|null} [extData] DeviceInfo extData
         * @property {string|null} [hardwareJsonParam] DeviceInfo hardwareJsonParam
         * @property {number|null} [deviceLevel] DeviceInfo deviceLevel
         * @property {boolean|null} [isSceneLightOpen] DeviceInfo isSceneLightOpen
         * @property {boolean|null} [lightStatus] DeviceInfo lightStatus
         * @property {boolean|null} [cloudStatus] DeviceInfo cloudStatus
         * @property {boolean|null} [reSetPlatform] DeviceInfo reSetPlatform
         */

        /**
         * Constructs a new DeviceInfo.
         * @memberof MapCloudProto
         * @classdesc Represents a DeviceInfo.
         * @implements IDeviceInfo
         * @constructor
         * @param {MapCloudProto.IDeviceInfo=} [properties] Properties to set
         */
        function DeviceInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeviceInfo platform.
         * @member {MapCloudProto.PlatformEnum} platform
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.platform = 0;

        /**
         * DeviceInfo name.
         * @member {string} name
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.name = "";

        /**
         * DeviceInfo extData.
         * @member {string} extData
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.extData = "";

        /**
         * DeviceInfo hardwareJsonParam.
         * @member {string} hardwareJsonParam
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.hardwareJsonParam = "";

        /**
         * DeviceInfo deviceLevel.
         * @member {number} deviceLevel
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.deviceLevel = 0;

        /**
         * DeviceInfo isSceneLightOpen.
         * @member {boolean} isSceneLightOpen
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.isSceneLightOpen = false;

        /**
         * DeviceInfo lightStatus.
         * @member {boolean} lightStatus
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.lightStatus = false;

        /**
         * DeviceInfo cloudStatus.
         * @member {boolean} cloudStatus
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.cloudStatus = false;

        /**
         * DeviceInfo reSetPlatform.
         * @member {boolean} reSetPlatform
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         */
        DeviceInfo.prototype.reSetPlatform = false;

        /**
         * Creates a new DeviceInfo instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {MapCloudProto.IDeviceInfo=} [properties] Properties to set
         * @returns {MapCloudProto.DeviceInfo} DeviceInfo instance
         */
        DeviceInfo.create = function create(properties) {
            return new DeviceInfo(properties);
        };

        /**
         * Encodes the specified DeviceInfo message. Does not implicitly {@link MapCloudProto.DeviceInfo.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {MapCloudProto.IDeviceInfo} message DeviceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeviceInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.platform);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.extData != null && Object.hasOwnProperty.call(message, "extData"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.extData);
            if (message.hardwareJsonParam != null && Object.hasOwnProperty.call(message, "hardwareJsonParam"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.hardwareJsonParam);
            if (message.deviceLevel != null && Object.hasOwnProperty.call(message, "deviceLevel"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.deviceLevel);
            if (message.isSceneLightOpen != null && Object.hasOwnProperty.call(message, "isSceneLightOpen"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isSceneLightOpen);
            if (message.lightStatus != null && Object.hasOwnProperty.call(message, "lightStatus"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.lightStatus);
            if (message.cloudStatus != null && Object.hasOwnProperty.call(message, "cloudStatus"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.cloudStatus);
            if (message.reSetPlatform != null && Object.hasOwnProperty.call(message, "reSetPlatform"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.reSetPlatform);
            return writer;
        };

        /**
         * Encodes the specified DeviceInfo message, length delimited. Does not implicitly {@link MapCloudProto.DeviceInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {MapCloudProto.IDeviceInfo} message DeviceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeviceInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeviceInfo message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.DeviceInfo} DeviceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeviceInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.DeviceInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.platform = reader.int32();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.extData = reader.string();
                        break;
                    }
                case 4: {
                        message.hardwareJsonParam = reader.string();
                        break;
                    }
                case 5: {
                        message.deviceLevel = reader.int32();
                        break;
                    }
                case 6: {
                        message.isSceneLightOpen = reader.bool();
                        break;
                    }
                case 7: {
                        message.lightStatus = reader.bool();
                        break;
                    }
                case 8: {
                        message.cloudStatus = reader.bool();
                        break;
                    }
                case 9: {
                        message.reSetPlatform = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("platform"))
                throw $util.ProtocolError("missing required 'platform'", { instance: message });
            return message;
        };

        /**
         * Decodes a DeviceInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.DeviceInfo} DeviceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeviceInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeviceInfo message.
         * @function verify
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeviceInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.platform) {
            default:
                return "platform: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.extData != null && message.hasOwnProperty("extData"))
                if (!$util.isString(message.extData))
                    return "extData: string expected";
            if (message.hardwareJsonParam != null && message.hasOwnProperty("hardwareJsonParam"))
                if (!$util.isString(message.hardwareJsonParam))
                    return "hardwareJsonParam: string expected";
            if (message.deviceLevel != null && message.hasOwnProperty("deviceLevel"))
                if (!$util.isInteger(message.deviceLevel))
                    return "deviceLevel: integer expected";
            if (message.isSceneLightOpen != null && message.hasOwnProperty("isSceneLightOpen"))
                if (typeof message.isSceneLightOpen !== "boolean")
                    return "isSceneLightOpen: boolean expected";
            if (message.lightStatus != null && message.hasOwnProperty("lightStatus"))
                if (typeof message.lightStatus !== "boolean")
                    return "lightStatus: boolean expected";
            if (message.cloudStatus != null && message.hasOwnProperty("cloudStatus"))
                if (typeof message.cloudStatus !== "boolean")
                    return "cloudStatus: boolean expected";
            if (message.reSetPlatform != null && message.hasOwnProperty("reSetPlatform"))
                if (typeof message.reSetPlatform !== "boolean")
                    return "reSetPlatform: boolean expected";
            return null;
        };

        /**
         * Creates a DeviceInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.DeviceInfo} DeviceInfo
         */
        DeviceInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.DeviceInfo)
                return object;
            let message = new $root.MapCloudProto.DeviceInfo();
            switch (object.platform) {
            default:
                if (typeof object.platform === "number") {
                    message.platform = object.platform;
                    break;
                }
                break;
            case "PlatformUnknown":
            case 0:
                message.platform = 0;
                break;
            case "PlatformIos":
            case 1:
                message.platform = 1;
                break;
            case "PlatformAndroid":
            case 2:
                message.platform = 2;
                break;
            case "PlatformPc":
            case 3:
                message.platform = 3;
                break;
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.extData != null)
                message.extData = String(object.extData);
            if (object.hardwareJsonParam != null)
                message.hardwareJsonParam = String(object.hardwareJsonParam);
            if (object.deviceLevel != null)
                message.deviceLevel = object.deviceLevel | 0;
            if (object.isSceneLightOpen != null)
                message.isSceneLightOpen = Boolean(object.isSceneLightOpen);
            if (object.lightStatus != null)
                message.lightStatus = Boolean(object.lightStatus);
            if (object.cloudStatus != null)
                message.cloudStatus = Boolean(object.cloudStatus);
            if (object.reSetPlatform != null)
                message.reSetPlatform = Boolean(object.reSetPlatform);
            return message;
        };

        /**
         * Creates a plain object from a DeviceInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {MapCloudProto.DeviceInfo} message DeviceInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeviceInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.platform = options.enums === String ? "PlatformUnknown" : 0;
                object.name = "";
                object.extData = "";
                object.hardwareJsonParam = "";
                object.deviceLevel = 0;
                object.isSceneLightOpen = false;
                object.lightStatus = false;
                object.cloudStatus = false;
                object.reSetPlatform = false;
            }
            if (message.platform != null && message.hasOwnProperty("platform"))
                object.platform = options.enums === String ? $root.MapCloudProto.PlatformEnum[message.platform] === undefined ? message.platform : $root.MapCloudProto.PlatformEnum[message.platform] : message.platform;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.extData != null && message.hasOwnProperty("extData"))
                object.extData = message.extData;
            if (message.hardwareJsonParam != null && message.hasOwnProperty("hardwareJsonParam"))
                object.hardwareJsonParam = message.hardwareJsonParam;
            if (message.deviceLevel != null && message.hasOwnProperty("deviceLevel"))
                object.deviceLevel = message.deviceLevel;
            if (message.isSceneLightOpen != null && message.hasOwnProperty("isSceneLightOpen"))
                object.isSceneLightOpen = message.isSceneLightOpen;
            if (message.lightStatus != null && message.hasOwnProperty("lightStatus"))
                object.lightStatus = message.lightStatus;
            if (message.cloudStatus != null && message.hasOwnProperty("cloudStatus"))
                object.cloudStatus = message.cloudStatus;
            if (message.reSetPlatform != null && message.hasOwnProperty("reSetPlatform"))
                object.reSetPlatform = message.reSetPlatform;
            return object;
        };

        /**
         * Converts this DeviceInfo to JSON.
         * @function toJSON
         * @memberof MapCloudProto.DeviceInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeviceInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeviceInfo
         * @function getTypeUrl
         * @memberof MapCloudProto.DeviceInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeviceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.DeviceInfo";
        };

        return DeviceInfo;
    })();

    MapCloudProto.TimeStampData = (function() {

        /**
         * Properties of a TimeStampData.
         * @memberof MapCloudProto
         * @interface ITimeStampData
         * @property {Array.<MapCloudProto.ITimeStampInfoData>|null} [timeInfo] TimeStampData timeInfo
         */

        /**
         * Constructs a new TimeStampData.
         * @memberof MapCloudProto
         * @classdesc Represents a TimeStampData.
         * @implements ITimeStampData
         * @constructor
         * @param {MapCloudProto.ITimeStampData=} [properties] Properties to set
         */
        function TimeStampData(properties) {
            this.timeInfo = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimeStampData timeInfo.
         * @member {Array.<MapCloudProto.ITimeStampInfoData>} timeInfo
         * @memberof MapCloudProto.TimeStampData
         * @instance
         */
        TimeStampData.prototype.timeInfo = $util.emptyArray;

        /**
         * Creates a new TimeStampData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {MapCloudProto.ITimeStampData=} [properties] Properties to set
         * @returns {MapCloudProto.TimeStampData} TimeStampData instance
         */
        TimeStampData.create = function create(properties) {
            return new TimeStampData(properties);
        };

        /**
         * Encodes the specified TimeStampData message. Does not implicitly {@link MapCloudProto.TimeStampData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {MapCloudProto.ITimeStampData} message TimeStampData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeStampData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeInfo != null && message.timeInfo.length)
                for (let i = 0; i < message.timeInfo.length; ++i)
                    $root.MapCloudProto.TimeStampInfoData.encode(message.timeInfo[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TimeStampData message, length delimited. Does not implicitly {@link MapCloudProto.TimeStampData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {MapCloudProto.ITimeStampData} message TimeStampData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeStampData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeStampData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.TimeStampData} TimeStampData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeStampData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.TimeStampData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.timeInfo && message.timeInfo.length))
                            message.timeInfo = [];
                        message.timeInfo.push($root.MapCloudProto.TimeStampInfoData.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimeStampData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.TimeStampData} TimeStampData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeStampData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimeStampData message.
         * @function verify
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimeStampData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeInfo != null && message.hasOwnProperty("timeInfo")) {
                if (!Array.isArray(message.timeInfo))
                    return "timeInfo: array expected";
                for (let i = 0; i < message.timeInfo.length; ++i) {
                    let error = $root.MapCloudProto.TimeStampInfoData.verify(message.timeInfo[i]);
                    if (error)
                        return "timeInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates a TimeStampData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.TimeStampData} TimeStampData
         */
        TimeStampData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.TimeStampData)
                return object;
            let message = new $root.MapCloudProto.TimeStampData();
            if (object.timeInfo) {
                if (!Array.isArray(object.timeInfo))
                    throw TypeError(".MapCloudProto.TimeStampData.timeInfo: array expected");
                message.timeInfo = [];
                for (let i = 0; i < object.timeInfo.length; ++i) {
                    if (typeof object.timeInfo[i] !== "object")
                        throw TypeError(".MapCloudProto.TimeStampData.timeInfo: object expected");
                    message.timeInfo[i] = $root.MapCloudProto.TimeStampInfoData.fromObject(object.timeInfo[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TimeStampData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {MapCloudProto.TimeStampData} message TimeStampData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimeStampData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.timeInfo = [];
            if (message.timeInfo && message.timeInfo.length) {
                object.timeInfo = [];
                for (let j = 0; j < message.timeInfo.length; ++j)
                    object.timeInfo[j] = $root.MapCloudProto.TimeStampInfoData.toObject(message.timeInfo[j], options);
            }
            return object;
        };

        /**
         * Converts this TimeStampData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.TimeStampData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimeStampData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TimeStampData
         * @function getTypeUrl
         * @memberof MapCloudProto.TimeStampData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TimeStampData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.TimeStampData";
        };

        return TimeStampData;
    })();

    MapCloudProto.TimeStampInfoData = (function() {

        /**
         * Properties of a TimeStampInfoData.
         * @memberof MapCloudProto
         * @interface ITimeStampInfoData
         * @property {string} node TimeStampInfoData node
         * @property {number|Long} timestamp TimeStampInfoData timestamp
         */

        /**
         * Constructs a new TimeStampInfoData.
         * @memberof MapCloudProto
         * @classdesc Represents a TimeStampInfoData.
         * @implements ITimeStampInfoData
         * @constructor
         * @param {MapCloudProto.ITimeStampInfoData=} [properties] Properties to set
         */
        function TimeStampInfoData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimeStampInfoData node.
         * @member {string} node
         * @memberof MapCloudProto.TimeStampInfoData
         * @instance
         */
        TimeStampInfoData.prototype.node = "";

        /**
         * TimeStampInfoData timestamp.
         * @member {number|Long} timestamp
         * @memberof MapCloudProto.TimeStampInfoData
         * @instance
         */
        TimeStampInfoData.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new TimeStampInfoData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {MapCloudProto.ITimeStampInfoData=} [properties] Properties to set
         * @returns {MapCloudProto.TimeStampInfoData} TimeStampInfoData instance
         */
        TimeStampInfoData.create = function create(properties) {
            return new TimeStampInfoData(properties);
        };

        /**
         * Encodes the specified TimeStampInfoData message. Does not implicitly {@link MapCloudProto.TimeStampInfoData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {MapCloudProto.ITimeStampInfoData} message TimeStampInfoData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeStampInfoData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.node);
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestamp);
            return writer;
        };

        /**
         * Encodes the specified TimeStampInfoData message, length delimited. Does not implicitly {@link MapCloudProto.TimeStampInfoData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {MapCloudProto.ITimeStampInfoData} message TimeStampInfoData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeStampInfoData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeStampInfoData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.TimeStampInfoData} TimeStampInfoData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeStampInfoData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.TimeStampInfoData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.node = reader.string();
                        break;
                    }
                case 2: {
                        message.timestamp = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("node"))
                throw $util.ProtocolError("missing required 'node'", { instance: message });
            if (!message.hasOwnProperty("timestamp"))
                throw $util.ProtocolError("missing required 'timestamp'", { instance: message });
            return message;
        };

        /**
         * Decodes a TimeStampInfoData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.TimeStampInfoData} TimeStampInfoData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeStampInfoData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimeStampInfoData message.
         * @function verify
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimeStampInfoData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.node))
                return "node: string expected";
            if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                return "timestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a TimeStampInfoData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.TimeStampInfoData} TimeStampInfoData
         */
        TimeStampInfoData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.TimeStampInfoData)
                return object;
            let message = new $root.MapCloudProto.TimeStampInfoData();
            if (object.node != null)
                message.node = String(object.node);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TimeStampInfoData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {MapCloudProto.TimeStampInfoData} message TimeStampInfoData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimeStampInfoData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.node = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
            }
            if (message.node != null && message.hasOwnProperty("node"))
                object.node = message.node;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            return object;
        };

        /**
         * Converts this TimeStampInfoData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.TimeStampInfoData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimeStampInfoData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TimeStampInfoData
         * @function getTypeUrl
         * @memberof MapCloudProto.TimeStampInfoData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TimeStampInfoData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.TimeStampInfoData";
        };

        return TimeStampInfoData;
    })();

    MapCloudProto.LoginData = (function() {

        /**
         * Properties of a LoginData.
         * @memberof MapCloudProto
         * @interface ILoginData
         * @property {string} sessionId LoginData sessionId
         * @property {MapCloudProto.IDeviceInfo} deviceInfo LoginData deviceInfo
         * @property {number|Long} sceneFileId LoginData sceneFileId
         * @property {string} userName LoginData userName
         * @property {string} channel LoginData channel
         * @property {number|null} [timeConfig] LoginData timeConfig
         */

        /**
         * Constructs a new LoginData.
         * @memberof MapCloudProto
         * @classdesc Represents a LoginData.
         * @implements ILoginData
         * @constructor
         * @param {MapCloudProto.ILoginData=} [properties] Properties to set
         */
        function LoginData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginData sessionId.
         * @member {string} sessionId
         * @memberof MapCloudProto.LoginData
         * @instance
         */
        LoginData.prototype.sessionId = "";

        /**
         * LoginData deviceInfo.
         * @member {MapCloudProto.IDeviceInfo} deviceInfo
         * @memberof MapCloudProto.LoginData
         * @instance
         */
        LoginData.prototype.deviceInfo = null;

        /**
         * LoginData sceneFileId.
         * @member {number|Long} sceneFileId
         * @memberof MapCloudProto.LoginData
         * @instance
         */
        LoginData.prototype.sceneFileId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LoginData userName.
         * @member {string} userName
         * @memberof MapCloudProto.LoginData
         * @instance
         */
        LoginData.prototype.userName = "";

        /**
         * LoginData channel.
         * @member {string} channel
         * @memberof MapCloudProto.LoginData
         * @instance
         */
        LoginData.prototype.channel = "";

        /**
         * LoginData timeConfig.
         * @member {number} timeConfig
         * @memberof MapCloudProto.LoginData
         * @instance
         */
        LoginData.prototype.timeConfig = 0;

        /**
         * Creates a new LoginData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {MapCloudProto.ILoginData=} [properties] Properties to set
         * @returns {MapCloudProto.LoginData} LoginData instance
         */
        LoginData.create = function create(properties) {
            return new LoginData(properties);
        };

        /**
         * Encodes the specified LoginData message. Does not implicitly {@link MapCloudProto.LoginData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {MapCloudProto.ILoginData} message LoginData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            $root.MapCloudProto.DeviceInfo.encode(message.deviceInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.sceneFileId);
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.userName);
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.channel);
            if (message.timeConfig != null && Object.hasOwnProperty.call(message, "timeConfig"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.timeConfig);
            return writer;
        };

        /**
         * Encodes the specified LoginData message, length delimited. Does not implicitly {@link MapCloudProto.LoginData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {MapCloudProto.ILoginData} message LoginData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.LoginData} LoginData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.LoginData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.deviceInfo = $root.MapCloudProto.DeviceInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.sceneFileId = reader.int64();
                        break;
                    }
                case 4: {
                        message.userName = reader.string();
                        break;
                    }
                case 5: {
                        message.channel = reader.string();
                        break;
                    }
                case 6: {
                        message.timeConfig = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("sessionId"))
                throw $util.ProtocolError("missing required 'sessionId'", { instance: message });
            if (!message.hasOwnProperty("deviceInfo"))
                throw $util.ProtocolError("missing required 'deviceInfo'", { instance: message });
            if (!message.hasOwnProperty("sceneFileId"))
                throw $util.ProtocolError("missing required 'sceneFileId'", { instance: message });
            if (!message.hasOwnProperty("userName"))
                throw $util.ProtocolError("missing required 'userName'", { instance: message });
            if (!message.hasOwnProperty("channel"))
                throw $util.ProtocolError("missing required 'channel'", { instance: message });
            return message;
        };

        /**
         * Decodes a LoginData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.LoginData} LoginData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginData message.
         * @function verify
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.sessionId))
                return "sessionId: string expected";
            {
                let error = $root.MapCloudProto.DeviceInfo.verify(message.deviceInfo);
                if (error)
                    return "deviceInfo." + error;
            }
            if (!$util.isInteger(message.sceneFileId) && !(message.sceneFileId && $util.isInteger(message.sceneFileId.low) && $util.isInteger(message.sceneFileId.high)))
                return "sceneFileId: integer|Long expected";
            if (!$util.isString(message.userName))
                return "userName: string expected";
            if (!$util.isString(message.channel))
                return "channel: string expected";
            if (message.timeConfig != null && message.hasOwnProperty("timeConfig"))
                if (!$util.isInteger(message.timeConfig))
                    return "timeConfig: integer expected";
            return null;
        };

        /**
         * Creates a LoginData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.LoginData} LoginData
         */
        LoginData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.LoginData)
                return object;
            let message = new $root.MapCloudProto.LoginData();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.deviceInfo != null) {
                if (typeof object.deviceInfo !== "object")
                    throw TypeError(".MapCloudProto.LoginData.deviceInfo: object expected");
                message.deviceInfo = $root.MapCloudProto.DeviceInfo.fromObject(object.deviceInfo);
            }
            if (object.sceneFileId != null)
                if ($util.Long)
                    (message.sceneFileId = $util.Long.fromValue(object.sceneFileId)).unsigned = false;
                else if (typeof object.sceneFileId === "string")
                    message.sceneFileId = parseInt(object.sceneFileId, 10);
                else if (typeof object.sceneFileId === "number")
                    message.sceneFileId = object.sceneFileId;
                else if (typeof object.sceneFileId === "object")
                    message.sceneFileId = new $util.LongBits(object.sceneFileId.low >>> 0, object.sceneFileId.high >>> 0).toNumber();
            if (object.userName != null)
                message.userName = String(object.userName);
            if (object.channel != null)
                message.channel = String(object.channel);
            if (object.timeConfig != null)
                message.timeConfig = object.timeConfig | 0;
            return message;
        };

        /**
         * Creates a plain object from a LoginData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {MapCloudProto.LoginData} message LoginData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.deviceInfo = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.sceneFileId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sceneFileId = options.longs === String ? "0" : 0;
                object.userName = "";
                object.channel = "";
                object.timeConfig = 0;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.deviceInfo != null && message.hasOwnProperty("deviceInfo"))
                object.deviceInfo = $root.MapCloudProto.DeviceInfo.toObject(message.deviceInfo, options);
            if (message.sceneFileId != null && message.hasOwnProperty("sceneFileId"))
                if (typeof message.sceneFileId === "number")
                    object.sceneFileId = options.longs === String ? String(message.sceneFileId) : message.sceneFileId;
                else
                    object.sceneFileId = options.longs === String ? $util.Long.prototype.toString.call(message.sceneFileId) : options.longs === Number ? new $util.LongBits(message.sceneFileId.low >>> 0, message.sceneFileId.high >>> 0).toNumber() : message.sceneFileId;
            if (message.userName != null && message.hasOwnProperty("userName"))
                object.userName = message.userName;
            if (message.channel != null && message.hasOwnProperty("channel"))
                object.channel = message.channel;
            if (message.timeConfig != null && message.hasOwnProperty("timeConfig"))
                object.timeConfig = message.timeConfig;
            return object;
        };

        /**
         * Converts this LoginData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.LoginData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginData
         * @function getTypeUrl
         * @memberof MapCloudProto.LoginData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.LoginData";
        };

        return LoginData;
    })();

    MapCloudProto.SetMapModeData = (function() {

        /**
         * Properties of a SetMapModeData.
         * @memberof MapCloudProto
         * @interface ISetMapModeData
         * @property {number} modeId SetMapModeData modeId
         * @property {string} mapConfig SetMapModeData mapConfig
         * @property {string} eagleConfig SetMapModeData eagleConfig
         */

        /**
         * Constructs a new SetMapModeData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMapModeData.
         * @implements ISetMapModeData
         * @constructor
         * @param {MapCloudProto.ISetMapModeData=} [properties] Properties to set
         */
        function SetMapModeData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMapModeData modeId.
         * @member {number} modeId
         * @memberof MapCloudProto.SetMapModeData
         * @instance
         */
        SetMapModeData.prototype.modeId = 0;

        /**
         * SetMapModeData mapConfig.
         * @member {string} mapConfig
         * @memberof MapCloudProto.SetMapModeData
         * @instance
         */
        SetMapModeData.prototype.mapConfig = "";

        /**
         * SetMapModeData eagleConfig.
         * @member {string} eagleConfig
         * @memberof MapCloudProto.SetMapModeData
         * @instance
         */
        SetMapModeData.prototype.eagleConfig = "";

        /**
         * Creates a new SetMapModeData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {MapCloudProto.ISetMapModeData=} [properties] Properties to set
         * @returns {MapCloudProto.SetMapModeData} SetMapModeData instance
         */
        SetMapModeData.create = function create(properties) {
            return new SetMapModeData(properties);
        };

        /**
         * Encodes the specified SetMapModeData message. Does not implicitly {@link MapCloudProto.SetMapModeData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {MapCloudProto.ISetMapModeData} message SetMapModeData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapModeData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.modeId);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.mapConfig);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.eagleConfig);
            return writer;
        };

        /**
         * Encodes the specified SetMapModeData message, length delimited. Does not implicitly {@link MapCloudProto.SetMapModeData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {MapCloudProto.ISetMapModeData} message SetMapModeData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapModeData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMapModeData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMapModeData} SetMapModeData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapModeData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMapModeData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.modeId = reader.int32();
                        break;
                    }
                case 2: {
                        message.mapConfig = reader.string();
                        break;
                    }
                case 3: {
                        message.eagleConfig = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("modeId"))
                throw $util.ProtocolError("missing required 'modeId'", { instance: message });
            if (!message.hasOwnProperty("mapConfig"))
                throw $util.ProtocolError("missing required 'mapConfig'", { instance: message });
            if (!message.hasOwnProperty("eagleConfig"))
                throw $util.ProtocolError("missing required 'eagleConfig'", { instance: message });
            return message;
        };

        /**
         * Decodes a SetMapModeData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMapModeData} SetMapModeData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapModeData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMapModeData message.
         * @function verify
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMapModeData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.modeId))
                return "modeId: integer expected";
            if (!$util.isString(message.mapConfig))
                return "mapConfig: string expected";
            if (!$util.isString(message.eagleConfig))
                return "eagleConfig: string expected";
            return null;
        };

        /**
         * Creates a SetMapModeData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMapModeData} SetMapModeData
         */
        SetMapModeData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMapModeData)
                return object;
            let message = new $root.MapCloudProto.SetMapModeData();
            if (object.modeId != null)
                message.modeId = object.modeId | 0;
            if (object.mapConfig != null)
                message.mapConfig = String(object.mapConfig);
            if (object.eagleConfig != null)
                message.eagleConfig = String(object.eagleConfig);
            return message;
        };

        /**
         * Creates a plain object from a SetMapModeData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {MapCloudProto.SetMapModeData} message SetMapModeData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMapModeData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.modeId = 0;
                object.mapConfig = "";
                object.eagleConfig = "";
            }
            if (message.modeId != null && message.hasOwnProperty("modeId"))
                object.modeId = message.modeId;
            if (message.mapConfig != null && message.hasOwnProperty("mapConfig"))
                object.mapConfig = message.mapConfig;
            if (message.eagleConfig != null && message.hasOwnProperty("eagleConfig"))
                object.eagleConfig = message.eagleConfig;
            return object;
        };

        /**
         * Converts this SetMapModeData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMapModeData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMapModeData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMapModeData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMapModeData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMapModeData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMapModeData";
        };

        return SetMapModeData;
    })();

    MapCloudProto.SetMapCenterData = (function() {

        /**
         * Properties of a SetMapCenterData.
         * @memberof MapCloudProto
         * @interface ISetMapCenterData
         * @property {number} lon SetMapCenterData lon
         * @property {number} lat SetMapCenterData lat
         * @property {number|null} [level] SetMapCenterData level
         * @property {number|null} [rollAngle] SetMapCenterData rollAngle
         * @property {number|null} [pitchAngle] SetMapCenterData pitchAngle
         * @property {boolean|null} [bAnimation] SetMapCenterData bAnimation
         */

        /**
         * Constructs a new SetMapCenterData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMapCenterData.
         * @implements ISetMapCenterData
         * @constructor
         * @param {MapCloudProto.ISetMapCenterData=} [properties] Properties to set
         */
        function SetMapCenterData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMapCenterData lon.
         * @member {number} lon
         * @memberof MapCloudProto.SetMapCenterData
         * @instance
         */
        SetMapCenterData.prototype.lon = 0;

        /**
         * SetMapCenterData lat.
         * @member {number} lat
         * @memberof MapCloudProto.SetMapCenterData
         * @instance
         */
        SetMapCenterData.prototype.lat = 0;

        /**
         * SetMapCenterData level.
         * @member {number} level
         * @memberof MapCloudProto.SetMapCenterData
         * @instance
         */
        SetMapCenterData.prototype.level = 0;

        /**
         * SetMapCenterData rollAngle.
         * @member {number} rollAngle
         * @memberof MapCloudProto.SetMapCenterData
         * @instance
         */
        SetMapCenterData.prototype.rollAngle = 0;

        /**
         * SetMapCenterData pitchAngle.
         * @member {number} pitchAngle
         * @memberof MapCloudProto.SetMapCenterData
         * @instance
         */
        SetMapCenterData.prototype.pitchAngle = 0;

        /**
         * SetMapCenterData bAnimation.
         * @member {boolean} bAnimation
         * @memberof MapCloudProto.SetMapCenterData
         * @instance
         */
        SetMapCenterData.prototype.bAnimation = false;

        /**
         * Creates a new SetMapCenterData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {MapCloudProto.ISetMapCenterData=} [properties] Properties to set
         * @returns {MapCloudProto.SetMapCenterData} SetMapCenterData instance
         */
        SetMapCenterData.create = function create(properties) {
            return new SetMapCenterData(properties);
        };

        /**
         * Encodes the specified SetMapCenterData message. Does not implicitly {@link MapCloudProto.SetMapCenterData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {MapCloudProto.ISetMapCenterData} message SetMapCenterData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapCenterData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 1 =*/9).double(message.lon);
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.lat);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.level);
            if (message.rollAngle != null && Object.hasOwnProperty.call(message, "rollAngle"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.rollAngle);
            if (message.pitchAngle != null && Object.hasOwnProperty.call(message, "pitchAngle"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.pitchAngle);
            if (message.bAnimation != null && Object.hasOwnProperty.call(message, "bAnimation"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.bAnimation);
            return writer;
        };

        /**
         * Encodes the specified SetMapCenterData message, length delimited. Does not implicitly {@link MapCloudProto.SetMapCenterData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {MapCloudProto.ISetMapCenterData} message SetMapCenterData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapCenterData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMapCenterData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMapCenterData} SetMapCenterData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapCenterData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMapCenterData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.lon = reader.double();
                        break;
                    }
                case 2: {
                        message.lat = reader.double();
                        break;
                    }
                case 3: {
                        message.level = reader.double();
                        break;
                    }
                case 4: {
                        message.rollAngle = reader.double();
                        break;
                    }
                case 5: {
                        message.pitchAngle = reader.double();
                        break;
                    }
                case 6: {
                        message.bAnimation = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("lon"))
                throw $util.ProtocolError("missing required 'lon'", { instance: message });
            if (!message.hasOwnProperty("lat"))
                throw $util.ProtocolError("missing required 'lat'", { instance: message });
            return message;
        };

        /**
         * Decodes a SetMapCenterData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMapCenterData} SetMapCenterData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapCenterData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMapCenterData message.
         * @function verify
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMapCenterData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.lon !== "number")
                return "lon: number expected";
            if (typeof message.lat !== "number")
                return "lat: number expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (typeof message.level !== "number")
                    return "level: number expected";
            if (message.rollAngle != null && message.hasOwnProperty("rollAngle"))
                if (typeof message.rollAngle !== "number")
                    return "rollAngle: number expected";
            if (message.pitchAngle != null && message.hasOwnProperty("pitchAngle"))
                if (typeof message.pitchAngle !== "number")
                    return "pitchAngle: number expected";
            if (message.bAnimation != null && message.hasOwnProperty("bAnimation"))
                if (typeof message.bAnimation !== "boolean")
                    return "bAnimation: boolean expected";
            return null;
        };

        /**
         * Creates a SetMapCenterData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMapCenterData} SetMapCenterData
         */
        SetMapCenterData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMapCenterData)
                return object;
            let message = new $root.MapCloudProto.SetMapCenterData();
            if (object.lon != null)
                message.lon = Number(object.lon);
            if (object.lat != null)
                message.lat = Number(object.lat);
            if (object.level != null)
                message.level = Number(object.level);
            if (object.rollAngle != null)
                message.rollAngle = Number(object.rollAngle);
            if (object.pitchAngle != null)
                message.pitchAngle = Number(object.pitchAngle);
            if (object.bAnimation != null)
                message.bAnimation = Boolean(object.bAnimation);
            return message;
        };

        /**
         * Creates a plain object from a SetMapCenterData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {MapCloudProto.SetMapCenterData} message SetMapCenterData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMapCenterData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.lon = 0;
                object.lat = 0;
                object.level = 0;
                object.rollAngle = 0;
                object.pitchAngle = 0;
                object.bAnimation = false;
            }
            if (message.lon != null && message.hasOwnProperty("lon"))
                object.lon = options.json && !isFinite(message.lon) ? String(message.lon) : message.lon;
            if (message.lat != null && message.hasOwnProperty("lat"))
                object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = options.json && !isFinite(message.level) ? String(message.level) : message.level;
            if (message.rollAngle != null && message.hasOwnProperty("rollAngle"))
                object.rollAngle = options.json && !isFinite(message.rollAngle) ? String(message.rollAngle) : message.rollAngle;
            if (message.pitchAngle != null && message.hasOwnProperty("pitchAngle"))
                object.pitchAngle = options.json && !isFinite(message.pitchAngle) ? String(message.pitchAngle) : message.pitchAngle;
            if (message.bAnimation != null && message.hasOwnProperty("bAnimation"))
                object.bAnimation = message.bAnimation;
            return object;
        };

        /**
         * Converts this SetMapCenterData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMapCenterData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMapCenterData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMapCenterData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMapCenterData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMapCenterData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMapCenterData";
        };

        return SetMapCenterData;
    })();

    MapCloudProto.SetMapStatusData = (function() {

        /**
         * Properties of a SetMapStatusData.
         * @memberof MapCloudProto
         * @interface ISetMapStatusData
         * @property {boolean|null} [bAnimation] SetMapStatusData bAnimation
         * @property {number|null} [level] SetMapStatusData level
         * @property {number|null} [rollAngle] SetMapStatusData rollAngle
         * @property {number|null} [pitchAngle] SetMapStatusData pitchAngle
         */

        /**
         * Constructs a new SetMapStatusData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMapStatusData.
         * @implements ISetMapStatusData
         * @constructor
         * @param {MapCloudProto.ISetMapStatusData=} [properties] Properties to set
         */
        function SetMapStatusData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMapStatusData bAnimation.
         * @member {boolean} bAnimation
         * @memberof MapCloudProto.SetMapStatusData
         * @instance
         */
        SetMapStatusData.prototype.bAnimation = false;

        /**
         * SetMapStatusData level.
         * @member {number} level
         * @memberof MapCloudProto.SetMapStatusData
         * @instance
         */
        SetMapStatusData.prototype.level = 0;

        /**
         * SetMapStatusData rollAngle.
         * @member {number} rollAngle
         * @memberof MapCloudProto.SetMapStatusData
         * @instance
         */
        SetMapStatusData.prototype.rollAngle = 0;

        /**
         * SetMapStatusData pitchAngle.
         * @member {number} pitchAngle
         * @memberof MapCloudProto.SetMapStatusData
         * @instance
         */
        SetMapStatusData.prototype.pitchAngle = 0;

        /**
         * Creates a new SetMapStatusData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {MapCloudProto.ISetMapStatusData=} [properties] Properties to set
         * @returns {MapCloudProto.SetMapStatusData} SetMapStatusData instance
         */
        SetMapStatusData.create = function create(properties) {
            return new SetMapStatusData(properties);
        };

        /**
         * Encodes the specified SetMapStatusData message. Does not implicitly {@link MapCloudProto.SetMapStatusData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {MapCloudProto.ISetMapStatusData} message SetMapStatusData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapStatusData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bAnimation != null && Object.hasOwnProperty.call(message, "bAnimation"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.bAnimation);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.level);
            if (message.rollAngle != null && Object.hasOwnProperty.call(message, "rollAngle"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.rollAngle);
            if (message.pitchAngle != null && Object.hasOwnProperty.call(message, "pitchAngle"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.pitchAngle);
            return writer;
        };

        /**
         * Encodes the specified SetMapStatusData message, length delimited. Does not implicitly {@link MapCloudProto.SetMapStatusData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {MapCloudProto.ISetMapStatusData} message SetMapStatusData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapStatusData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMapStatusData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMapStatusData} SetMapStatusData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapStatusData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMapStatusData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bAnimation = reader.bool();
                        break;
                    }
                case 2: {
                        message.level = reader.double();
                        break;
                    }
                case 3: {
                        message.rollAngle = reader.double();
                        break;
                    }
                case 4: {
                        message.pitchAngle = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetMapStatusData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMapStatusData} SetMapStatusData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapStatusData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMapStatusData message.
         * @function verify
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMapStatusData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.bAnimation != null && message.hasOwnProperty("bAnimation"))
                if (typeof message.bAnimation !== "boolean")
                    return "bAnimation: boolean expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (typeof message.level !== "number")
                    return "level: number expected";
            if (message.rollAngle != null && message.hasOwnProperty("rollAngle"))
                if (typeof message.rollAngle !== "number")
                    return "rollAngle: number expected";
            if (message.pitchAngle != null && message.hasOwnProperty("pitchAngle"))
                if (typeof message.pitchAngle !== "number")
                    return "pitchAngle: number expected";
            return null;
        };

        /**
         * Creates a SetMapStatusData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMapStatusData} SetMapStatusData
         */
        SetMapStatusData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMapStatusData)
                return object;
            let message = new $root.MapCloudProto.SetMapStatusData();
            if (object.bAnimation != null)
                message.bAnimation = Boolean(object.bAnimation);
            if (object.level != null)
                message.level = Number(object.level);
            if (object.rollAngle != null)
                message.rollAngle = Number(object.rollAngle);
            if (object.pitchAngle != null)
                message.pitchAngle = Number(object.pitchAngle);
            return message;
        };

        /**
         * Creates a plain object from a SetMapStatusData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {MapCloudProto.SetMapStatusData} message SetMapStatusData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMapStatusData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.bAnimation = false;
                object.level = 0;
                object.rollAngle = 0;
                object.pitchAngle = 0;
            }
            if (message.bAnimation != null && message.hasOwnProperty("bAnimation"))
                object.bAnimation = message.bAnimation;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = options.json && !isFinite(message.level) ? String(message.level) : message.level;
            if (message.rollAngle != null && message.hasOwnProperty("rollAngle"))
                object.rollAngle = options.json && !isFinite(message.rollAngle) ? String(message.rollAngle) : message.rollAngle;
            if (message.pitchAngle != null && message.hasOwnProperty("pitchAngle"))
                object.pitchAngle = options.json && !isFinite(message.pitchAngle) ? String(message.pitchAngle) : message.pitchAngle;
            return object;
        };

        /**
         * Converts this SetMapStatusData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMapStatusData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMapStatusData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMapStatusData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMapStatusData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMapStatusData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMapStatusData";
        };

        return SetMapStatusData;
    })();

    MapCloudProto.SetMapZoomToData = (function() {

        /**
         * Properties of a SetMapZoomToData.
         * @memberof MapCloudProto
         * @interface ISetMapZoomToData
         * @property {number} level SetMapZoomToData level
         * @property {boolean|null} [bAnimation] SetMapZoomToData bAnimation
         */

        /**
         * Constructs a new SetMapZoomToData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMapZoomToData.
         * @implements ISetMapZoomToData
         * @constructor
         * @param {MapCloudProto.ISetMapZoomToData=} [properties] Properties to set
         */
        function SetMapZoomToData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMapZoomToData level.
         * @member {number} level
         * @memberof MapCloudProto.SetMapZoomToData
         * @instance
         */
        SetMapZoomToData.prototype.level = 0;

        /**
         * SetMapZoomToData bAnimation.
         * @member {boolean} bAnimation
         * @memberof MapCloudProto.SetMapZoomToData
         * @instance
         */
        SetMapZoomToData.prototype.bAnimation = false;

        /**
         * Creates a new SetMapZoomToData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {MapCloudProto.ISetMapZoomToData=} [properties] Properties to set
         * @returns {MapCloudProto.SetMapZoomToData} SetMapZoomToData instance
         */
        SetMapZoomToData.create = function create(properties) {
            return new SetMapZoomToData(properties);
        };

        /**
         * Encodes the specified SetMapZoomToData message. Does not implicitly {@link MapCloudProto.SetMapZoomToData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {MapCloudProto.ISetMapZoomToData} message SetMapZoomToData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapZoomToData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 1 =*/9).double(message.level);
            if (message.bAnimation != null && Object.hasOwnProperty.call(message, "bAnimation"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.bAnimation);
            return writer;
        };

        /**
         * Encodes the specified SetMapZoomToData message, length delimited. Does not implicitly {@link MapCloudProto.SetMapZoomToData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {MapCloudProto.ISetMapZoomToData} message SetMapZoomToData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapZoomToData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMapZoomToData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMapZoomToData} SetMapZoomToData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapZoomToData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMapZoomToData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.level = reader.double();
                        break;
                    }
                case 2: {
                        message.bAnimation = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("level"))
                throw $util.ProtocolError("missing required 'level'", { instance: message });
            return message;
        };

        /**
         * Decodes a SetMapZoomToData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMapZoomToData} SetMapZoomToData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapZoomToData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMapZoomToData message.
         * @function verify
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMapZoomToData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.level !== "number")
                return "level: number expected";
            if (message.bAnimation != null && message.hasOwnProperty("bAnimation"))
                if (typeof message.bAnimation !== "boolean")
                    return "bAnimation: boolean expected";
            return null;
        };

        /**
         * Creates a SetMapZoomToData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMapZoomToData} SetMapZoomToData
         */
        SetMapZoomToData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMapZoomToData)
                return object;
            let message = new $root.MapCloudProto.SetMapZoomToData();
            if (object.level != null)
                message.level = Number(object.level);
            if (object.bAnimation != null)
                message.bAnimation = Boolean(object.bAnimation);
            return message;
        };

        /**
         * Creates a plain object from a SetMapZoomToData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {MapCloudProto.SetMapZoomToData} message SetMapZoomToData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMapZoomToData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.level = 0;
                object.bAnimation = false;
            }
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = options.json && !isFinite(message.level) ? String(message.level) : message.level;
            if (message.bAnimation != null && message.hasOwnProperty("bAnimation"))
                object.bAnimation = message.bAnimation;
            return object;
        };

        /**
         * Converts this SetMapZoomToData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMapZoomToData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMapZoomToData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMapZoomToData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMapZoomToData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMapZoomToData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMapZoomToData";
        };

        return SetMapZoomToData;
    })();

    MapCloudProto.SetMapZoomInData = (function() {

        /**
         * Properties of a SetMapZoomInData.
         * @memberof MapCloudProto
         * @interface ISetMapZoomInData
         */

        /**
         * Constructs a new SetMapZoomInData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMapZoomInData.
         * @implements ISetMapZoomInData
         * @constructor
         * @param {MapCloudProto.ISetMapZoomInData=} [properties] Properties to set
         */
        function SetMapZoomInData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetMapZoomInData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {MapCloudProto.ISetMapZoomInData=} [properties] Properties to set
         * @returns {MapCloudProto.SetMapZoomInData} SetMapZoomInData instance
         */
        SetMapZoomInData.create = function create(properties) {
            return new SetMapZoomInData(properties);
        };

        /**
         * Encodes the specified SetMapZoomInData message. Does not implicitly {@link MapCloudProto.SetMapZoomInData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {MapCloudProto.ISetMapZoomInData} message SetMapZoomInData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapZoomInData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetMapZoomInData message, length delimited. Does not implicitly {@link MapCloudProto.SetMapZoomInData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {MapCloudProto.ISetMapZoomInData} message SetMapZoomInData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapZoomInData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMapZoomInData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMapZoomInData} SetMapZoomInData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapZoomInData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMapZoomInData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetMapZoomInData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMapZoomInData} SetMapZoomInData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapZoomInData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMapZoomInData message.
         * @function verify
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMapZoomInData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetMapZoomInData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMapZoomInData} SetMapZoomInData
         */
        SetMapZoomInData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMapZoomInData)
                return object;
            return new $root.MapCloudProto.SetMapZoomInData();
        };

        /**
         * Creates a plain object from a SetMapZoomInData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {MapCloudProto.SetMapZoomInData} message SetMapZoomInData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMapZoomInData.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetMapZoomInData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMapZoomInData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMapZoomInData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMapZoomInData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMapZoomInData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMapZoomInData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMapZoomInData";
        };

        return SetMapZoomInData;
    })();

    MapCloudProto.SetMapZoomOutData = (function() {

        /**
         * Properties of a SetMapZoomOutData.
         * @memberof MapCloudProto
         * @interface ISetMapZoomOutData
         */

        /**
         * Constructs a new SetMapZoomOutData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMapZoomOutData.
         * @implements ISetMapZoomOutData
         * @constructor
         * @param {MapCloudProto.ISetMapZoomOutData=} [properties] Properties to set
         */
        function SetMapZoomOutData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetMapZoomOutData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {MapCloudProto.ISetMapZoomOutData=} [properties] Properties to set
         * @returns {MapCloudProto.SetMapZoomOutData} SetMapZoomOutData instance
         */
        SetMapZoomOutData.create = function create(properties) {
            return new SetMapZoomOutData(properties);
        };

        /**
         * Encodes the specified SetMapZoomOutData message. Does not implicitly {@link MapCloudProto.SetMapZoomOutData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {MapCloudProto.ISetMapZoomOutData} message SetMapZoomOutData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapZoomOutData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetMapZoomOutData message, length delimited. Does not implicitly {@link MapCloudProto.SetMapZoomOutData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {MapCloudProto.ISetMapZoomOutData} message SetMapZoomOutData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapZoomOutData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMapZoomOutData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMapZoomOutData} SetMapZoomOutData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapZoomOutData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMapZoomOutData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetMapZoomOutData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMapZoomOutData} SetMapZoomOutData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapZoomOutData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMapZoomOutData message.
         * @function verify
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMapZoomOutData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetMapZoomOutData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMapZoomOutData} SetMapZoomOutData
         */
        SetMapZoomOutData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMapZoomOutData)
                return object;
            return new $root.MapCloudProto.SetMapZoomOutData();
        };

        /**
         * Creates a plain object from a SetMapZoomOutData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {MapCloudProto.SetMapZoomOutData} message SetMapZoomOutData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMapZoomOutData.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetMapZoomOutData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMapZoomOutData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMapZoomOutData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMapZoomOutData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMapZoomOutData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMapZoomOutData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMapZoomOutData";
        };

        return SetMapZoomOutData;
    })();

    MapCloudProto.SetSurfaceChangeData = (function() {

        /**
         * Properties of a SetSurfaceChangeData.
         * @memberof MapCloudProto
         * @interface ISetSurfaceChangeData
         * @property {number} width SetSurfaceChangeData width
         * @property {number} height SetSurfaceChangeData height
         * @property {string|null} [channel] SetSurfaceChangeData channel
         * @property {number|null} [devicePixelRatio] SetSurfaceChangeData devicePixelRatio
         */

        /**
         * Constructs a new SetSurfaceChangeData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetSurfaceChangeData.
         * @implements ISetSurfaceChangeData
         * @constructor
         * @param {MapCloudProto.ISetSurfaceChangeData=} [properties] Properties to set
         */
        function SetSurfaceChangeData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetSurfaceChangeData width.
         * @member {number} width
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @instance
         */
        SetSurfaceChangeData.prototype.width = 0;

        /**
         * SetSurfaceChangeData height.
         * @member {number} height
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @instance
         */
        SetSurfaceChangeData.prototype.height = 0;

        /**
         * SetSurfaceChangeData channel.
         * @member {string} channel
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @instance
         */
        SetSurfaceChangeData.prototype.channel = "";

        /**
         * SetSurfaceChangeData devicePixelRatio.
         * @member {number} devicePixelRatio
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @instance
         */
        SetSurfaceChangeData.prototype.devicePixelRatio = 0;

        /**
         * Creates a new SetSurfaceChangeData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {MapCloudProto.ISetSurfaceChangeData=} [properties] Properties to set
         * @returns {MapCloudProto.SetSurfaceChangeData} SetSurfaceChangeData instance
         */
        SetSurfaceChangeData.create = function create(properties) {
            return new SetSurfaceChangeData(properties);
        };

        /**
         * Encodes the specified SetSurfaceChangeData message. Does not implicitly {@link MapCloudProto.SetSurfaceChangeData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {MapCloudProto.ISetSurfaceChangeData} message SetSurfaceChangeData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetSurfaceChangeData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.width);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.height);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.channel);
            if (message.devicePixelRatio != null && Object.hasOwnProperty.call(message, "devicePixelRatio"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.devicePixelRatio);
            return writer;
        };

        /**
         * Encodes the specified SetSurfaceChangeData message, length delimited. Does not implicitly {@link MapCloudProto.SetSurfaceChangeData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {MapCloudProto.ISetSurfaceChangeData} message SetSurfaceChangeData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetSurfaceChangeData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetSurfaceChangeData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetSurfaceChangeData} SetSurfaceChangeData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetSurfaceChangeData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetSurfaceChangeData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.width = reader.int32();
                        break;
                    }
                case 2: {
                        message.height = reader.int32();
                        break;
                    }
                case 3: {
                        message.channel = reader.string();
                        break;
                    }
                case 4: {
                        message.devicePixelRatio = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("width"))
                throw $util.ProtocolError("missing required 'width'", { instance: message });
            if (!message.hasOwnProperty("height"))
                throw $util.ProtocolError("missing required 'height'", { instance: message });
            return message;
        };

        /**
         * Decodes a SetSurfaceChangeData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetSurfaceChangeData} SetSurfaceChangeData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetSurfaceChangeData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetSurfaceChangeData message.
         * @function verify
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetSurfaceChangeData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.width))
                return "width: integer expected";
            if (!$util.isInteger(message.height))
                return "height: integer expected";
            if (message.channel != null && message.hasOwnProperty("channel"))
                if (!$util.isString(message.channel))
                    return "channel: string expected";
            if (message.devicePixelRatio != null && message.hasOwnProperty("devicePixelRatio"))
                if (typeof message.devicePixelRatio !== "number")
                    return "devicePixelRatio: number expected";
            return null;
        };

        /**
         * Creates a SetSurfaceChangeData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetSurfaceChangeData} SetSurfaceChangeData
         */
        SetSurfaceChangeData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetSurfaceChangeData)
                return object;
            let message = new $root.MapCloudProto.SetSurfaceChangeData();
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            if (object.channel != null)
                message.channel = String(object.channel);
            if (object.devicePixelRatio != null)
                message.devicePixelRatio = Number(object.devicePixelRatio);
            return message;
        };

        /**
         * Creates a plain object from a SetSurfaceChangeData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {MapCloudProto.SetSurfaceChangeData} message SetSurfaceChangeData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetSurfaceChangeData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.width = 0;
                object.height = 0;
                object.channel = "";
                object.devicePixelRatio = 0;
            }
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.channel != null && message.hasOwnProperty("channel"))
                object.channel = message.channel;
            if (message.devicePixelRatio != null && message.hasOwnProperty("devicePixelRatio"))
                object.devicePixelRatio = options.json && !isFinite(message.devicePixelRatio) ? String(message.devicePixelRatio) : message.devicePixelRatio;
            return object;
        };

        /**
         * Converts this SetSurfaceChangeData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetSurfaceChangeData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetSurfaceChangeData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetSurfaceChangeData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetSurfaceChangeData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetSurfaceChangeData";
        };

        return SetSurfaceChangeData;
    })();

    MapCloudProto.GPoint = (function() {

        /**
         * Properties of a GPoint.
         * @memberof MapCloudProto
         * @interface IGPoint
         * @property {number} x GPoint x
         * @property {number} y GPoint y
         */

        /**
         * Constructs a new GPoint.
         * @memberof MapCloudProto
         * @classdesc Represents a GPoint.
         * @implements IGPoint
         * @constructor
         * @param {MapCloudProto.IGPoint=} [properties] Properties to set
         */
        function GPoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GPoint x.
         * @member {number} x
         * @memberof MapCloudProto.GPoint
         * @instance
         */
        GPoint.prototype.x = 0;

        /**
         * GPoint y.
         * @member {number} y
         * @memberof MapCloudProto.GPoint
         * @instance
         */
        GPoint.prototype.y = 0;

        /**
         * Creates a new GPoint instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {MapCloudProto.IGPoint=} [properties] Properties to set
         * @returns {MapCloudProto.GPoint} GPoint instance
         */
        GPoint.create = function create(properties) {
            return new GPoint(properties);
        };

        /**
         * Encodes the specified GPoint message. Does not implicitly {@link MapCloudProto.GPoint.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {MapCloudProto.IGPoint} message GPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GPoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.x);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.y);
            return writer;
        };

        /**
         * Encodes the specified GPoint message, length delimited. Does not implicitly {@link MapCloudProto.GPoint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {MapCloudProto.IGPoint} message GPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GPoint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GPoint message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.GPoint} GPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GPoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.GPoint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.x = reader.int32();
                        break;
                    }
                case 2: {
                        message.y = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("x"))
                throw $util.ProtocolError("missing required 'x'", { instance: message });
            if (!message.hasOwnProperty("y"))
                throw $util.ProtocolError("missing required 'y'", { instance: message });
            return message;
        };

        /**
         * Decodes a GPoint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.GPoint} GPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GPoint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GPoint message.
         * @function verify
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GPoint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.x))
                return "x: integer expected";
            if (!$util.isInteger(message.y))
                return "y: integer expected";
            return null;
        };

        /**
         * Creates a GPoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.GPoint} GPoint
         */
        GPoint.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.GPoint)
                return object;
            let message = new $root.MapCloudProto.GPoint();
            if (object.x != null)
                message.x = object.x | 0;
            if (object.y != null)
                message.y = object.y | 0;
            return message;
        };

        /**
         * Creates a plain object from a GPoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {MapCloudProto.GPoint} message GPoint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GPoint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.x = 0;
                object.y = 0;
            }
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = message.y;
            return object;
        };

        /**
         * Converts this GPoint to JSON.
         * @function toJSON
         * @memberof MapCloudProto.GPoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GPoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GPoint
         * @function getTypeUrl
         * @memberof MapCloudProto.GPoint
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GPoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.GPoint";
        };

        return GPoint;
    })();

    MapCloudProto.SetMouseEventData = (function() {

        /**
         * Properties of a SetMouseEventData.
         * @memberof MapCloudProto
         * @interface ISetMouseEventData
         * @property {MapCloudProto.MouseEventTypeEnum} type SetMouseEventData type
         * @property {MapCloudProto.IGPoint} point SetMouseEventData point
         * @property {MapCloudProto.IGPoint} speed SetMouseEventData speed
         * @property {MapCloudProto.MouseButtonEnum|null} [button] SetMouseEventData button
         */

        /**
         * Constructs a new SetMouseEventData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMouseEventData.
         * @implements ISetMouseEventData
         * @constructor
         * @param {MapCloudProto.ISetMouseEventData=} [properties] Properties to set
         */
        function SetMouseEventData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMouseEventData type.
         * @member {MapCloudProto.MouseEventTypeEnum} type
         * @memberof MapCloudProto.SetMouseEventData
         * @instance
         */
        SetMouseEventData.prototype.type = 0;

        /**
         * SetMouseEventData point.
         * @member {MapCloudProto.IGPoint} point
         * @memberof MapCloudProto.SetMouseEventData
         * @instance
         */
        SetMouseEventData.prototype.point = null;

        /**
         * SetMouseEventData speed.
         * @member {MapCloudProto.IGPoint} speed
         * @memberof MapCloudProto.SetMouseEventData
         * @instance
         */
        SetMouseEventData.prototype.speed = null;

        /**
         * SetMouseEventData button.
         * @member {MapCloudProto.MouseButtonEnum} button
         * @memberof MapCloudProto.SetMouseEventData
         * @instance
         */
        SetMouseEventData.prototype.button = 0;

        /**
         * Creates a new SetMouseEventData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {MapCloudProto.ISetMouseEventData=} [properties] Properties to set
         * @returns {MapCloudProto.SetMouseEventData} SetMouseEventData instance
         */
        SetMouseEventData.create = function create(properties) {
            return new SetMouseEventData(properties);
        };

        /**
         * Encodes the specified SetMouseEventData message. Does not implicitly {@link MapCloudProto.SetMouseEventData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {MapCloudProto.ISetMouseEventData} message SetMouseEventData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMouseEventData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            $root.MapCloudProto.GPoint.encode(message.point, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            $root.MapCloudProto.GPoint.encode(message.speed, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.button != null && Object.hasOwnProperty.call(message, "button"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.button);
            return writer;
        };

        /**
         * Encodes the specified SetMouseEventData message, length delimited. Does not implicitly {@link MapCloudProto.SetMouseEventData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {MapCloudProto.ISetMouseEventData} message SetMouseEventData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMouseEventData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMouseEventData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMouseEventData} SetMouseEventData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMouseEventData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMouseEventData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.point = $root.MapCloudProto.GPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.speed = $root.MapCloudProto.GPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.button = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("type"))
                throw $util.ProtocolError("missing required 'type'", { instance: message });
            if (!message.hasOwnProperty("point"))
                throw $util.ProtocolError("missing required 'point'", { instance: message });
            if (!message.hasOwnProperty("speed"))
                throw $util.ProtocolError("missing required 'speed'", { instance: message });
            return message;
        };

        /**
         * Decodes a SetMouseEventData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMouseEventData} SetMouseEventData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMouseEventData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMouseEventData message.
         * @function verify
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMouseEventData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
            {
                let error = $root.MapCloudProto.GPoint.verify(message.point);
                if (error)
                    return "point." + error;
            }
            {
                let error = $root.MapCloudProto.GPoint.verify(message.speed);
                if (error)
                    return "speed." + error;
            }
            if (message.button != null && message.hasOwnProperty("button"))
                switch (message.button) {
                default:
                    return "button: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a SetMouseEventData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMouseEventData} SetMouseEventData
         */
        SetMouseEventData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMouseEventData)
                return object;
            let message = new $root.MapCloudProto.SetMouseEventData();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "Down":
            case 0:
                message.type = 0;
                break;
            case "Up":
            case 1:
                message.type = 1;
                break;
            case "Drag":
            case 2:
                message.type = 2;
                break;
            case "Enter":
            case 3:
                message.type = 3;
                break;
            case "Leave":
            case 4:
                message.type = 4;
                break;
            case "Move":
            case 5:
                message.type = 5;
                break;
            }
            if (object.point != null) {
                if (typeof object.point !== "object")
                    throw TypeError(".MapCloudProto.SetMouseEventData.point: object expected");
                message.point = $root.MapCloudProto.GPoint.fromObject(object.point);
            }
            if (object.speed != null) {
                if (typeof object.speed !== "object")
                    throw TypeError(".MapCloudProto.SetMouseEventData.speed: object expected");
                message.speed = $root.MapCloudProto.GPoint.fromObject(object.speed);
            }
            switch (object.button) {
            default:
                if (typeof object.button === "number") {
                    message.button = object.button;
                    break;
                }
                break;
            case "MOUSE_BUTTON_LEFT":
            case 0:
                message.button = 0;
                break;
            case "MOUSE_BUTTON_RIGHT":
            case 1:
                message.button = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SetMouseEventData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {MapCloudProto.SetMouseEventData} message SetMouseEventData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMouseEventData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "Down" : 0;
                object.point = null;
                object.speed = null;
                object.button = options.enums === String ? "MOUSE_BUTTON_LEFT" : 0;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.MapCloudProto.MouseEventTypeEnum[message.type] === undefined ? message.type : $root.MapCloudProto.MouseEventTypeEnum[message.type] : message.type;
            if (message.point != null && message.hasOwnProperty("point"))
                object.point = $root.MapCloudProto.GPoint.toObject(message.point, options);
            if (message.speed != null && message.hasOwnProperty("speed"))
                object.speed = $root.MapCloudProto.GPoint.toObject(message.speed, options);
            if (message.button != null && message.hasOwnProperty("button"))
                object.button = options.enums === String ? $root.MapCloudProto.MouseButtonEnum[message.button] === undefined ? message.button : $root.MapCloudProto.MouseButtonEnum[message.button] : message.button;
            return object;
        };

        /**
         * Converts this SetMouseEventData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMouseEventData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMouseEventData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMouseEventData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMouseEventData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMouseEventData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMouseEventData";
        };

        return SetMouseEventData;
    })();

    MapCloudProto.SetMapParameterData = (function() {

        /**
         * Properties of a SetMapParameterData.
         * @memberof MapCloudProto
         * @interface ISetMapParameterData
         * @property {number|null} [dataId] SetMapParameterData dataId
         * @property {number} dataType SetMapParameterData dataType
         * @property {number|null} [n1] SetMapParameterData n1
         * @property {number|null} [n2] SetMapParameterData n2
         * @property {number|null} [n3] SetMapParameterData n3
         * @property {number|null} [n4] SetMapParameterData n4
         * @property {string|null} [data] SetMapParameterData data
         */

        /**
         * Constructs a new SetMapParameterData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMapParameterData.
         * @implements ISetMapParameterData
         * @constructor
         * @param {MapCloudProto.ISetMapParameterData=} [properties] Properties to set
         */
        function SetMapParameterData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMapParameterData dataId.
         * @member {number} dataId
         * @memberof MapCloudProto.SetMapParameterData
         * @instance
         */
        SetMapParameterData.prototype.dataId = 0;

        /**
         * SetMapParameterData dataType.
         * @member {number} dataType
         * @memberof MapCloudProto.SetMapParameterData
         * @instance
         */
        SetMapParameterData.prototype.dataType = 0;

        /**
         * SetMapParameterData n1.
         * @member {number} n1
         * @memberof MapCloudProto.SetMapParameterData
         * @instance
         */
        SetMapParameterData.prototype.n1 = 0;

        /**
         * SetMapParameterData n2.
         * @member {number} n2
         * @memberof MapCloudProto.SetMapParameterData
         * @instance
         */
        SetMapParameterData.prototype.n2 = 0;

        /**
         * SetMapParameterData n3.
         * @member {number} n3
         * @memberof MapCloudProto.SetMapParameterData
         * @instance
         */
        SetMapParameterData.prototype.n3 = 0;

        /**
         * SetMapParameterData n4.
         * @member {number} n4
         * @memberof MapCloudProto.SetMapParameterData
         * @instance
         */
        SetMapParameterData.prototype.n4 = 0;

        /**
         * SetMapParameterData data.
         * @member {string} data
         * @memberof MapCloudProto.SetMapParameterData
         * @instance
         */
        SetMapParameterData.prototype.data = "";

        /**
         * Creates a new SetMapParameterData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {MapCloudProto.ISetMapParameterData=} [properties] Properties to set
         * @returns {MapCloudProto.SetMapParameterData} SetMapParameterData instance
         */
        SetMapParameterData.create = function create(properties) {
            return new SetMapParameterData(properties);
        };

        /**
         * Encodes the specified SetMapParameterData message. Does not implicitly {@link MapCloudProto.SetMapParameterData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {MapCloudProto.ISetMapParameterData} message SetMapParameterData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapParameterData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dataId != null && Object.hasOwnProperty.call(message, "dataId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.dataId);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.dataType);
            if (message.n1 != null && Object.hasOwnProperty.call(message, "n1"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.n1);
            if (message.n2 != null && Object.hasOwnProperty.call(message, "n2"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.n2);
            if (message.n3 != null && Object.hasOwnProperty.call(message, "n3"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.n3);
            if (message.n4 != null && Object.hasOwnProperty.call(message, "n4"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.n4);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.data);
            return writer;
        };

        /**
         * Encodes the specified SetMapParameterData message, length delimited. Does not implicitly {@link MapCloudProto.SetMapParameterData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {MapCloudProto.ISetMapParameterData} message SetMapParameterData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapParameterData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMapParameterData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMapParameterData} SetMapParameterData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapParameterData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMapParameterData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dataId = reader.int32();
                        break;
                    }
                case 2: {
                        message.dataType = reader.int32();
                        break;
                    }
                case 3: {
                        message.n1 = reader.int32();
                        break;
                    }
                case 4: {
                        message.n2 = reader.int32();
                        break;
                    }
                case 5: {
                        message.n3 = reader.int32();
                        break;
                    }
                case 6: {
                        message.n4 = reader.int32();
                        break;
                    }
                case 7: {
                        message.data = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("dataType"))
                throw $util.ProtocolError("missing required 'dataType'", { instance: message });
            return message;
        };

        /**
         * Decodes a SetMapParameterData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMapParameterData} SetMapParameterData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapParameterData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMapParameterData message.
         * @function verify
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMapParameterData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dataId != null && message.hasOwnProperty("dataId"))
                if (!$util.isInteger(message.dataId))
                    return "dataId: integer expected";
            if (!$util.isInteger(message.dataType))
                return "dataType: integer expected";
            if (message.n1 != null && message.hasOwnProperty("n1"))
                if (!$util.isInteger(message.n1))
                    return "n1: integer expected";
            if (message.n2 != null && message.hasOwnProperty("n2"))
                if (!$util.isInteger(message.n2))
                    return "n2: integer expected";
            if (message.n3 != null && message.hasOwnProperty("n3"))
                if (!$util.isInteger(message.n3))
                    return "n3: integer expected";
            if (message.n4 != null && message.hasOwnProperty("n4"))
                if (!$util.isInteger(message.n4))
                    return "n4: integer expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!$util.isString(message.data))
                    return "data: string expected";
            return null;
        };

        /**
         * Creates a SetMapParameterData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMapParameterData} SetMapParameterData
         */
        SetMapParameterData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMapParameterData)
                return object;
            let message = new $root.MapCloudProto.SetMapParameterData();
            if (object.dataId != null)
                message.dataId = object.dataId | 0;
            if (object.dataType != null)
                message.dataType = object.dataType | 0;
            if (object.n1 != null)
                message.n1 = object.n1 | 0;
            if (object.n2 != null)
                message.n2 = object.n2 | 0;
            if (object.n3 != null)
                message.n3 = object.n3 | 0;
            if (object.n4 != null)
                message.n4 = object.n4 | 0;
            if (object.data != null)
                message.data = String(object.data);
            return message;
        };

        /**
         * Creates a plain object from a SetMapParameterData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {MapCloudProto.SetMapParameterData} message SetMapParameterData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMapParameterData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dataId = 0;
                object.dataType = 0;
                object.n1 = 0;
                object.n2 = 0;
                object.n3 = 0;
                object.n4 = 0;
                object.data = "";
            }
            if (message.dataId != null && message.hasOwnProperty("dataId"))
                object.dataId = message.dataId;
            if (message.dataType != null && message.hasOwnProperty("dataType"))
                object.dataType = message.dataType;
            if (message.n1 != null && message.hasOwnProperty("n1"))
                object.n1 = message.n1;
            if (message.n2 != null && message.hasOwnProperty("n2"))
                object.n2 = message.n2;
            if (message.n3 != null && message.hasOwnProperty("n3"))
                object.n3 = message.n3;
            if (message.n4 != null && message.hasOwnProperty("n4"))
                object.n4 = message.n4;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = message.data;
            return object;
        };

        /**
         * Converts this SetMapParameterData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMapParameterData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMapParameterData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMapParameterData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMapParameterData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMapParameterData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMapParameterData";
        };

        return SetMapParameterData;
    })();

    MapCloudProto.SetWebRtcParameterData = (function() {

        /**
         * Properties of a SetWebRtcParameterData.
         * @memberof MapCloudProto
         * @interface ISetWebRtcParameterData
         * @property {number|null} [fluency] SetWebRtcParameterData fluency
         */

        /**
         * Constructs a new SetWebRtcParameterData.
         * @memberof MapCloudProto
         * @classdesc Represents a SetWebRtcParameterData.
         * @implements ISetWebRtcParameterData
         * @constructor
         * @param {MapCloudProto.ISetWebRtcParameterData=} [properties] Properties to set
         */
        function SetWebRtcParameterData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetWebRtcParameterData fluency.
         * @member {number} fluency
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @instance
         */
        SetWebRtcParameterData.prototype.fluency = 0;

        /**
         * Creates a new SetWebRtcParameterData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {MapCloudProto.ISetWebRtcParameterData=} [properties] Properties to set
         * @returns {MapCloudProto.SetWebRtcParameterData} SetWebRtcParameterData instance
         */
        SetWebRtcParameterData.create = function create(properties) {
            return new SetWebRtcParameterData(properties);
        };

        /**
         * Encodes the specified SetWebRtcParameterData message. Does not implicitly {@link MapCloudProto.SetWebRtcParameterData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {MapCloudProto.ISetWebRtcParameterData} message SetWebRtcParameterData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetWebRtcParameterData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fluency != null && Object.hasOwnProperty.call(message, "fluency"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.fluency);
            return writer;
        };

        /**
         * Encodes the specified SetWebRtcParameterData message, length delimited. Does not implicitly {@link MapCloudProto.SetWebRtcParameterData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {MapCloudProto.ISetWebRtcParameterData} message SetWebRtcParameterData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetWebRtcParameterData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetWebRtcParameterData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetWebRtcParameterData} SetWebRtcParameterData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetWebRtcParameterData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetWebRtcParameterData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fluency = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetWebRtcParameterData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetWebRtcParameterData} SetWebRtcParameterData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetWebRtcParameterData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetWebRtcParameterData message.
         * @function verify
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetWebRtcParameterData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fluency != null && message.hasOwnProperty("fluency"))
                if (!$util.isInteger(message.fluency))
                    return "fluency: integer expected";
            return null;
        };

        /**
         * Creates a SetWebRtcParameterData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetWebRtcParameterData} SetWebRtcParameterData
         */
        SetWebRtcParameterData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetWebRtcParameterData)
                return object;
            let message = new $root.MapCloudProto.SetWebRtcParameterData();
            if (object.fluency != null)
                message.fluency = object.fluency | 0;
            return message;
        };

        /**
         * Creates a plain object from a SetWebRtcParameterData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {MapCloudProto.SetWebRtcParameterData} message SetWebRtcParameterData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetWebRtcParameterData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.fluency = 0;
            if (message.fluency != null && message.hasOwnProperty("fluency"))
                object.fluency = message.fluency;
            return object;
        };

        /**
         * Converts this SetWebRtcParameterData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetWebRtcParameterData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetWebRtcParameterData
         * @function getTypeUrl
         * @memberof MapCloudProto.SetWebRtcParameterData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetWebRtcParameterData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetWebRtcParameterData";
        };

        return SetWebRtcParameterData;
    })();

    MapCloudProto.RequestMapData = (function() {

        /**
         * Properties of a RequestMapData.
         * @memberof MapCloudProto
         * @interface IRequestMapData
         * @property {string|null} [mapId] RequestMapData mapId
         */

        /**
         * Constructs a new RequestMapData.
         * @memberof MapCloudProto
         * @classdesc Represents a RequestMapData.
         * @implements IRequestMapData
         * @constructor
         * @param {MapCloudProto.IRequestMapData=} [properties] Properties to set
         */
        function RequestMapData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestMapData mapId.
         * @member {string} mapId
         * @memberof MapCloudProto.RequestMapData
         * @instance
         */
        RequestMapData.prototype.mapId = "";

        /**
         * Creates a new RequestMapData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {MapCloudProto.IRequestMapData=} [properties] Properties to set
         * @returns {MapCloudProto.RequestMapData} RequestMapData instance
         */
        RequestMapData.create = function create(properties) {
            return new RequestMapData(properties);
        };

        /**
         * Encodes the specified RequestMapData message. Does not implicitly {@link MapCloudProto.RequestMapData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {MapCloudProto.IRequestMapData} message RequestMapData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestMapData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapId);
            return writer;
        };

        /**
         * Encodes the specified RequestMapData message, length delimited. Does not implicitly {@link MapCloudProto.RequestMapData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {MapCloudProto.IRequestMapData} message RequestMapData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestMapData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestMapData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.RequestMapData} RequestMapData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestMapData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.RequestMapData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mapId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestMapData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.RequestMapData} RequestMapData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestMapData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestMapData message.
         * @function verify
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestMapData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapId != null && message.hasOwnProperty("mapId"))
                if (!$util.isString(message.mapId))
                    return "mapId: string expected";
            return null;
        };

        /**
         * Creates a RequestMapData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.RequestMapData} RequestMapData
         */
        RequestMapData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.RequestMapData)
                return object;
            let message = new $root.MapCloudProto.RequestMapData();
            if (object.mapId != null)
                message.mapId = String(object.mapId);
            return message;
        };

        /**
         * Creates a plain object from a RequestMapData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {MapCloudProto.RequestMapData} message RequestMapData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestMapData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mapId = "";
            if (message.mapId != null && message.hasOwnProperty("mapId"))
                object.mapId = message.mapId;
            return object;
        };

        /**
         * Converts this RequestMapData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.RequestMapData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestMapData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestMapData
         * @function getTypeUrl
         * @memberof MapCloudProto.RequestMapData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestMapData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.RequestMapData";
        };

        return RequestMapData;
    })();

    MapCloudProto.RequestParameterData = (function() {

        /**
         * Properties of a RequestParameterData.
         * @memberof MapCloudProto
         * @interface IRequestParameterData
         * @property {MapCloudProto.RequestParameterEnum} type RequestParameterData type
         * @property {MapCloudProto.IRequestMapData|null} [requestMapData] RequestParameterData requestMapData
         */

        /**
         * Constructs a new RequestParameterData.
         * @memberof MapCloudProto
         * @classdesc Represents a RequestParameterData.
         * @implements IRequestParameterData
         * @constructor
         * @param {MapCloudProto.IRequestParameterData=} [properties] Properties to set
         */
        function RequestParameterData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestParameterData type.
         * @member {MapCloudProto.RequestParameterEnum} type
         * @memberof MapCloudProto.RequestParameterData
         * @instance
         */
        RequestParameterData.prototype.type = 0;

        /**
         * RequestParameterData requestMapData.
         * @member {MapCloudProto.IRequestMapData|null|undefined} requestMapData
         * @memberof MapCloudProto.RequestParameterData
         * @instance
         */
        RequestParameterData.prototype.requestMapData = null;

        /**
         * Creates a new RequestParameterData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {MapCloudProto.IRequestParameterData=} [properties] Properties to set
         * @returns {MapCloudProto.RequestParameterData} RequestParameterData instance
         */
        RequestParameterData.create = function create(properties) {
            return new RequestParameterData(properties);
        };

        /**
         * Encodes the specified RequestParameterData message. Does not implicitly {@link MapCloudProto.RequestParameterData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {MapCloudProto.IRequestParameterData} message RequestParameterData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestParameterData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.requestMapData != null && Object.hasOwnProperty.call(message, "requestMapData"))
                $root.MapCloudProto.RequestMapData.encode(message.requestMapData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RequestParameterData message, length delimited. Does not implicitly {@link MapCloudProto.RequestParameterData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {MapCloudProto.IRequestParameterData} message RequestParameterData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestParameterData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestParameterData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.RequestParameterData} RequestParameterData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestParameterData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.RequestParameterData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.requestMapData = $root.MapCloudProto.RequestMapData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("type"))
                throw $util.ProtocolError("missing required 'type'", { instance: message });
            return message;
        };

        /**
         * Decodes a RequestParameterData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.RequestParameterData} RequestParameterData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestParameterData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestParameterData message.
         * @function verify
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestParameterData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
                break;
            }
            if (message.requestMapData != null && message.hasOwnProperty("requestMapData")) {
                let error = $root.MapCloudProto.RequestMapData.verify(message.requestMapData);
                if (error)
                    return "requestMapData." + error;
            }
            return null;
        };

        /**
         * Creates a RequestParameterData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.RequestParameterData} RequestParameterData
         */
        RequestParameterData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.RequestParameterData)
                return object;
            let message = new $root.MapCloudProto.RequestParameterData();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "RequestMapBaseInfo":
            case 0:
                message.type = 0;
                break;
            }
            if (object.requestMapData != null) {
                if (typeof object.requestMapData !== "object")
                    throw TypeError(".MapCloudProto.RequestParameterData.requestMapData: object expected");
                message.requestMapData = $root.MapCloudProto.RequestMapData.fromObject(object.requestMapData);
            }
            return message;
        };

        /**
         * Creates a plain object from a RequestParameterData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {MapCloudProto.RequestParameterData} message RequestParameterData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestParameterData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "RequestMapBaseInfo" : 0;
                object.requestMapData = null;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.MapCloudProto.RequestParameterEnum[message.type] === undefined ? message.type : $root.MapCloudProto.RequestParameterEnum[message.type] : message.type;
            if (message.requestMapData != null && message.hasOwnProperty("requestMapData"))
                object.requestMapData = $root.MapCloudProto.RequestMapData.toObject(message.requestMapData, options);
            return object;
        };

        /**
         * Converts this RequestParameterData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.RequestParameterData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestParameterData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestParameterData
         * @function getTypeUrl
         * @memberof MapCloudProto.RequestParameterData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestParameterData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.RequestParameterData";
        };

        return RequestParameterData;
    })();

    MapCloudProto.MapBaseData = (function() {

        /**
         * Properties of a MapBaseData.
         * @memberof MapCloudProto
         * @interface IMapBaseData
         * @property {number} width MapBaseData width
         * @property {number} height MapBaseData height
         * @property {number} zoomLevel MapBaseData zoomLevel
         * @property {number} pitchAngle MapBaseData pitchAngle
         * @property {number} rollAngle MapBaseData rollAngle
         * @property {number} minZoomLevel MapBaseData minZoomLevel
         * @property {number} maxZoomLevel MapBaseData maxZoomLevel
         * @property {boolean} tmc MapBaseData tmc
         * @property {string|null} [ws] MapBaseData ws
         * @property {number|null} [lon] MapBaseData lon
         * @property {number|null} [lat] MapBaseData lat
         * @property {number|null} [z] MapBaseData z
         * @property {string|null} [extData] MapBaseData extData
         * @property {string|null} [sessionId] MapBaseData sessionId
         */

        /**
         * Constructs a new MapBaseData.
         * @memberof MapCloudProto
         * @classdesc Represents a MapBaseData.
         * @implements IMapBaseData
         * @constructor
         * @param {MapCloudProto.IMapBaseData=} [properties] Properties to set
         */
        function MapBaseData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapBaseData width.
         * @member {number} width
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.width = 0;

        /**
         * MapBaseData height.
         * @member {number} height
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.height = 0;

        /**
         * MapBaseData zoomLevel.
         * @member {number} zoomLevel
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.zoomLevel = 0;

        /**
         * MapBaseData pitchAngle.
         * @member {number} pitchAngle
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.pitchAngle = 0;

        /**
         * MapBaseData rollAngle.
         * @member {number} rollAngle
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.rollAngle = 0;

        /**
         * MapBaseData minZoomLevel.
         * @member {number} minZoomLevel
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.minZoomLevel = 0;

        /**
         * MapBaseData maxZoomLevel.
         * @member {number} maxZoomLevel
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.maxZoomLevel = 0;

        /**
         * MapBaseData tmc.
         * @member {boolean} tmc
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.tmc = false;

        /**
         * MapBaseData ws.
         * @member {string} ws
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.ws = "";

        /**
         * MapBaseData lon.
         * @member {number} lon
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.lon = 0;

        /**
         * MapBaseData lat.
         * @member {number} lat
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.lat = 0;

        /**
         * MapBaseData z.
         * @member {number} z
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.z = 0;

        /**
         * MapBaseData extData.
         * @member {string} extData
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.extData = "";

        /**
         * MapBaseData sessionId.
         * @member {string} sessionId
         * @memberof MapCloudProto.MapBaseData
         * @instance
         */
        MapBaseData.prototype.sessionId = "";

        /**
         * Creates a new MapBaseData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {MapCloudProto.IMapBaseData=} [properties] Properties to set
         * @returns {MapCloudProto.MapBaseData} MapBaseData instance
         */
        MapBaseData.create = function create(properties) {
            return new MapBaseData(properties);
        };

        /**
         * Encodes the specified MapBaseData message. Does not implicitly {@link MapCloudProto.MapBaseData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {MapCloudProto.IMapBaseData} message MapBaseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapBaseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 1 =*/9).double(message.width);
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.height);
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.zoomLevel);
            writer.uint32(/* id 4, wireType 1 =*/33).double(message.pitchAngle);
            writer.uint32(/* id 5, wireType 1 =*/41).double(message.rollAngle);
            writer.uint32(/* id 6, wireType 1 =*/49).double(message.minZoomLevel);
            writer.uint32(/* id 7, wireType 1 =*/57).double(message.maxZoomLevel);
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.tmc);
            if (message.ws != null && Object.hasOwnProperty.call(message, "ws"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.ws);
            if (message.lon != null && Object.hasOwnProperty.call(message, "lon"))
                writer.uint32(/* id 12, wireType 1 =*/97).double(message.lon);
            if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                writer.uint32(/* id 13, wireType 1 =*/105).double(message.lat);
            if (message.z != null && Object.hasOwnProperty.call(message, "z"))
                writer.uint32(/* id 14, wireType 1 =*/113).double(message.z);
            if (message.extData != null && Object.hasOwnProperty.call(message, "extData"))
                writer.uint32(/* id 100, wireType 2 =*/802).string(message.extData);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 200, wireType 2 =*/1602).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified MapBaseData message, length delimited. Does not implicitly {@link MapCloudProto.MapBaseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {MapCloudProto.IMapBaseData} message MapBaseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapBaseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapBaseData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.MapBaseData} MapBaseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapBaseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.MapBaseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.width = reader.double();
                        break;
                    }
                case 2: {
                        message.height = reader.double();
                        break;
                    }
                case 3: {
                        message.zoomLevel = reader.double();
                        break;
                    }
                case 4: {
                        message.pitchAngle = reader.double();
                        break;
                    }
                case 5: {
                        message.rollAngle = reader.double();
                        break;
                    }
                case 6: {
                        message.minZoomLevel = reader.double();
                        break;
                    }
                case 7: {
                        message.maxZoomLevel = reader.double();
                        break;
                    }
                case 9: {
                        message.tmc = reader.bool();
                        break;
                    }
                case 10: {
                        message.ws = reader.string();
                        break;
                    }
                case 12: {
                        message.lon = reader.double();
                        break;
                    }
                case 13: {
                        message.lat = reader.double();
                        break;
                    }
                case 14: {
                        message.z = reader.double();
                        break;
                    }
                case 100: {
                        message.extData = reader.string();
                        break;
                    }
                case 200: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("width"))
                throw $util.ProtocolError("missing required 'width'", { instance: message });
            if (!message.hasOwnProperty("height"))
                throw $util.ProtocolError("missing required 'height'", { instance: message });
            if (!message.hasOwnProperty("zoomLevel"))
                throw $util.ProtocolError("missing required 'zoomLevel'", { instance: message });
            if (!message.hasOwnProperty("pitchAngle"))
                throw $util.ProtocolError("missing required 'pitchAngle'", { instance: message });
            if (!message.hasOwnProperty("rollAngle"))
                throw $util.ProtocolError("missing required 'rollAngle'", { instance: message });
            if (!message.hasOwnProperty("minZoomLevel"))
                throw $util.ProtocolError("missing required 'minZoomLevel'", { instance: message });
            if (!message.hasOwnProperty("maxZoomLevel"))
                throw $util.ProtocolError("missing required 'maxZoomLevel'", { instance: message });
            if (!message.hasOwnProperty("tmc"))
                throw $util.ProtocolError("missing required 'tmc'", { instance: message });
            return message;
        };

        /**
         * Decodes a MapBaseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.MapBaseData} MapBaseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapBaseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapBaseData message.
         * @function verify
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapBaseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.width !== "number")
                return "width: number expected";
            if (typeof message.height !== "number")
                return "height: number expected";
            if (typeof message.zoomLevel !== "number")
                return "zoomLevel: number expected";
            if (typeof message.pitchAngle !== "number")
                return "pitchAngle: number expected";
            if (typeof message.rollAngle !== "number")
                return "rollAngle: number expected";
            if (typeof message.minZoomLevel !== "number")
                return "minZoomLevel: number expected";
            if (typeof message.maxZoomLevel !== "number")
                return "maxZoomLevel: number expected";
            if (typeof message.tmc !== "boolean")
                return "tmc: boolean expected";
            if (message.ws != null && message.hasOwnProperty("ws"))
                if (!$util.isString(message.ws))
                    return "ws: string expected";
            if (message.lon != null && message.hasOwnProperty("lon"))
                if (typeof message.lon !== "number")
                    return "lon: number expected";
            if (message.lat != null && message.hasOwnProperty("lat"))
                if (typeof message.lat !== "number")
                    return "lat: number expected";
            if (message.z != null && message.hasOwnProperty("z"))
                if (typeof message.z !== "number")
                    return "z: number expected";
            if (message.extData != null && message.hasOwnProperty("extData"))
                if (!$util.isString(message.extData))
                    return "extData: string expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates a MapBaseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.MapBaseData} MapBaseData
         */
        MapBaseData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.MapBaseData)
                return object;
            let message = new $root.MapCloudProto.MapBaseData();
            if (object.width != null)
                message.width = Number(object.width);
            if (object.height != null)
                message.height = Number(object.height);
            if (object.zoomLevel != null)
                message.zoomLevel = Number(object.zoomLevel);
            if (object.pitchAngle != null)
                message.pitchAngle = Number(object.pitchAngle);
            if (object.rollAngle != null)
                message.rollAngle = Number(object.rollAngle);
            if (object.minZoomLevel != null)
                message.minZoomLevel = Number(object.minZoomLevel);
            if (object.maxZoomLevel != null)
                message.maxZoomLevel = Number(object.maxZoomLevel);
            if (object.tmc != null)
                message.tmc = Boolean(object.tmc);
            if (object.ws != null)
                message.ws = String(object.ws);
            if (object.lon != null)
                message.lon = Number(object.lon);
            if (object.lat != null)
                message.lat = Number(object.lat);
            if (object.z != null)
                message.z = Number(object.z);
            if (object.extData != null)
                message.extData = String(object.extData);
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from a MapBaseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {MapCloudProto.MapBaseData} message MapBaseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapBaseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.width = 0;
                object.height = 0;
                object.zoomLevel = 0;
                object.pitchAngle = 0;
                object.rollAngle = 0;
                object.minZoomLevel = 0;
                object.maxZoomLevel = 0;
                object.tmc = false;
                object.ws = "";
                object.lon = 0;
                object.lat = 0;
                object.z = 0;
                object.extData = "";
                object.sessionId = "";
            }
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = options.json && !isFinite(message.height) ? String(message.height) : message.height;
            if (message.zoomLevel != null && message.hasOwnProperty("zoomLevel"))
                object.zoomLevel = options.json && !isFinite(message.zoomLevel) ? String(message.zoomLevel) : message.zoomLevel;
            if (message.pitchAngle != null && message.hasOwnProperty("pitchAngle"))
                object.pitchAngle = options.json && !isFinite(message.pitchAngle) ? String(message.pitchAngle) : message.pitchAngle;
            if (message.rollAngle != null && message.hasOwnProperty("rollAngle"))
                object.rollAngle = options.json && !isFinite(message.rollAngle) ? String(message.rollAngle) : message.rollAngle;
            if (message.minZoomLevel != null && message.hasOwnProperty("minZoomLevel"))
                object.minZoomLevel = options.json && !isFinite(message.minZoomLevel) ? String(message.minZoomLevel) : message.minZoomLevel;
            if (message.maxZoomLevel != null && message.hasOwnProperty("maxZoomLevel"))
                object.maxZoomLevel = options.json && !isFinite(message.maxZoomLevel) ? String(message.maxZoomLevel) : message.maxZoomLevel;
            if (message.tmc != null && message.hasOwnProperty("tmc"))
                object.tmc = message.tmc;
            if (message.ws != null && message.hasOwnProperty("ws"))
                object.ws = message.ws;
            if (message.lon != null && message.hasOwnProperty("lon"))
                object.lon = options.json && !isFinite(message.lon) ? String(message.lon) : message.lon;
            if (message.lat != null && message.hasOwnProperty("lat"))
                object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
            if (message.z != null && message.hasOwnProperty("z"))
                object.z = options.json && !isFinite(message.z) ? String(message.z) : message.z;
            if (message.extData != null && message.hasOwnProperty("extData"))
                object.extData = message.extData;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this MapBaseData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.MapBaseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapBaseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapBaseData
         * @function getTypeUrl
         * @memberof MapCloudProto.MapBaseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapBaseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.MapBaseData";
        };

        return MapBaseData;
    })();

    MapCloudProto.GestureInfo = (function() {

        /**
         * Properties of a GestureInfo.
         * @memberof MapCloudProto
         * @interface IGestureInfo
         * @property {number|null} [deviceId] GestureInfo deviceId
         * @property {MapCloudProto.MapGestureType|null} [gestureType] GestureInfo gestureType
         * @property {MapCloudProto.MapGestureState|null} [gestureState] GestureInfo gestureState
         * @property {number|null} [x] GestureInfo x
         * @property {number|null} [y] GestureInfo y
         * @property {number|null} [numberOfTouches] GestureInfo numberOfTouches
         * @property {number|null} [scale] GestureInfo scale
         * @property {number|null} [rotation] GestureInfo rotation
         * @property {number|null} [velocity] GestureInfo velocity
         * @property {number|null} [velocityX] GestureInfo velocityX
         * @property {number|null} [velocityY] GestureInfo velocityY
         */

        /**
         * Constructs a new GestureInfo.
         * @memberof MapCloudProto
         * @classdesc Represents a GestureInfo.
         * @implements IGestureInfo
         * @constructor
         * @param {MapCloudProto.IGestureInfo=} [properties] Properties to set
         */
        function GestureInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GestureInfo deviceId.
         * @member {number} deviceId
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.deviceId = 0;

        /**
         * GestureInfo gestureType.
         * @member {MapCloudProto.MapGestureType} gestureType
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.gestureType = 0;

        /**
         * GestureInfo gestureState.
         * @member {MapCloudProto.MapGestureState} gestureState
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.gestureState = 0;

        /**
         * GestureInfo x.
         * @member {number} x
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.x = 0;

        /**
         * GestureInfo y.
         * @member {number} y
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.y = 0;

        /**
         * GestureInfo numberOfTouches.
         * @member {number} numberOfTouches
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.numberOfTouches = 0;

        /**
         * GestureInfo scale.
         * @member {number} scale
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.scale = 0;

        /**
         * GestureInfo rotation.
         * @member {number} rotation
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.rotation = 0;

        /**
         * GestureInfo velocity.
         * @member {number} velocity
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.velocity = 0;

        /**
         * GestureInfo velocityX.
         * @member {number} velocityX
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.velocityX = 0;

        /**
         * GestureInfo velocityY.
         * @member {number} velocityY
         * @memberof MapCloudProto.GestureInfo
         * @instance
         */
        GestureInfo.prototype.velocityY = 0;

        /**
         * Creates a new GestureInfo instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {MapCloudProto.IGestureInfo=} [properties] Properties to set
         * @returns {MapCloudProto.GestureInfo} GestureInfo instance
         */
        GestureInfo.create = function create(properties) {
            return new GestureInfo(properties);
        };

        /**
         * Encodes the specified GestureInfo message. Does not implicitly {@link MapCloudProto.GestureInfo.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {MapCloudProto.IGestureInfo} message GestureInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GestureInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deviceId);
            if (message.gestureType != null && Object.hasOwnProperty.call(message, "gestureType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.gestureType);
            if (message.gestureState != null && Object.hasOwnProperty.call(message, "gestureState"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.gestureState);
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.y);
            if (message.numberOfTouches != null && Object.hasOwnProperty.call(message, "numberOfTouches"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.numberOfTouches);
            if (message.scale != null && Object.hasOwnProperty.call(message, "scale"))
                writer.uint32(/* id 7, wireType 5 =*/61).float(message.scale);
            if (message.rotation != null && Object.hasOwnProperty.call(message, "rotation"))
                writer.uint32(/* id 8, wireType 5 =*/69).float(message.rotation);
            if (message.velocity != null && Object.hasOwnProperty.call(message, "velocity"))
                writer.uint32(/* id 9, wireType 5 =*/77).float(message.velocity);
            if (message.velocityX != null && Object.hasOwnProperty.call(message, "velocityX"))
                writer.uint32(/* id 10, wireType 5 =*/85).float(message.velocityX);
            if (message.velocityY != null && Object.hasOwnProperty.call(message, "velocityY"))
                writer.uint32(/* id 11, wireType 5 =*/93).float(message.velocityY);
            return writer;
        };

        /**
         * Encodes the specified GestureInfo message, length delimited. Does not implicitly {@link MapCloudProto.GestureInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {MapCloudProto.IGestureInfo} message GestureInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GestureInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GestureInfo message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.GestureInfo} GestureInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GestureInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.GestureInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deviceId = reader.int32();
                        break;
                    }
                case 2: {
                        message.gestureType = reader.int32();
                        break;
                    }
                case 3: {
                        message.gestureState = reader.int32();
                        break;
                    }
                case 4: {
                        message.x = reader.float();
                        break;
                    }
                case 5: {
                        message.y = reader.float();
                        break;
                    }
                case 6: {
                        message.numberOfTouches = reader.uint32();
                        break;
                    }
                case 7: {
                        message.scale = reader.float();
                        break;
                    }
                case 8: {
                        message.rotation = reader.float();
                        break;
                    }
                case 9: {
                        message.velocity = reader.float();
                        break;
                    }
                case 10: {
                        message.velocityX = reader.float();
                        break;
                    }
                case 11: {
                        message.velocityY = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GestureInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.GestureInfo} GestureInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GestureInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GestureInfo message.
         * @function verify
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GestureInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                if (!$util.isInteger(message.deviceId))
                    return "deviceId: integer expected";
            if (message.gestureType != null && message.hasOwnProperty("gestureType"))
                switch (message.gestureType) {
                default:
                    return "gestureType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    break;
                }
            if (message.gestureState != null && message.hasOwnProperty("gestureState"))
                switch (message.gestureState) {
                default:
                    return "gestureState: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (typeof message.y !== "number")
                    return "y: number expected";
            if (message.numberOfTouches != null && message.hasOwnProperty("numberOfTouches"))
                if (!$util.isInteger(message.numberOfTouches))
                    return "numberOfTouches: integer expected";
            if (message.scale != null && message.hasOwnProperty("scale"))
                if (typeof message.scale !== "number")
                    return "scale: number expected";
            if (message.rotation != null && message.hasOwnProperty("rotation"))
                if (typeof message.rotation !== "number")
                    return "rotation: number expected";
            if (message.velocity != null && message.hasOwnProperty("velocity"))
                if (typeof message.velocity !== "number")
                    return "velocity: number expected";
            if (message.velocityX != null && message.hasOwnProperty("velocityX"))
                if (typeof message.velocityX !== "number")
                    return "velocityX: number expected";
            if (message.velocityY != null && message.hasOwnProperty("velocityY"))
                if (typeof message.velocityY !== "number")
                    return "velocityY: number expected";
            return null;
        };

        /**
         * Creates a GestureInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.GestureInfo} GestureInfo
         */
        GestureInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.GestureInfo)
                return object;
            let message = new $root.MapCloudProto.GestureInfo();
            if (object.deviceId != null)
                message.deviceId = object.deviceId | 0;
            switch (object.gestureType) {
            default:
                if (typeof object.gestureType === "number") {
                    message.gestureType = object.gestureType;
                    break;
                }
                break;
            case "MapGestureTypeNone":
            case 0:
                message.gestureType = 0;
                break;
            case "MapGestureTypeTapZoomIn":
            case 1:
                message.gestureType = 1;
                break;
            case "MapGestureTypeTapZoomOut":
            case 2:
                message.gestureType = 2;
                break;
            case "MapGestureTypeMove":
            case 3:
                message.gestureType = 3;
                break;
            case "MapGestureTypePinchZoom":
            case 4:
                message.gestureType = 4;
                break;
            case "MapGestureTypeRotation":
            case 5:
                message.gestureType = 5;
                break;
            case "MapGestureTypeCameraHeader":
            case 6:
                message.gestureType = 6;
                break;
            case "MapGestureTypeLongPress":
            case 7:
                message.gestureType = 7;
                break;
            case "MapGestureTypeSingleTap":
            case 8:
                message.gestureType = 8;
                break;
            case "MapGestureTypeSingleZoom":
            case 9:
                message.gestureType = 9;
                break;
            }
            switch (object.gestureState) {
            default:
                if (typeof object.gestureState === "number") {
                    message.gestureState = object.gestureState;
                    break;
                }
                break;
            case "MapGestureStatePossible":
            case 0:
                message.gestureState = 0;
                break;
            case "MapGestureStateBegan":
            case 1:
                message.gestureState = 1;
                break;
            case "MapGestureStateChanged":
            case 2:
                message.gestureState = 2;
                break;
            case "MapGestureStateEnded":
            case 3:
                message.gestureState = 3;
                break;
            case "MapGestureStateCancelled":
            case 4:
                message.gestureState = 4;
                break;
            case "MapGestureStateFailed":
            case 5:
                message.gestureState = 5;
                break;
            case "MapGestureStateRecognized":
            case 6:
                message.gestureState = 6;
                break;
            }
            if (object.x != null)
                message.x = Number(object.x);
            if (object.y != null)
                message.y = Number(object.y);
            if (object.numberOfTouches != null)
                message.numberOfTouches = object.numberOfTouches >>> 0;
            if (object.scale != null)
                message.scale = Number(object.scale);
            if (object.rotation != null)
                message.rotation = Number(object.rotation);
            if (object.velocity != null)
                message.velocity = Number(object.velocity);
            if (object.velocityX != null)
                message.velocityX = Number(object.velocityX);
            if (object.velocityY != null)
                message.velocityY = Number(object.velocityY);
            return message;
        };

        /**
         * Creates a plain object from a GestureInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {MapCloudProto.GestureInfo} message GestureInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GestureInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.deviceId = 0;
                object.gestureType = options.enums === String ? "MapGestureTypeNone" : 0;
                object.gestureState = options.enums === String ? "MapGestureStatePossible" : 0;
                object.x = 0;
                object.y = 0;
                object.numberOfTouches = 0;
                object.scale = 0;
                object.rotation = 0;
                object.velocity = 0;
                object.velocityX = 0;
                object.velocityY = 0;
            }
            if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                object.deviceId = message.deviceId;
            if (message.gestureType != null && message.hasOwnProperty("gestureType"))
                object.gestureType = options.enums === String ? $root.MapCloudProto.MapGestureType[message.gestureType] === undefined ? message.gestureType : $root.MapCloudProto.MapGestureType[message.gestureType] : message.gestureType;
            if (message.gestureState != null && message.hasOwnProperty("gestureState"))
                object.gestureState = options.enums === String ? $root.MapCloudProto.MapGestureState[message.gestureState] === undefined ? message.gestureState : $root.MapCloudProto.MapGestureState[message.gestureState] : message.gestureState;
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
            if (message.numberOfTouches != null && message.hasOwnProperty("numberOfTouches"))
                object.numberOfTouches = message.numberOfTouches;
            if (message.scale != null && message.hasOwnProperty("scale"))
                object.scale = options.json && !isFinite(message.scale) ? String(message.scale) : message.scale;
            if (message.rotation != null && message.hasOwnProperty("rotation"))
                object.rotation = options.json && !isFinite(message.rotation) ? String(message.rotation) : message.rotation;
            if (message.velocity != null && message.hasOwnProperty("velocity"))
                object.velocity = options.json && !isFinite(message.velocity) ? String(message.velocity) : message.velocity;
            if (message.velocityX != null && message.hasOwnProperty("velocityX"))
                object.velocityX = options.json && !isFinite(message.velocityX) ? String(message.velocityX) : message.velocityX;
            if (message.velocityY != null && message.hasOwnProperty("velocityY"))
                object.velocityY = options.json && !isFinite(message.velocityY) ? String(message.velocityY) : message.velocityY;
            return object;
        };

        /**
         * Converts this GestureInfo to JSON.
         * @function toJSON
         * @memberof MapCloudProto.GestureInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GestureInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GestureInfo
         * @function getTypeUrl
         * @memberof MapCloudProto.GestureInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GestureInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.GestureInfo";
        };

        return GestureInfo;
    })();

    MapCloudProto.StyleFileInfo = (function() {

        /**
         * Properties of a StyleFileInfo.
         * @memberof MapCloudProto
         * @interface IStyleFileInfo
         * @property {MapCloudProto.StyleTypeEnum} type StyleFileInfo type
         * @property {string} fileName StyleFileInfo fileName
         * @property {Uint8Array} data StyleFileInfo data
         */

        /**
         * Constructs a new StyleFileInfo.
         * @memberof MapCloudProto
         * @classdesc Represents a StyleFileInfo.
         * @implements IStyleFileInfo
         * @constructor
         * @param {MapCloudProto.IStyleFileInfo=} [properties] Properties to set
         */
        function StyleFileInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StyleFileInfo type.
         * @member {MapCloudProto.StyleTypeEnum} type
         * @memberof MapCloudProto.StyleFileInfo
         * @instance
         */
        StyleFileInfo.prototype.type = 0;

        /**
         * StyleFileInfo fileName.
         * @member {string} fileName
         * @memberof MapCloudProto.StyleFileInfo
         * @instance
         */
        StyleFileInfo.prototype.fileName = "";

        /**
         * StyleFileInfo data.
         * @member {Uint8Array} data
         * @memberof MapCloudProto.StyleFileInfo
         * @instance
         */
        StyleFileInfo.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new StyleFileInfo instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {MapCloudProto.IStyleFileInfo=} [properties] Properties to set
         * @returns {MapCloudProto.StyleFileInfo} StyleFileInfo instance
         */
        StyleFileInfo.create = function create(properties) {
            return new StyleFileInfo(properties);
        };

        /**
         * Encodes the specified StyleFileInfo message. Does not implicitly {@link MapCloudProto.StyleFileInfo.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {MapCloudProto.IStyleFileInfo} message StyleFileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StyleFileInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileName);
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified StyleFileInfo message, length delimited. Does not implicitly {@link MapCloudProto.StyleFileInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {MapCloudProto.IStyleFileInfo} message StyleFileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StyleFileInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StyleFileInfo message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.StyleFileInfo} StyleFileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StyleFileInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.StyleFileInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.fileName = reader.string();
                        break;
                    }
                case 3: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("type"))
                throw $util.ProtocolError("missing required 'type'", { instance: message });
            if (!message.hasOwnProperty("fileName"))
                throw $util.ProtocolError("missing required 'fileName'", { instance: message });
            if (!message.hasOwnProperty("data"))
                throw $util.ProtocolError("missing required 'data'", { instance: message });
            return message;
        };

        /**
         * Decodes a StyleFileInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.StyleFileInfo} StyleFileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StyleFileInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StyleFileInfo message.
         * @function verify
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StyleFileInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
                break;
            }
            if (!$util.isString(message.fileName))
                return "fileName: string expected";
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
            return null;
        };

        /**
         * Creates a StyleFileInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.StyleFileInfo} StyleFileInfo
         */
        StyleFileInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.StyleFileInfo)
                return object;
            let message = new $root.MapCloudProto.StyleFileInfo();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "StyleTypeNormal":
            case 0:
                message.type = 0;
                break;
            case "StyleTypeSenior":
            case 1:
                message.type = 1;
                break;
            }
            if (object.fileName != null)
                message.fileName = String(object.fileName);
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a StyleFileInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {MapCloudProto.StyleFileInfo} message StyleFileInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StyleFileInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "StyleTypeNormal" : 0;
                object.fileName = "";
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.MapCloudProto.StyleTypeEnum[message.type] === undefined ? message.type : $root.MapCloudProto.StyleTypeEnum[message.type] : message.type;
            if (message.fileName != null && message.hasOwnProperty("fileName"))
                object.fileName = message.fileName;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this StyleFileInfo to JSON.
         * @function toJSON
         * @memberof MapCloudProto.StyleFileInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StyleFileInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StyleFileInfo
         * @function getTypeUrl
         * @memberof MapCloudProto.StyleFileInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StyleFileInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.StyleFileInfo";
        };

        return StyleFileInfo;
    })();

    MapCloudProto.UpdateStyleData = (function() {

        /**
         * Properties of an UpdateStyleData.
         * @memberof MapCloudProto
         * @interface IUpdateStyleData
         * @property {boolean} isWholeFile UpdateStyleData isWholeFile
         * @property {Array.<MapCloudProto.IStyleFileInfo>|null} [styleFileInfo] UpdateStyleData styleFileInfo
         */

        /**
         * Constructs a new UpdateStyleData.
         * @memberof MapCloudProto
         * @classdesc Represents an UpdateStyleData.
         * @implements IUpdateStyleData
         * @constructor
         * @param {MapCloudProto.IUpdateStyleData=} [properties] Properties to set
         */
        function UpdateStyleData(properties) {
            this.styleFileInfo = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateStyleData isWholeFile.
         * @member {boolean} isWholeFile
         * @memberof MapCloudProto.UpdateStyleData
         * @instance
         */
        UpdateStyleData.prototype.isWholeFile = false;

        /**
         * UpdateStyleData styleFileInfo.
         * @member {Array.<MapCloudProto.IStyleFileInfo>} styleFileInfo
         * @memberof MapCloudProto.UpdateStyleData
         * @instance
         */
        UpdateStyleData.prototype.styleFileInfo = $util.emptyArray;

        /**
         * Creates a new UpdateStyleData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {MapCloudProto.IUpdateStyleData=} [properties] Properties to set
         * @returns {MapCloudProto.UpdateStyleData} UpdateStyleData instance
         */
        UpdateStyleData.create = function create(properties) {
            return new UpdateStyleData(properties);
        };

        /**
         * Encodes the specified UpdateStyleData message. Does not implicitly {@link MapCloudProto.UpdateStyleData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {MapCloudProto.IUpdateStyleData} message UpdateStyleData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateStyleData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isWholeFile);
            if (message.styleFileInfo != null && message.styleFileInfo.length)
                for (let i = 0; i < message.styleFileInfo.length; ++i)
                    $root.MapCloudProto.StyleFileInfo.encode(message.styleFileInfo[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateStyleData message, length delimited. Does not implicitly {@link MapCloudProto.UpdateStyleData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {MapCloudProto.IUpdateStyleData} message UpdateStyleData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateStyleData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateStyleData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.UpdateStyleData} UpdateStyleData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateStyleData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.UpdateStyleData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isWholeFile = reader.bool();
                        break;
                    }
                case 2: {
                        if (!(message.styleFileInfo && message.styleFileInfo.length))
                            message.styleFileInfo = [];
                        message.styleFileInfo.push($root.MapCloudProto.StyleFileInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("isWholeFile"))
                throw $util.ProtocolError("missing required 'isWholeFile'", { instance: message });
            return message;
        };

        /**
         * Decodes an UpdateStyleData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.UpdateStyleData} UpdateStyleData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateStyleData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateStyleData message.
         * @function verify
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateStyleData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.isWholeFile !== "boolean")
                return "isWholeFile: boolean expected";
            if (message.styleFileInfo != null && message.hasOwnProperty("styleFileInfo")) {
                if (!Array.isArray(message.styleFileInfo))
                    return "styleFileInfo: array expected";
                for (let i = 0; i < message.styleFileInfo.length; ++i) {
                    let error = $root.MapCloudProto.StyleFileInfo.verify(message.styleFileInfo[i]);
                    if (error)
                        return "styleFileInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpdateStyleData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.UpdateStyleData} UpdateStyleData
         */
        UpdateStyleData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.UpdateStyleData)
                return object;
            let message = new $root.MapCloudProto.UpdateStyleData();
            if (object.isWholeFile != null)
                message.isWholeFile = Boolean(object.isWholeFile);
            if (object.styleFileInfo) {
                if (!Array.isArray(object.styleFileInfo))
                    throw TypeError(".MapCloudProto.UpdateStyleData.styleFileInfo: array expected");
                message.styleFileInfo = [];
                for (let i = 0; i < object.styleFileInfo.length; ++i) {
                    if (typeof object.styleFileInfo[i] !== "object")
                        throw TypeError(".MapCloudProto.UpdateStyleData.styleFileInfo: object expected");
                    message.styleFileInfo[i] = $root.MapCloudProto.StyleFileInfo.fromObject(object.styleFileInfo[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateStyleData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {MapCloudProto.UpdateStyleData} message UpdateStyleData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateStyleData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.styleFileInfo = [];
            if (options.defaults)
                object.isWholeFile = false;
            if (message.isWholeFile != null && message.hasOwnProperty("isWholeFile"))
                object.isWholeFile = message.isWholeFile;
            if (message.styleFileInfo && message.styleFileInfo.length) {
                object.styleFileInfo = [];
                for (let j = 0; j < message.styleFileInfo.length; ++j)
                    object.styleFileInfo[j] = $root.MapCloudProto.StyleFileInfo.toObject(message.styleFileInfo[j], options);
            }
            return object;
        };

        /**
         * Converts this UpdateStyleData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.UpdateStyleData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateStyleData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateStyleData
         * @function getTypeUrl
         * @memberof MapCloudProto.UpdateStyleData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateStyleData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.UpdateStyleData";
        };

        return UpdateStyleData;
    })();

    MapCloudProto.IconFileInfo = (function() {

        /**
         * Properties of an IconFileInfo.
         * @memberof MapCloudProto
         * @interface IIconFileInfo
         * @property {string} fileName IconFileInfo fileName
         * @property {Uint8Array} data IconFileInfo data
         */

        /**
         * Constructs a new IconFileInfo.
         * @memberof MapCloudProto
         * @classdesc Represents an IconFileInfo.
         * @implements IIconFileInfo
         * @constructor
         * @param {MapCloudProto.IIconFileInfo=} [properties] Properties to set
         */
        function IconFileInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IconFileInfo fileName.
         * @member {string} fileName
         * @memberof MapCloudProto.IconFileInfo
         * @instance
         */
        IconFileInfo.prototype.fileName = "";

        /**
         * IconFileInfo data.
         * @member {Uint8Array} data
         * @memberof MapCloudProto.IconFileInfo
         * @instance
         */
        IconFileInfo.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new IconFileInfo instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {MapCloudProto.IIconFileInfo=} [properties] Properties to set
         * @returns {MapCloudProto.IconFileInfo} IconFileInfo instance
         */
        IconFileInfo.create = function create(properties) {
            return new IconFileInfo(properties);
        };

        /**
         * Encodes the specified IconFileInfo message. Does not implicitly {@link MapCloudProto.IconFileInfo.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {MapCloudProto.IIconFileInfo} message IconFileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IconFileInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileName);
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified IconFileInfo message, length delimited. Does not implicitly {@link MapCloudProto.IconFileInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {MapCloudProto.IIconFileInfo} message IconFileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IconFileInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IconFileInfo message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.IconFileInfo} IconFileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IconFileInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.IconFileInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.fileName = reader.string();
                        break;
                    }
                case 3: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("fileName"))
                throw $util.ProtocolError("missing required 'fileName'", { instance: message });
            if (!message.hasOwnProperty("data"))
                throw $util.ProtocolError("missing required 'data'", { instance: message });
            return message;
        };

        /**
         * Decodes an IconFileInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.IconFileInfo} IconFileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IconFileInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IconFileInfo message.
         * @function verify
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IconFileInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.fileName))
                return "fileName: string expected";
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
            return null;
        };

        /**
         * Creates an IconFileInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.IconFileInfo} IconFileInfo
         */
        IconFileInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.IconFileInfo)
                return object;
            let message = new $root.MapCloudProto.IconFileInfo();
            if (object.fileName != null)
                message.fileName = String(object.fileName);
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from an IconFileInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {MapCloudProto.IconFileInfo} message IconFileInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IconFileInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileName = "";
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.fileName != null && message.hasOwnProperty("fileName"))
                object.fileName = message.fileName;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this IconFileInfo to JSON.
         * @function toJSON
         * @memberof MapCloudProto.IconFileInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IconFileInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IconFileInfo
         * @function getTypeUrl
         * @memberof MapCloudProto.IconFileInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IconFileInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.IconFileInfo";
        };

        return IconFileInfo;
    })();

    MapCloudProto.UpdateIconData = (function() {

        /**
         * Properties of an UpdateIconData.
         * @memberof MapCloudProto
         * @interface IUpdateIconData
         * @property {Array.<MapCloudProto.IIconFileInfo>|null} [iconFileInfo] UpdateIconData iconFileInfo
         */

        /**
         * Constructs a new UpdateIconData.
         * @memberof MapCloudProto
         * @classdesc Represents an UpdateIconData.
         * @implements IUpdateIconData
         * @constructor
         * @param {MapCloudProto.IUpdateIconData=} [properties] Properties to set
         */
        function UpdateIconData(properties) {
            this.iconFileInfo = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateIconData iconFileInfo.
         * @member {Array.<MapCloudProto.IIconFileInfo>} iconFileInfo
         * @memberof MapCloudProto.UpdateIconData
         * @instance
         */
        UpdateIconData.prototype.iconFileInfo = $util.emptyArray;

        /**
         * Creates a new UpdateIconData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {MapCloudProto.IUpdateIconData=} [properties] Properties to set
         * @returns {MapCloudProto.UpdateIconData} UpdateIconData instance
         */
        UpdateIconData.create = function create(properties) {
            return new UpdateIconData(properties);
        };

        /**
         * Encodes the specified UpdateIconData message. Does not implicitly {@link MapCloudProto.UpdateIconData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {MapCloudProto.IUpdateIconData} message UpdateIconData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateIconData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.iconFileInfo != null && message.iconFileInfo.length)
                for (let i = 0; i < message.iconFileInfo.length; ++i)
                    $root.MapCloudProto.IconFileInfo.encode(message.iconFileInfo[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateIconData message, length delimited. Does not implicitly {@link MapCloudProto.UpdateIconData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {MapCloudProto.IUpdateIconData} message UpdateIconData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateIconData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateIconData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.UpdateIconData} UpdateIconData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateIconData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.UpdateIconData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.iconFileInfo && message.iconFileInfo.length))
                            message.iconFileInfo = [];
                        message.iconFileInfo.push($root.MapCloudProto.IconFileInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateIconData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.UpdateIconData} UpdateIconData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateIconData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateIconData message.
         * @function verify
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateIconData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.iconFileInfo != null && message.hasOwnProperty("iconFileInfo")) {
                if (!Array.isArray(message.iconFileInfo))
                    return "iconFileInfo: array expected";
                for (let i = 0; i < message.iconFileInfo.length; ++i) {
                    let error = $root.MapCloudProto.IconFileInfo.verify(message.iconFileInfo[i]);
                    if (error)
                        return "iconFileInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UpdateIconData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.UpdateIconData} UpdateIconData
         */
        UpdateIconData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.UpdateIconData)
                return object;
            let message = new $root.MapCloudProto.UpdateIconData();
            if (object.iconFileInfo) {
                if (!Array.isArray(object.iconFileInfo))
                    throw TypeError(".MapCloudProto.UpdateIconData.iconFileInfo: array expected");
                message.iconFileInfo = [];
                for (let i = 0; i < object.iconFileInfo.length; ++i) {
                    if (typeof object.iconFileInfo[i] !== "object")
                        throw TypeError(".MapCloudProto.UpdateIconData.iconFileInfo: object expected");
                    message.iconFileInfo[i] = $root.MapCloudProto.IconFileInfo.fromObject(object.iconFileInfo[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateIconData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {MapCloudProto.UpdateIconData} message UpdateIconData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateIconData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.iconFileInfo = [];
            if (message.iconFileInfo && message.iconFileInfo.length) {
                object.iconFileInfo = [];
                for (let j = 0; j < message.iconFileInfo.length; ++j)
                    object.iconFileInfo[j] = $root.MapCloudProto.IconFileInfo.toObject(message.iconFileInfo[j], options);
            }
            return object;
        };

        /**
         * Converts this UpdateIconData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.UpdateIconData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateIconData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateIconData
         * @function getTypeUrl
         * @memberof MapCloudProto.UpdateIconData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateIconData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.UpdateIconData";
        };

        return UpdateIconData;
    })();

    MapCloudProto.UpdateCustomFileConfigData = (function() {

        /**
         * Properties of an UpdateCustomFileConfigData.
         * @memberof MapCloudProto
         * @interface IUpdateCustomFileConfigData
         * @property {string} configFileName UpdateCustomFileConfigData configFileName
         * @property {Uint8Array} configFileData UpdateCustomFileConfigData configFileData
         */

        /**
         * Constructs a new UpdateCustomFileConfigData.
         * @memberof MapCloudProto
         * @classdesc Represents an UpdateCustomFileConfigData.
         * @implements IUpdateCustomFileConfigData
         * @constructor
         * @param {MapCloudProto.IUpdateCustomFileConfigData=} [properties] Properties to set
         */
        function UpdateCustomFileConfigData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateCustomFileConfigData configFileName.
         * @member {string} configFileName
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @instance
         */
        UpdateCustomFileConfigData.prototype.configFileName = "";

        /**
         * UpdateCustomFileConfigData configFileData.
         * @member {Uint8Array} configFileData
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @instance
         */
        UpdateCustomFileConfigData.prototype.configFileData = $util.newBuffer([]);

        /**
         * Creates a new UpdateCustomFileConfigData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {MapCloudProto.IUpdateCustomFileConfigData=} [properties] Properties to set
         * @returns {MapCloudProto.UpdateCustomFileConfigData} UpdateCustomFileConfigData instance
         */
        UpdateCustomFileConfigData.create = function create(properties) {
            return new UpdateCustomFileConfigData(properties);
        };

        /**
         * Encodes the specified UpdateCustomFileConfigData message. Does not implicitly {@link MapCloudProto.UpdateCustomFileConfigData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {MapCloudProto.IUpdateCustomFileConfigData} message UpdateCustomFileConfigData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateCustomFileConfigData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.configFileName);
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.configFileData);
            return writer;
        };

        /**
         * Encodes the specified UpdateCustomFileConfigData message, length delimited. Does not implicitly {@link MapCloudProto.UpdateCustomFileConfigData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {MapCloudProto.IUpdateCustomFileConfigData} message UpdateCustomFileConfigData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateCustomFileConfigData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateCustomFileConfigData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.UpdateCustomFileConfigData} UpdateCustomFileConfigData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateCustomFileConfigData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.UpdateCustomFileConfigData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.configFileName = reader.string();
                        break;
                    }
                case 2: {
                        message.configFileData = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("configFileName"))
                throw $util.ProtocolError("missing required 'configFileName'", { instance: message });
            if (!message.hasOwnProperty("configFileData"))
                throw $util.ProtocolError("missing required 'configFileData'", { instance: message });
            return message;
        };

        /**
         * Decodes an UpdateCustomFileConfigData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.UpdateCustomFileConfigData} UpdateCustomFileConfigData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateCustomFileConfigData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateCustomFileConfigData message.
         * @function verify
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateCustomFileConfigData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.configFileName))
                return "configFileName: string expected";
            if (!(message.configFileData && typeof message.configFileData.length === "number" || $util.isString(message.configFileData)))
                return "configFileData: buffer expected";
            return null;
        };

        /**
         * Creates an UpdateCustomFileConfigData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.UpdateCustomFileConfigData} UpdateCustomFileConfigData
         */
        UpdateCustomFileConfigData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.UpdateCustomFileConfigData)
                return object;
            let message = new $root.MapCloudProto.UpdateCustomFileConfigData();
            if (object.configFileName != null)
                message.configFileName = String(object.configFileName);
            if (object.configFileData != null)
                if (typeof object.configFileData === "string")
                    $util.base64.decode(object.configFileData, message.configFileData = $util.newBuffer($util.base64.length(object.configFileData)), 0);
                else if (object.configFileData.length >= 0)
                    message.configFileData = object.configFileData;
            return message;
        };

        /**
         * Creates a plain object from an UpdateCustomFileConfigData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {MapCloudProto.UpdateCustomFileConfigData} message UpdateCustomFileConfigData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateCustomFileConfigData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.configFileName = "";
                if (options.bytes === String)
                    object.configFileData = "";
                else {
                    object.configFileData = [];
                    if (options.bytes !== Array)
                        object.configFileData = $util.newBuffer(object.configFileData);
                }
            }
            if (message.configFileName != null && message.hasOwnProperty("configFileName"))
                object.configFileName = message.configFileName;
            if (message.configFileData != null && message.hasOwnProperty("configFileData"))
                object.configFileData = options.bytes === String ? $util.base64.encode(message.configFileData, 0, message.configFileData.length) : options.bytes === Array ? Array.prototype.slice.call(message.configFileData) : message.configFileData;
            return object;
        };

        /**
         * Converts this UpdateCustomFileConfigData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateCustomFileConfigData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateCustomFileConfigData
         * @function getTypeUrl
         * @memberof MapCloudProto.UpdateCustomFileConfigData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateCustomFileConfigData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.UpdateCustomFileConfigData";
        };

        return UpdateCustomFileConfigData;
    })();

    MapCloudProto.ModifyModelPropertiesData = (function() {

        /**
         * Properties of a ModifyModelPropertiesData.
         * @memberof MapCloudProto
         * @interface IModifyModelPropertiesData
         * @property {string|null} [uri] ModifyModelPropertiesData uri
         * @property {number} mType ModifyModelPropertiesData mType
         * @property {number} layerId ModifyModelPropertiesData layerId
         * @property {number} sublayerId ModifyModelPropertiesData sublayerId
         * @property {number|Long|null} [tileId] ModifyModelPropertiesData tileId
         * @property {Uint8Array|null} [buffer] ModifyModelPropertiesData buffer
         * @property {string|null} [body] ModifyModelPropertiesData body
         */

        /**
         * Constructs a new ModifyModelPropertiesData.
         * @memberof MapCloudProto
         * @classdesc Represents a ModifyModelPropertiesData.
         * @implements IModifyModelPropertiesData
         * @constructor
         * @param {MapCloudProto.IModifyModelPropertiesData=} [properties] Properties to set
         */
        function ModifyModelPropertiesData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ModifyModelPropertiesData uri.
         * @member {string} uri
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @instance
         */
        ModifyModelPropertiesData.prototype.uri = "";

        /**
         * ModifyModelPropertiesData mType.
         * @member {number} mType
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @instance
         */
        ModifyModelPropertiesData.prototype.mType = 0;

        /**
         * ModifyModelPropertiesData layerId.
         * @member {number} layerId
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @instance
         */
        ModifyModelPropertiesData.prototype.layerId = 0;

        /**
         * ModifyModelPropertiesData sublayerId.
         * @member {number} sublayerId
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @instance
         */
        ModifyModelPropertiesData.prototype.sublayerId = 0;

        /**
         * ModifyModelPropertiesData tileId.
         * @member {number|Long} tileId
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @instance
         */
        ModifyModelPropertiesData.prototype.tileId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ModifyModelPropertiesData buffer.
         * @member {Uint8Array} buffer
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @instance
         */
        ModifyModelPropertiesData.prototype.buffer = $util.newBuffer([]);

        /**
         * ModifyModelPropertiesData body.
         * @member {string} body
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @instance
         */
        ModifyModelPropertiesData.prototype.body = "";

        /**
         * Creates a new ModifyModelPropertiesData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {MapCloudProto.IModifyModelPropertiesData=} [properties] Properties to set
         * @returns {MapCloudProto.ModifyModelPropertiesData} ModifyModelPropertiesData instance
         */
        ModifyModelPropertiesData.create = function create(properties) {
            return new ModifyModelPropertiesData(properties);
        };

        /**
         * Encodes the specified ModifyModelPropertiesData message. Does not implicitly {@link MapCloudProto.ModifyModelPropertiesData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {MapCloudProto.IModifyModelPropertiesData} message ModifyModelPropertiesData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyModelPropertiesData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uri != null && Object.hasOwnProperty.call(message, "uri"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uri);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.mType);
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.layerId);
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sublayerId);
            if (message.tileId != null && Object.hasOwnProperty.call(message, "tileId"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.tileId);
            if (message.buffer != null && Object.hasOwnProperty.call(message, "buffer"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.buffer);
            if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.body);
            return writer;
        };

        /**
         * Encodes the specified ModifyModelPropertiesData message, length delimited. Does not implicitly {@link MapCloudProto.ModifyModelPropertiesData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {MapCloudProto.IModifyModelPropertiesData} message ModifyModelPropertiesData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyModelPropertiesData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ModifyModelPropertiesData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.ModifyModelPropertiesData} ModifyModelPropertiesData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyModelPropertiesData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.ModifyModelPropertiesData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uri = reader.string();
                        break;
                    }
                case 2: {
                        message.mType = reader.int32();
                        break;
                    }
                case 3: {
                        message.layerId = reader.int32();
                        break;
                    }
                case 4: {
                        message.sublayerId = reader.int32();
                        break;
                    }
                case 5: {
                        message.tileId = reader.int64();
                        break;
                    }
                case 6: {
                        message.buffer = reader.bytes();
                        break;
                    }
                case 7: {
                        message.body = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("mType"))
                throw $util.ProtocolError("missing required 'mType'", { instance: message });
            if (!message.hasOwnProperty("layerId"))
                throw $util.ProtocolError("missing required 'layerId'", { instance: message });
            if (!message.hasOwnProperty("sublayerId"))
                throw $util.ProtocolError("missing required 'sublayerId'", { instance: message });
            return message;
        };

        /**
         * Decodes a ModifyModelPropertiesData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.ModifyModelPropertiesData} ModifyModelPropertiesData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyModelPropertiesData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ModifyModelPropertiesData message.
         * @function verify
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ModifyModelPropertiesData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uri != null && message.hasOwnProperty("uri"))
                if (!$util.isString(message.uri))
                    return "uri: string expected";
            if (!$util.isInteger(message.mType))
                return "mType: integer expected";
            if (!$util.isInteger(message.layerId))
                return "layerId: integer expected";
            if (!$util.isInteger(message.sublayerId))
                return "sublayerId: integer expected";
            if (message.tileId != null && message.hasOwnProperty("tileId"))
                if (!$util.isInteger(message.tileId) && !(message.tileId && $util.isInteger(message.tileId.low) && $util.isInteger(message.tileId.high)))
                    return "tileId: integer|Long expected";
            if (message.buffer != null && message.hasOwnProperty("buffer"))
                if (!(message.buffer && typeof message.buffer.length === "number" || $util.isString(message.buffer)))
                    return "buffer: buffer expected";
            if (message.body != null && message.hasOwnProperty("body"))
                if (!$util.isString(message.body))
                    return "body: string expected";
            return null;
        };

        /**
         * Creates a ModifyModelPropertiesData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.ModifyModelPropertiesData} ModifyModelPropertiesData
         */
        ModifyModelPropertiesData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.ModifyModelPropertiesData)
                return object;
            let message = new $root.MapCloudProto.ModifyModelPropertiesData();
            if (object.uri != null)
                message.uri = String(object.uri);
            if (object.mType != null)
                message.mType = object.mType | 0;
            if (object.layerId != null)
                message.layerId = object.layerId | 0;
            if (object.sublayerId != null)
                message.sublayerId = object.sublayerId | 0;
            if (object.tileId != null)
                if ($util.Long)
                    (message.tileId = $util.Long.fromValue(object.tileId)).unsigned = false;
                else if (typeof object.tileId === "string")
                    message.tileId = parseInt(object.tileId, 10);
                else if (typeof object.tileId === "number")
                    message.tileId = object.tileId;
                else if (typeof object.tileId === "object")
                    message.tileId = new $util.LongBits(object.tileId.low >>> 0, object.tileId.high >>> 0).toNumber();
            if (object.buffer != null)
                if (typeof object.buffer === "string")
                    $util.base64.decode(object.buffer, message.buffer = $util.newBuffer($util.base64.length(object.buffer)), 0);
                else if (object.buffer.length >= 0)
                    message.buffer = object.buffer;
            if (object.body != null)
                message.body = String(object.body);
            return message;
        };

        /**
         * Creates a plain object from a ModifyModelPropertiesData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {MapCloudProto.ModifyModelPropertiesData} message ModifyModelPropertiesData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ModifyModelPropertiesData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.uri = "";
                object.mType = 0;
                object.layerId = 0;
                object.sublayerId = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.tileId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.tileId = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.buffer = "";
                else {
                    object.buffer = [];
                    if (options.bytes !== Array)
                        object.buffer = $util.newBuffer(object.buffer);
                }
                object.body = "";
            }
            if (message.uri != null && message.hasOwnProperty("uri"))
                object.uri = message.uri;
            if (message.mType != null && message.hasOwnProperty("mType"))
                object.mType = message.mType;
            if (message.layerId != null && message.hasOwnProperty("layerId"))
                object.layerId = message.layerId;
            if (message.sublayerId != null && message.hasOwnProperty("sublayerId"))
                object.sublayerId = message.sublayerId;
            if (message.tileId != null && message.hasOwnProperty("tileId"))
                if (typeof message.tileId === "number")
                    object.tileId = options.longs === String ? String(message.tileId) : message.tileId;
                else
                    object.tileId = options.longs === String ? $util.Long.prototype.toString.call(message.tileId) : options.longs === Number ? new $util.LongBits(message.tileId.low >>> 0, message.tileId.high >>> 0).toNumber() : message.tileId;
            if (message.buffer != null && message.hasOwnProperty("buffer"))
                object.buffer = options.bytes === String ? $util.base64.encode(message.buffer, 0, message.buffer.length) : options.bytes === Array ? Array.prototype.slice.call(message.buffer) : message.buffer;
            if (message.body != null && message.hasOwnProperty("body"))
                object.body = message.body;
            return object;
        };

        /**
         * Converts this ModifyModelPropertiesData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ModifyModelPropertiesData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ModifyModelPropertiesData
         * @function getTypeUrl
         * @memberof MapCloudProto.ModifyModelPropertiesData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ModifyModelPropertiesData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.ModifyModelPropertiesData";
        };

        return ModifyModelPropertiesData;
    })();

    MapCloudProto.MapControllerStates = (function() {

        /**
         * Properties of a MapControllerStates.
         * @memberof MapCloudProto
         * @interface IMapControllerStates
         * @property {MapCloudProto.MapControllerStatesType} type MapControllerStates type
         * @property {number} value MapControllerStates value
         */

        /**
         * Constructs a new MapControllerStates.
         * @memberof MapCloudProto
         * @classdesc Represents a MapControllerStates.
         * @implements IMapControllerStates
         * @constructor
         * @param {MapCloudProto.IMapControllerStates=} [properties] Properties to set
         */
        function MapControllerStates(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapControllerStates type.
         * @member {MapCloudProto.MapControllerStatesType} type
         * @memberof MapCloudProto.MapControllerStates
         * @instance
         */
        MapControllerStates.prototype.type = 1;

        /**
         * MapControllerStates value.
         * @member {number} value
         * @memberof MapCloudProto.MapControllerStates
         * @instance
         */
        MapControllerStates.prototype.value = 0;

        /**
         * Creates a new MapControllerStates instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {MapCloudProto.IMapControllerStates=} [properties] Properties to set
         * @returns {MapCloudProto.MapControllerStates} MapControllerStates instance
         */
        MapControllerStates.create = function create(properties) {
            return new MapControllerStates(properties);
        };

        /**
         * Encodes the specified MapControllerStates message. Does not implicitly {@link MapCloudProto.MapControllerStates.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {MapCloudProto.IMapControllerStates} message MapControllerStates message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapControllerStates.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            return writer;
        };

        /**
         * Encodes the specified MapControllerStates message, length delimited. Does not implicitly {@link MapCloudProto.MapControllerStates.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {MapCloudProto.IMapControllerStates} message MapControllerStates message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapControllerStates.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapControllerStates message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.MapControllerStates} MapControllerStates
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapControllerStates.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.MapControllerStates();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.value = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("type"))
                throw $util.ProtocolError("missing required 'type'", { instance: message });
            if (!message.hasOwnProperty("value"))
                throw $util.ProtocolError("missing required 'value'", { instance: message });
            return message;
        };

        /**
         * Decodes a MapControllerStates message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.MapControllerStates} MapControllerStates
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapControllerStates.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapControllerStates message.
         * @function verify
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapControllerStates.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 1:
            case 2:
            case 3:
                break;
            }
            if (!$util.isInteger(message.value))
                return "value: integer expected";
            return null;
        };

        /**
         * Creates a MapControllerStates message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.MapControllerStates} MapControllerStates
         */
        MapControllerStates.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.MapControllerStates)
                return object;
            let message = new $root.MapCloudProto.MapControllerStates();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "MAP_CONTROLLER_ONOFF_TRAFFIC_STATE":
            case 1:
                message.type = 1;
                break;
            case "MAP_CONTROLLER_TRAFFIC_HIGHLIGHT":
            case 2:
                message.type = 2;
                break;
            case "MAP_CONTROLLER_TRAFFIC_COLORBLIND":
            case 3:
                message.type = 3;
                break;
            }
            if (object.value != null)
                message.value = object.value | 0;
            return message;
        };

        /**
         * Creates a plain object from a MapControllerStates message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {MapCloudProto.MapControllerStates} message MapControllerStates
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapControllerStates.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "MAP_CONTROLLER_ONOFF_TRAFFIC_STATE" : 1;
                object.value = 0;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.MapCloudProto.MapControllerStatesType[message.type] === undefined ? message.type : $root.MapCloudProto.MapControllerStatesType[message.type] : message.type;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this MapControllerStates to JSON.
         * @function toJSON
         * @memberof MapCloudProto.MapControllerStates
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapControllerStates.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapControllerStates
         * @function getTypeUrl
         * @memberof MapCloudProto.MapControllerStates
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapControllerStates.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.MapControllerStates";
        };

        return MapControllerStates;
    })();

    MapCloudProto.ShellCmdData = (function() {

        /**
         * Properties of a ShellCmdData.
         * @memberof MapCloudProto
         * @interface IShellCmdData
         * @property {string} name ShellCmdData name
         * @property {string|null} [parameter] ShellCmdData parameter
         * @property {string|null} [dir] ShellCmdData dir
         */

        /**
         * Constructs a new ShellCmdData.
         * @memberof MapCloudProto
         * @classdesc Represents a ShellCmdData.
         * @implements IShellCmdData
         * @constructor
         * @param {MapCloudProto.IShellCmdData=} [properties] Properties to set
         */
        function ShellCmdData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ShellCmdData name.
         * @member {string} name
         * @memberof MapCloudProto.ShellCmdData
         * @instance
         */
        ShellCmdData.prototype.name = "";

        /**
         * ShellCmdData parameter.
         * @member {string} parameter
         * @memberof MapCloudProto.ShellCmdData
         * @instance
         */
        ShellCmdData.prototype.parameter = "";

        /**
         * ShellCmdData dir.
         * @member {string} dir
         * @memberof MapCloudProto.ShellCmdData
         * @instance
         */
        ShellCmdData.prototype.dir = "";

        /**
         * Creates a new ShellCmdData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {MapCloudProto.IShellCmdData=} [properties] Properties to set
         * @returns {MapCloudProto.ShellCmdData} ShellCmdData instance
         */
        ShellCmdData.create = function create(properties) {
            return new ShellCmdData(properties);
        };

        /**
         * Encodes the specified ShellCmdData message. Does not implicitly {@link MapCloudProto.ShellCmdData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {MapCloudProto.IShellCmdData} message ShellCmdData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ShellCmdData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.parameter != null && Object.hasOwnProperty.call(message, "parameter"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.parameter);
            if (message.dir != null && Object.hasOwnProperty.call(message, "dir"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.dir);
            return writer;
        };

        /**
         * Encodes the specified ShellCmdData message, length delimited. Does not implicitly {@link MapCloudProto.ShellCmdData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {MapCloudProto.IShellCmdData} message ShellCmdData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ShellCmdData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ShellCmdData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.ShellCmdData} ShellCmdData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ShellCmdData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.ShellCmdData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.parameter = reader.string();
                        break;
                    }
                case 3: {
                        message.dir = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("name"))
                throw $util.ProtocolError("missing required 'name'", { instance: message });
            return message;
        };

        /**
         * Decodes a ShellCmdData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.ShellCmdData} ShellCmdData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ShellCmdData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ShellCmdData message.
         * @function verify
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ShellCmdData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.name))
                return "name: string expected";
            if (message.parameter != null && message.hasOwnProperty("parameter"))
                if (!$util.isString(message.parameter))
                    return "parameter: string expected";
            if (message.dir != null && message.hasOwnProperty("dir"))
                if (!$util.isString(message.dir))
                    return "dir: string expected";
            return null;
        };

        /**
         * Creates a ShellCmdData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.ShellCmdData} ShellCmdData
         */
        ShellCmdData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.ShellCmdData)
                return object;
            let message = new $root.MapCloudProto.ShellCmdData();
            if (object.name != null)
                message.name = String(object.name);
            if (object.parameter != null)
                message.parameter = String(object.parameter);
            if (object.dir != null)
                message.dir = String(object.dir);
            return message;
        };

        /**
         * Creates a plain object from a ShellCmdData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {MapCloudProto.ShellCmdData} message ShellCmdData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ShellCmdData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.parameter = "";
                object.dir = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.parameter != null && message.hasOwnProperty("parameter"))
                object.parameter = message.parameter;
            if (message.dir != null && message.hasOwnProperty("dir"))
                object.dir = message.dir;
            return object;
        };

        /**
         * Converts this ShellCmdData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.ShellCmdData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ShellCmdData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ShellCmdData
         * @function getTypeUrl
         * @memberof MapCloudProto.ShellCmdData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ShellCmdData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.ShellCmdData";
        };

        return ShellCmdData;
    })();

    MapCloudProto.WebrtcData = (function() {

        /**
         * Properties of a WebrtcData.
         * @memberof MapCloudProto
         * @interface IWebrtcData
         * @property {Uint8Array} data WebrtcData data
         */

        /**
         * Constructs a new WebrtcData.
         * @memberof MapCloudProto
         * @classdesc Represents a WebrtcData.
         * @implements IWebrtcData
         * @constructor
         * @param {MapCloudProto.IWebrtcData=} [properties] Properties to set
         */
        function WebrtcData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebrtcData data.
         * @member {Uint8Array} data
         * @memberof MapCloudProto.WebrtcData
         * @instance
         */
        WebrtcData.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new WebrtcData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {MapCloudProto.IWebrtcData=} [properties] Properties to set
         * @returns {MapCloudProto.WebrtcData} WebrtcData instance
         */
        WebrtcData.create = function create(properties) {
            return new WebrtcData(properties);
        };

        /**
         * Encodes the specified WebrtcData message. Does not implicitly {@link MapCloudProto.WebrtcData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {MapCloudProto.IWebrtcData} message WebrtcData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebrtcData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified WebrtcData message, length delimited. Does not implicitly {@link MapCloudProto.WebrtcData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {MapCloudProto.IWebrtcData} message WebrtcData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebrtcData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WebrtcData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.WebrtcData} WebrtcData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebrtcData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.WebrtcData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("data"))
                throw $util.ProtocolError("missing required 'data'", { instance: message });
            return message;
        };

        /**
         * Decodes a WebrtcData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.WebrtcData} WebrtcData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebrtcData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WebrtcData message.
         * @function verify
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WebrtcData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
            return null;
        };

        /**
         * Creates a WebrtcData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.WebrtcData} WebrtcData
         */
        WebrtcData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.WebrtcData)
                return object;
            let message = new $root.MapCloudProto.WebrtcData();
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a WebrtcData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {MapCloudProto.WebrtcData} message WebrtcData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebrtcData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this WebrtcData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.WebrtcData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebrtcData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebrtcData
         * @function getTypeUrl
         * @memberof MapCloudProto.WebrtcData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebrtcData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.WebrtcData";
        };

        return WebrtcData;
    })();

    MapCloudProto.ZoomLevelData = (function() {

        /**
         * Properties of a ZoomLevelData.
         * @memberof MapCloudProto
         * @interface IZoomLevelData
         * @property {number|null} [level] ZoomLevelData level
         * @property {number|null} [increment] ZoomLevelData increment
         * @property {number|null} [pointX] ZoomLevelData pointX
         * @property {number|null} [pointY] ZoomLevelData pointY
         * @property {boolean|null} [animate] ZoomLevelData animate
         */

        /**
         * Constructs a new ZoomLevelData.
         * @memberof MapCloudProto
         * @classdesc Represents a ZoomLevelData.
         * @implements IZoomLevelData
         * @constructor
         * @param {MapCloudProto.IZoomLevelData=} [properties] Properties to set
         */
        function ZoomLevelData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZoomLevelData level.
         * @member {number} level
         * @memberof MapCloudProto.ZoomLevelData
         * @instance
         */
        ZoomLevelData.prototype.level = 0;

        /**
         * ZoomLevelData increment.
         * @member {number} increment
         * @memberof MapCloudProto.ZoomLevelData
         * @instance
         */
        ZoomLevelData.prototype.increment = 0;

        /**
         * ZoomLevelData pointX.
         * @member {number} pointX
         * @memberof MapCloudProto.ZoomLevelData
         * @instance
         */
        ZoomLevelData.prototype.pointX = 0;

        /**
         * ZoomLevelData pointY.
         * @member {number} pointY
         * @memberof MapCloudProto.ZoomLevelData
         * @instance
         */
        ZoomLevelData.prototype.pointY = 0;

        /**
         * ZoomLevelData animate.
         * @member {boolean} animate
         * @memberof MapCloudProto.ZoomLevelData
         * @instance
         */
        ZoomLevelData.prototype.animate = false;

        /**
         * Creates a new ZoomLevelData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {MapCloudProto.IZoomLevelData=} [properties] Properties to set
         * @returns {MapCloudProto.ZoomLevelData} ZoomLevelData instance
         */
        ZoomLevelData.create = function create(properties) {
            return new ZoomLevelData(properties);
        };

        /**
         * Encodes the specified ZoomLevelData message. Does not implicitly {@link MapCloudProto.ZoomLevelData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {MapCloudProto.IZoomLevelData} message ZoomLevelData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZoomLevelData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.level);
            if (message.increment != null && Object.hasOwnProperty.call(message, "increment"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.increment);
            if (message.pointX != null && Object.hasOwnProperty.call(message, "pointX"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.pointX);
            if (message.pointY != null && Object.hasOwnProperty.call(message, "pointY"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.pointY);
            if (message.animate != null && Object.hasOwnProperty.call(message, "animate"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.animate);
            return writer;
        };

        /**
         * Encodes the specified ZoomLevelData message, length delimited. Does not implicitly {@link MapCloudProto.ZoomLevelData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {MapCloudProto.IZoomLevelData} message ZoomLevelData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ZoomLevelData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ZoomLevelData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.ZoomLevelData} ZoomLevelData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZoomLevelData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.ZoomLevelData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.level = reader.float();
                        break;
                    }
                case 2: {
                        message.increment = reader.float();
                        break;
                    }
                case 3: {
                        message.pointX = reader.float();
                        break;
                    }
                case 4: {
                        message.pointY = reader.float();
                        break;
                    }
                case 5: {
                        message.animate = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ZoomLevelData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.ZoomLevelData} ZoomLevelData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ZoomLevelData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ZoomLevelData message.
         * @function verify
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ZoomLevelData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (typeof message.level !== "number")
                    return "level: number expected";
            if (message.increment != null && message.hasOwnProperty("increment"))
                if (typeof message.increment !== "number")
                    return "increment: number expected";
            if (message.pointX != null && message.hasOwnProperty("pointX"))
                if (typeof message.pointX !== "number")
                    return "pointX: number expected";
            if (message.pointY != null && message.hasOwnProperty("pointY"))
                if (typeof message.pointY !== "number")
                    return "pointY: number expected";
            if (message.animate != null && message.hasOwnProperty("animate"))
                if (typeof message.animate !== "boolean")
                    return "animate: boolean expected";
            return null;
        };

        /**
         * Creates a ZoomLevelData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.ZoomLevelData} ZoomLevelData
         */
        ZoomLevelData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.ZoomLevelData)
                return object;
            let message = new $root.MapCloudProto.ZoomLevelData();
            if (object.level != null)
                message.level = Number(object.level);
            if (object.increment != null)
                message.increment = Number(object.increment);
            if (object.pointX != null)
                message.pointX = Number(object.pointX);
            if (object.pointY != null)
                message.pointY = Number(object.pointY);
            if (object.animate != null)
                message.animate = Boolean(object.animate);
            return message;
        };

        /**
         * Creates a plain object from a ZoomLevelData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {MapCloudProto.ZoomLevelData} message ZoomLevelData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ZoomLevelData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.level = 0;
                object.increment = 0;
                object.pointX = 0;
                object.pointY = 0;
                object.animate = false;
            }
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = options.json && !isFinite(message.level) ? String(message.level) : message.level;
            if (message.increment != null && message.hasOwnProperty("increment"))
                object.increment = options.json && !isFinite(message.increment) ? String(message.increment) : message.increment;
            if (message.pointX != null && message.hasOwnProperty("pointX"))
                object.pointX = options.json && !isFinite(message.pointX) ? String(message.pointX) : message.pointX;
            if (message.pointY != null && message.hasOwnProperty("pointY"))
                object.pointY = options.json && !isFinite(message.pointY) ? String(message.pointY) : message.pointY;
            if (message.animate != null && message.hasOwnProperty("animate"))
                object.animate = message.animate;
            return object;
        };

        /**
         * Converts this ZoomLevelData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.ZoomLevelData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ZoomLevelData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ZoomLevelData
         * @function getTypeUrl
         * @memberof MapCloudProto.ZoomLevelData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZoomLevelData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.ZoomLevelData";
        };

        return ZoomLevelData;
    })();

    MapCloudProto.PitchAngleData = (function() {

        /**
         * Properties of a PitchAngleData.
         * @memberof MapCloudProto
         * @interface IPitchAngleData
         * @property {number|null} [angle] PitchAngleData angle
         * @property {number|null} [increment] PitchAngleData increment
         */

        /**
         * Constructs a new PitchAngleData.
         * @memberof MapCloudProto
         * @classdesc Represents a PitchAngleData.
         * @implements IPitchAngleData
         * @constructor
         * @param {MapCloudProto.IPitchAngleData=} [properties] Properties to set
         */
        function PitchAngleData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PitchAngleData angle.
         * @member {number} angle
         * @memberof MapCloudProto.PitchAngleData
         * @instance
         */
        PitchAngleData.prototype.angle = 0;

        /**
         * PitchAngleData increment.
         * @member {number} increment
         * @memberof MapCloudProto.PitchAngleData
         * @instance
         */
        PitchAngleData.prototype.increment = 0;

        /**
         * Creates a new PitchAngleData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {MapCloudProto.IPitchAngleData=} [properties] Properties to set
         * @returns {MapCloudProto.PitchAngleData} PitchAngleData instance
         */
        PitchAngleData.create = function create(properties) {
            return new PitchAngleData(properties);
        };

        /**
         * Encodes the specified PitchAngleData message. Does not implicitly {@link MapCloudProto.PitchAngleData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {MapCloudProto.IPitchAngleData} message PitchAngleData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PitchAngleData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.angle != null && Object.hasOwnProperty.call(message, "angle"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.angle);
            if (message.increment != null && Object.hasOwnProperty.call(message, "increment"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.increment);
            return writer;
        };

        /**
         * Encodes the specified PitchAngleData message, length delimited. Does not implicitly {@link MapCloudProto.PitchAngleData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {MapCloudProto.IPitchAngleData} message PitchAngleData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PitchAngleData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PitchAngleData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.PitchAngleData} PitchAngleData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PitchAngleData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.PitchAngleData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.angle = reader.float();
                        break;
                    }
                case 2: {
                        message.increment = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PitchAngleData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.PitchAngleData} PitchAngleData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PitchAngleData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PitchAngleData message.
         * @function verify
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PitchAngleData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.angle != null && message.hasOwnProperty("angle"))
                if (typeof message.angle !== "number")
                    return "angle: number expected";
            if (message.increment != null && message.hasOwnProperty("increment"))
                if (typeof message.increment !== "number")
                    return "increment: number expected";
            return null;
        };

        /**
         * Creates a PitchAngleData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.PitchAngleData} PitchAngleData
         */
        PitchAngleData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.PitchAngleData)
                return object;
            let message = new $root.MapCloudProto.PitchAngleData();
            if (object.angle != null)
                message.angle = Number(object.angle);
            if (object.increment != null)
                message.increment = Number(object.increment);
            return message;
        };

        /**
         * Creates a plain object from a PitchAngleData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {MapCloudProto.PitchAngleData} message PitchAngleData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PitchAngleData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.angle = 0;
                object.increment = 0;
            }
            if (message.angle != null && message.hasOwnProperty("angle"))
                object.angle = options.json && !isFinite(message.angle) ? String(message.angle) : message.angle;
            if (message.increment != null && message.hasOwnProperty("increment"))
                object.increment = options.json && !isFinite(message.increment) ? String(message.increment) : message.increment;
            return object;
        };

        /**
         * Converts this PitchAngleData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.PitchAngleData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PitchAngleData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PitchAngleData
         * @function getTypeUrl
         * @memberof MapCloudProto.PitchAngleData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PitchAngleData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.PitchAngleData";
        };

        return PitchAngleData;
    })();

    MapCloudProto.RollAngleData = (function() {

        /**
         * Properties of a RollAngleData.
         * @memberof MapCloudProto
         * @interface IRollAngleData
         * @property {number|null} [angle] RollAngleData angle
         * @property {number|null} [increment] RollAngleData increment
         */

        /**
         * Constructs a new RollAngleData.
         * @memberof MapCloudProto
         * @classdesc Represents a RollAngleData.
         * @implements IRollAngleData
         * @constructor
         * @param {MapCloudProto.IRollAngleData=} [properties] Properties to set
         */
        function RollAngleData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RollAngleData angle.
         * @member {number} angle
         * @memberof MapCloudProto.RollAngleData
         * @instance
         */
        RollAngleData.prototype.angle = 0;

        /**
         * RollAngleData increment.
         * @member {number} increment
         * @memberof MapCloudProto.RollAngleData
         * @instance
         */
        RollAngleData.prototype.increment = 0;

        /**
         * Creates a new RollAngleData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {MapCloudProto.IRollAngleData=} [properties] Properties to set
         * @returns {MapCloudProto.RollAngleData} RollAngleData instance
         */
        RollAngleData.create = function create(properties) {
            return new RollAngleData(properties);
        };

        /**
         * Encodes the specified RollAngleData message. Does not implicitly {@link MapCloudProto.RollAngleData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {MapCloudProto.IRollAngleData} message RollAngleData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RollAngleData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.angle != null && Object.hasOwnProperty.call(message, "angle"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.angle);
            if (message.increment != null && Object.hasOwnProperty.call(message, "increment"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.increment);
            return writer;
        };

        /**
         * Encodes the specified RollAngleData message, length delimited. Does not implicitly {@link MapCloudProto.RollAngleData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {MapCloudProto.IRollAngleData} message RollAngleData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RollAngleData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RollAngleData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.RollAngleData} RollAngleData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RollAngleData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.RollAngleData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.angle = reader.float();
                        break;
                    }
                case 2: {
                        message.increment = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RollAngleData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.RollAngleData} RollAngleData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RollAngleData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RollAngleData message.
         * @function verify
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RollAngleData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.angle != null && message.hasOwnProperty("angle"))
                if (typeof message.angle !== "number")
                    return "angle: number expected";
            if (message.increment != null && message.hasOwnProperty("increment"))
                if (typeof message.increment !== "number")
                    return "increment: number expected";
            return null;
        };

        /**
         * Creates a RollAngleData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.RollAngleData} RollAngleData
         */
        RollAngleData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.RollAngleData)
                return object;
            let message = new $root.MapCloudProto.RollAngleData();
            if (object.angle != null)
                message.angle = Number(object.angle);
            if (object.increment != null)
                message.increment = Number(object.increment);
            return message;
        };

        /**
         * Creates a plain object from a RollAngleData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {MapCloudProto.RollAngleData} message RollAngleData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RollAngleData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.angle = 0;
                object.increment = 0;
            }
            if (message.angle != null && message.hasOwnProperty("angle"))
                object.angle = options.json && !isFinite(message.angle) ? String(message.angle) : message.angle;
            if (message.increment != null && message.hasOwnProperty("increment"))
                object.increment = options.json && !isFinite(message.increment) ? String(message.increment) : message.increment;
            return object;
        };

        /**
         * Converts this RollAngleData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.RollAngleData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RollAngleData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RollAngleData
         * @function getTypeUrl
         * @memberof MapCloudProto.RollAngleData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RollAngleData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.RollAngleData";
        };

        return RollAngleData;
    })();

    MapCloudProto.SetMapModeStateTime = (function() {

        /**
         * Properties of a SetMapModeStateTime.
         * @memberof MapCloudProto
         * @interface ISetMapModeStateTime
         * @property {number} mapMode SetMapModeStateTime mapMode
         * @property {number} mapState SetMapModeStateTime mapState
         * @property {number} mapTime SetMapModeStateTime mapTime
         */

        /**
         * Constructs a new SetMapModeStateTime.
         * @memberof MapCloudProto
         * @classdesc Represents a SetMapModeStateTime.
         * @implements ISetMapModeStateTime
         * @constructor
         * @param {MapCloudProto.ISetMapModeStateTime=} [properties] Properties to set
         */
        function SetMapModeStateTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMapModeStateTime mapMode.
         * @member {number} mapMode
         * @memberof MapCloudProto.SetMapModeStateTime
         * @instance
         */
        SetMapModeStateTime.prototype.mapMode = 0;

        /**
         * SetMapModeStateTime mapState.
         * @member {number} mapState
         * @memberof MapCloudProto.SetMapModeStateTime
         * @instance
         */
        SetMapModeStateTime.prototype.mapState = 0;

        /**
         * SetMapModeStateTime mapTime.
         * @member {number} mapTime
         * @memberof MapCloudProto.SetMapModeStateTime
         * @instance
         */
        SetMapModeStateTime.prototype.mapTime = 0;

        /**
         * Creates a new SetMapModeStateTime instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {MapCloudProto.ISetMapModeStateTime=} [properties] Properties to set
         * @returns {MapCloudProto.SetMapModeStateTime} SetMapModeStateTime instance
         */
        SetMapModeStateTime.create = function create(properties) {
            return new SetMapModeStateTime(properties);
        };

        /**
         * Encodes the specified SetMapModeStateTime message. Does not implicitly {@link MapCloudProto.SetMapModeStateTime.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {MapCloudProto.ISetMapModeStateTime} message SetMapModeStateTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapModeStateTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.mapMode);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.mapState);
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.mapTime);
            return writer;
        };

        /**
         * Encodes the specified SetMapModeStateTime message, length delimited. Does not implicitly {@link MapCloudProto.SetMapModeStateTime.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {MapCloudProto.ISetMapModeStateTime} message SetMapModeStateTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMapModeStateTime.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMapModeStateTime message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.SetMapModeStateTime} SetMapModeStateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapModeStateTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.SetMapModeStateTime();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mapMode = reader.int32();
                        break;
                    }
                case 2: {
                        message.mapState = reader.int32();
                        break;
                    }
                case 3: {
                        message.mapTime = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("mapMode"))
                throw $util.ProtocolError("missing required 'mapMode'", { instance: message });
            if (!message.hasOwnProperty("mapState"))
                throw $util.ProtocolError("missing required 'mapState'", { instance: message });
            if (!message.hasOwnProperty("mapTime"))
                throw $util.ProtocolError("missing required 'mapTime'", { instance: message });
            return message;
        };

        /**
         * Decodes a SetMapModeStateTime message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.SetMapModeStateTime} SetMapModeStateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMapModeStateTime.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMapModeStateTime message.
         * @function verify
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMapModeStateTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.mapMode))
                return "mapMode: integer expected";
            if (!$util.isInteger(message.mapState))
                return "mapState: integer expected";
            if (!$util.isInteger(message.mapTime))
                return "mapTime: integer expected";
            return null;
        };

        /**
         * Creates a SetMapModeStateTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.SetMapModeStateTime} SetMapModeStateTime
         */
        SetMapModeStateTime.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.SetMapModeStateTime)
                return object;
            let message = new $root.MapCloudProto.SetMapModeStateTime();
            if (object.mapMode != null)
                message.mapMode = object.mapMode | 0;
            if (object.mapState != null)
                message.mapState = object.mapState | 0;
            if (object.mapTime != null)
                message.mapTime = object.mapTime | 0;
            return message;
        };

        /**
         * Creates a plain object from a SetMapModeStateTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {MapCloudProto.SetMapModeStateTime} message SetMapModeStateTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMapModeStateTime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.mapMode = 0;
                object.mapState = 0;
                object.mapTime = 0;
            }
            if (message.mapMode != null && message.hasOwnProperty("mapMode"))
                object.mapMode = message.mapMode;
            if (message.mapState != null && message.hasOwnProperty("mapState"))
                object.mapState = message.mapState;
            if (message.mapTime != null && message.hasOwnProperty("mapTime"))
                object.mapTime = message.mapTime;
            return object;
        };

        /**
         * Converts this SetMapModeStateTime to JSON.
         * @function toJSON
         * @memberof MapCloudProto.SetMapModeStateTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMapModeStateTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetMapModeStateTime
         * @function getTypeUrl
         * @memberof MapCloudProto.SetMapModeStateTime
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetMapModeStateTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.SetMapModeStateTime";
        };

        return SetMapModeStateTime;
    })();

    MapCloudProto.OssKeyData = (function() {

        /**
         * Properties of an OssKeyData.
         * @memberof MapCloudProto
         * @interface IOssKeyData
         * @property {number} flag OssKeyData flag
         * @property {string} accessKeyID OssKeyData accessKeyID
         * @property {string} accessKeySecret OssKeyData accessKeySecret
         * @property {string} endpoint OssKeyData endpoint
         * @property {string|null} [stsToken] OssKeyData stsToken
         * @property {string} bucketName OssKeyData bucketName
         * @property {number|Long|null} [expiration] OssKeyData expiration
         */

        /**
         * Constructs a new OssKeyData.
         * @memberof MapCloudProto
         * @classdesc Represents an OssKeyData.
         * @implements IOssKeyData
         * @constructor
         * @param {MapCloudProto.IOssKeyData=} [properties] Properties to set
         */
        function OssKeyData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OssKeyData flag.
         * @member {number} flag
         * @memberof MapCloudProto.OssKeyData
         * @instance
         */
        OssKeyData.prototype.flag = 0;

        /**
         * OssKeyData accessKeyID.
         * @member {string} accessKeyID
         * @memberof MapCloudProto.OssKeyData
         * @instance
         */
        OssKeyData.prototype.accessKeyID = "";

        /**
         * OssKeyData accessKeySecret.
         * @member {string} accessKeySecret
         * @memberof MapCloudProto.OssKeyData
         * @instance
         */
        OssKeyData.prototype.accessKeySecret = "";

        /**
         * OssKeyData endpoint.
         * @member {string} endpoint
         * @memberof MapCloudProto.OssKeyData
         * @instance
         */
        OssKeyData.prototype.endpoint = "";

        /**
         * OssKeyData stsToken.
         * @member {string} stsToken
         * @memberof MapCloudProto.OssKeyData
         * @instance
         */
        OssKeyData.prototype.stsToken = "";

        /**
         * OssKeyData bucketName.
         * @member {string} bucketName
         * @memberof MapCloudProto.OssKeyData
         * @instance
         */
        OssKeyData.prototype.bucketName = "";

        /**
         * OssKeyData expiration.
         * @member {number|Long} expiration
         * @memberof MapCloudProto.OssKeyData
         * @instance
         */
        OssKeyData.prototype.expiration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new OssKeyData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {MapCloudProto.IOssKeyData=} [properties] Properties to set
         * @returns {MapCloudProto.OssKeyData} OssKeyData instance
         */
        OssKeyData.create = function create(properties) {
            return new OssKeyData(properties);
        };

        /**
         * Encodes the specified OssKeyData message. Does not implicitly {@link MapCloudProto.OssKeyData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {MapCloudProto.IOssKeyData} message OssKeyData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OssKeyData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.flag);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.accessKeyID);
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.accessKeySecret);
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.endpoint);
            if (message.stsToken != null && Object.hasOwnProperty.call(message, "stsToken"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.stsToken);
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.bucketName);
            if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.expiration);
            return writer;
        };

        /**
         * Encodes the specified OssKeyData message, length delimited. Does not implicitly {@link MapCloudProto.OssKeyData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {MapCloudProto.IOssKeyData} message OssKeyData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OssKeyData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OssKeyData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.OssKeyData} OssKeyData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OssKeyData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.OssKeyData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.flag = reader.int32();
                        break;
                    }
                case 2: {
                        message.accessKeyID = reader.string();
                        break;
                    }
                case 3: {
                        message.accessKeySecret = reader.string();
                        break;
                    }
                case 4: {
                        message.endpoint = reader.string();
                        break;
                    }
                case 5: {
                        message.stsToken = reader.string();
                        break;
                    }
                case 6: {
                        message.bucketName = reader.string();
                        break;
                    }
                case 7: {
                        message.expiration = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("flag"))
                throw $util.ProtocolError("missing required 'flag'", { instance: message });
            if (!message.hasOwnProperty("accessKeyID"))
                throw $util.ProtocolError("missing required 'accessKeyID'", { instance: message });
            if (!message.hasOwnProperty("accessKeySecret"))
                throw $util.ProtocolError("missing required 'accessKeySecret'", { instance: message });
            if (!message.hasOwnProperty("endpoint"))
                throw $util.ProtocolError("missing required 'endpoint'", { instance: message });
            if (!message.hasOwnProperty("bucketName"))
                throw $util.ProtocolError("missing required 'bucketName'", { instance: message });
            return message;
        };

        /**
         * Decodes an OssKeyData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.OssKeyData} OssKeyData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OssKeyData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OssKeyData message.
         * @function verify
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OssKeyData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.flag))
                return "flag: integer expected";
            if (!$util.isString(message.accessKeyID))
                return "accessKeyID: string expected";
            if (!$util.isString(message.accessKeySecret))
                return "accessKeySecret: string expected";
            if (!$util.isString(message.endpoint))
                return "endpoint: string expected";
            if (message.stsToken != null && message.hasOwnProperty("stsToken"))
                if (!$util.isString(message.stsToken))
                    return "stsToken: string expected";
            if (!$util.isString(message.bucketName))
                return "bucketName: string expected";
            if (message.expiration != null && message.hasOwnProperty("expiration"))
                if (!$util.isInteger(message.expiration) && !(message.expiration && $util.isInteger(message.expiration.low) && $util.isInteger(message.expiration.high)))
                    return "expiration: integer|Long expected";
            return null;
        };

        /**
         * Creates an OssKeyData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.OssKeyData} OssKeyData
         */
        OssKeyData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.OssKeyData)
                return object;
            let message = new $root.MapCloudProto.OssKeyData();
            if (object.flag != null)
                message.flag = object.flag | 0;
            if (object.accessKeyID != null)
                message.accessKeyID = String(object.accessKeyID);
            if (object.accessKeySecret != null)
                message.accessKeySecret = String(object.accessKeySecret);
            if (object.endpoint != null)
                message.endpoint = String(object.endpoint);
            if (object.stsToken != null)
                message.stsToken = String(object.stsToken);
            if (object.bucketName != null)
                message.bucketName = String(object.bucketName);
            if (object.expiration != null)
                if ($util.Long)
                    (message.expiration = $util.Long.fromValue(object.expiration)).unsigned = false;
                else if (typeof object.expiration === "string")
                    message.expiration = parseInt(object.expiration, 10);
                else if (typeof object.expiration === "number")
                    message.expiration = object.expiration;
                else if (typeof object.expiration === "object")
                    message.expiration = new $util.LongBits(object.expiration.low >>> 0, object.expiration.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an OssKeyData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {MapCloudProto.OssKeyData} message OssKeyData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OssKeyData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.flag = 0;
                object.accessKeyID = "";
                object.accessKeySecret = "";
                object.endpoint = "";
                object.stsToken = "";
                object.bucketName = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expiration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiration = options.longs === String ? "0" : 0;
            }
            if (message.flag != null && message.hasOwnProperty("flag"))
                object.flag = message.flag;
            if (message.accessKeyID != null && message.hasOwnProperty("accessKeyID"))
                object.accessKeyID = message.accessKeyID;
            if (message.accessKeySecret != null && message.hasOwnProperty("accessKeySecret"))
                object.accessKeySecret = message.accessKeySecret;
            if (message.endpoint != null && message.hasOwnProperty("endpoint"))
                object.endpoint = message.endpoint;
            if (message.stsToken != null && message.hasOwnProperty("stsToken"))
                object.stsToken = message.stsToken;
            if (message.bucketName != null && message.hasOwnProperty("bucketName"))
                object.bucketName = message.bucketName;
            if (message.expiration != null && message.hasOwnProperty("expiration"))
                if (typeof message.expiration === "number")
                    object.expiration = options.longs === String ? String(message.expiration) : message.expiration;
                else
                    object.expiration = options.longs === String ? $util.Long.prototype.toString.call(message.expiration) : options.longs === Number ? new $util.LongBits(message.expiration.low >>> 0, message.expiration.high >>> 0).toNumber() : message.expiration;
            return object;
        };

        /**
         * Converts this OssKeyData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.OssKeyData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OssKeyData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OssKeyData
         * @function getTypeUrl
         * @memberof MapCloudProto.OssKeyData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OssKeyData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.OssKeyData";
        };

        return OssKeyData;
    })();

    MapCloudProto.ExecResult = (function() {

        /**
         * Properties of an ExecResult.
         * @memberof MapCloudProto
         * @interface IExecResult
         * @property {number} code ExecResult code
         * @property {string} flag ExecResult flag
         * @property {string|null} [result] ExecResult result
         * @property {string|null} [message] ExecResult message
         */

        /**
         * Constructs a new ExecResult.
         * @memberof MapCloudProto
         * @classdesc Represents an ExecResult.
         * @implements IExecResult
         * @constructor
         * @param {MapCloudProto.IExecResult=} [properties] Properties to set
         */
        function ExecResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecResult code.
         * @member {number} code
         * @memberof MapCloudProto.ExecResult
         * @instance
         */
        ExecResult.prototype.code = 0;

        /**
         * ExecResult flag.
         * @member {string} flag
         * @memberof MapCloudProto.ExecResult
         * @instance
         */
        ExecResult.prototype.flag = "";

        /**
         * ExecResult result.
         * @member {string} result
         * @memberof MapCloudProto.ExecResult
         * @instance
         */
        ExecResult.prototype.result = "";

        /**
         * ExecResult message.
         * @member {string} message
         * @memberof MapCloudProto.ExecResult
         * @instance
         */
        ExecResult.prototype.message = "";

        /**
         * Creates a new ExecResult instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {MapCloudProto.IExecResult=} [properties] Properties to set
         * @returns {MapCloudProto.ExecResult} ExecResult instance
         */
        ExecResult.create = function create(properties) {
            return new ExecResult(properties);
        };

        /**
         * Encodes the specified ExecResult message. Does not implicitly {@link MapCloudProto.ExecResult.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {MapCloudProto.IExecResult} message ExecResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.flag);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.result);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified ExecResult message, length delimited. Does not implicitly {@link MapCloudProto.ExecResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {MapCloudProto.IExecResult} message ExecResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExecResult message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.ExecResult} ExecResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.ExecResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.flag = reader.string();
                        break;
                    }
                case 3: {
                        message.result = reader.string();
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("code"))
                throw $util.ProtocolError("missing required 'code'", { instance: message });
            if (!message.hasOwnProperty("flag"))
                throw $util.ProtocolError("missing required 'flag'", { instance: message });
            return message;
        };

        /**
         * Decodes an ExecResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.ExecResult} ExecResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExecResult message.
         * @function verify
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExecResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.code))
                return "code: integer expected";
            if (!$util.isString(message.flag))
                return "flag: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isString(message.result))
                    return "result: string expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates an ExecResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.ExecResult} ExecResult
         */
        ExecResult.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.ExecResult)
                return object;
            let message = new $root.MapCloudProto.ExecResult();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.flag != null)
                message.flag = String(object.flag);
            if (object.result != null)
                message.result = String(object.result);
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from an ExecResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {MapCloudProto.ExecResult} message ExecResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExecResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.flag = "";
                object.result = "";
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.flag != null && message.hasOwnProperty("flag"))
                object.flag = message.flag;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this ExecResult to JSON.
         * @function toJSON
         * @memberof MapCloudProto.ExecResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExecResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExecResult
         * @function getTypeUrl
         * @memberof MapCloudProto.ExecResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExecResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.ExecResult";
        };

        return ExecResult;
    })();

    MapCloudProto.OssReqData = (function() {

        /**
         * Properties of an OssReqData.
         * @memberof MapCloudProto
         * @interface IOssReqData
         * @property {number} flag OssReqData flag
         */

        /**
         * Constructs a new OssReqData.
         * @memberof MapCloudProto
         * @classdesc Represents an OssReqData.
         * @implements IOssReqData
         * @constructor
         * @param {MapCloudProto.IOssReqData=} [properties] Properties to set
         */
        function OssReqData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OssReqData flag.
         * @member {number} flag
         * @memberof MapCloudProto.OssReqData
         * @instance
         */
        OssReqData.prototype.flag = 0;

        /**
         * Creates a new OssReqData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {MapCloudProto.IOssReqData=} [properties] Properties to set
         * @returns {MapCloudProto.OssReqData} OssReqData instance
         */
        OssReqData.create = function create(properties) {
            return new OssReqData(properties);
        };

        /**
         * Encodes the specified OssReqData message. Does not implicitly {@link MapCloudProto.OssReqData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {MapCloudProto.IOssReqData} message OssReqData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OssReqData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.flag);
            return writer;
        };

        /**
         * Encodes the specified OssReqData message, length delimited. Does not implicitly {@link MapCloudProto.OssReqData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {MapCloudProto.IOssReqData} message OssReqData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OssReqData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OssReqData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.OssReqData} OssReqData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OssReqData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.OssReqData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.flag = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("flag"))
                throw $util.ProtocolError("missing required 'flag'", { instance: message });
            return message;
        };

        /**
         * Decodes an OssReqData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.OssReqData} OssReqData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OssReqData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OssReqData message.
         * @function verify
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OssReqData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isInteger(message.flag))
                return "flag: integer expected";
            return null;
        };

        /**
         * Creates an OssReqData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.OssReqData} OssReqData
         */
        OssReqData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.OssReqData)
                return object;
            let message = new $root.MapCloudProto.OssReqData();
            if (object.flag != null)
                message.flag = object.flag | 0;
            return message;
        };

        /**
         * Creates a plain object from an OssReqData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {MapCloudProto.OssReqData} message OssReqData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OssReqData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.flag = 0;
            if (message.flag != null && message.hasOwnProperty("flag"))
                object.flag = message.flag;
            return object;
        };

        /**
         * Converts this OssReqData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.OssReqData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OssReqData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OssReqData
         * @function getTypeUrl
         * @memberof MapCloudProto.OssReqData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OssReqData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.OssReqData";
        };

        return OssReqData;
    })();

    MapCloudProto.ImmersiveRecordResponse = (function() {

        /**
         * Properties of an ImmersiveRecordResponse.
         * @memberof MapCloudProto
         * @interface IImmersiveRecordResponse
         * @property {MapCloudProto.ImmersiveRecordStatus} status ImmersiveRecordResponse status
         * @property {string} poiId ImmersiveRecordResponse poiId
         * @property {number} percent ImmersiveRecordResponse percent
         * @property {string|null} [ossPath] ImmersiveRecordResponse ossPath
         * @property {string|null} [message] ImmersiveRecordResponse message
         */

        /**
         * Constructs a new ImmersiveRecordResponse.
         * @memberof MapCloudProto
         * @classdesc Represents an ImmersiveRecordResponse.
         * @implements IImmersiveRecordResponse
         * @constructor
         * @param {MapCloudProto.IImmersiveRecordResponse=} [properties] Properties to set
         */
        function ImmersiveRecordResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImmersiveRecordResponse status.
         * @member {MapCloudProto.ImmersiveRecordStatus} status
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @instance
         */
        ImmersiveRecordResponse.prototype.status = 0;

        /**
         * ImmersiveRecordResponse poiId.
         * @member {string} poiId
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @instance
         */
        ImmersiveRecordResponse.prototype.poiId = "";

        /**
         * ImmersiveRecordResponse percent.
         * @member {number} percent
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @instance
         */
        ImmersiveRecordResponse.prototype.percent = 0;

        /**
         * ImmersiveRecordResponse ossPath.
         * @member {string} ossPath
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @instance
         */
        ImmersiveRecordResponse.prototype.ossPath = "";

        /**
         * ImmersiveRecordResponse message.
         * @member {string} message
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @instance
         */
        ImmersiveRecordResponse.prototype.message = "";

        /**
         * Creates a new ImmersiveRecordResponse instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {MapCloudProto.IImmersiveRecordResponse=} [properties] Properties to set
         * @returns {MapCloudProto.ImmersiveRecordResponse} ImmersiveRecordResponse instance
         */
        ImmersiveRecordResponse.create = function create(properties) {
            return new ImmersiveRecordResponse(properties);
        };

        /**
         * Encodes the specified ImmersiveRecordResponse message. Does not implicitly {@link MapCloudProto.ImmersiveRecordResponse.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {MapCloudProto.IImmersiveRecordResponse} message ImmersiveRecordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImmersiveRecordResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.poiId);
            writer.uint32(/* id 3, wireType 5 =*/29).float(message.percent);
            if (message.ossPath != null && Object.hasOwnProperty.call(message, "ossPath"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.ossPath);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified ImmersiveRecordResponse message, length delimited. Does not implicitly {@link MapCloudProto.ImmersiveRecordResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {MapCloudProto.IImmersiveRecordResponse} message ImmersiveRecordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImmersiveRecordResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImmersiveRecordResponse message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.ImmersiveRecordResponse} ImmersiveRecordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImmersiveRecordResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.ImmersiveRecordResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.poiId = reader.string();
                        break;
                    }
                case 3: {
                        message.percent = reader.float();
                        break;
                    }
                case 4: {
                        message.ossPath = reader.string();
                        break;
                    }
                case 5: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("status"))
                throw $util.ProtocolError("missing required 'status'", { instance: message });
            if (!message.hasOwnProperty("poiId"))
                throw $util.ProtocolError("missing required 'poiId'", { instance: message });
            if (!message.hasOwnProperty("percent"))
                throw $util.ProtocolError("missing required 'percent'", { instance: message });
            return message;
        };

        /**
         * Decodes an ImmersiveRecordResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.ImmersiveRecordResponse} ImmersiveRecordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImmersiveRecordResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImmersiveRecordResponse message.
         * @function verify
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImmersiveRecordResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.status) {
            default:
                return "status: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
            if (!$util.isString(message.poiId))
                return "poiId: string expected";
            if (typeof message.percent !== "number")
                return "percent: number expected";
            if (message.ossPath != null && message.hasOwnProperty("ossPath"))
                if (!$util.isString(message.ossPath))
                    return "ossPath: string expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates an ImmersiveRecordResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.ImmersiveRecordResponse} ImmersiveRecordResponse
         */
        ImmersiveRecordResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.ImmersiveRecordResponse)
                return object;
            let message = new $root.MapCloudProto.ImmersiveRecordResponse();
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "Begin":
            case 0:
                message.status = 0;
                break;
            case "Doing":
            case 1:
                message.status = 1;
                break;
            case "End":
            case 2:
                message.status = 2;
                break;
            case "Error":
            case 3:
                message.status = 3;
                break;
            }
            if (object.poiId != null)
                message.poiId = String(object.poiId);
            if (object.percent != null)
                message.percent = Number(object.percent);
            if (object.ossPath != null)
                message.ossPath = String(object.ossPath);
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from an ImmersiveRecordResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {MapCloudProto.ImmersiveRecordResponse} message ImmersiveRecordResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImmersiveRecordResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "Begin" : 0;
                object.poiId = "";
                object.percent = 0;
                object.ossPath = "";
                object.message = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.MapCloudProto.ImmersiveRecordStatus[message.status] === undefined ? message.status : $root.MapCloudProto.ImmersiveRecordStatus[message.status] : message.status;
            if (message.poiId != null && message.hasOwnProperty("poiId"))
                object.poiId = message.poiId;
            if (message.percent != null && message.hasOwnProperty("percent"))
                object.percent = options.json && !isFinite(message.percent) ? String(message.percent) : message.percent;
            if (message.ossPath != null && message.hasOwnProperty("ossPath"))
                object.ossPath = message.ossPath;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this ImmersiveRecordResponse to JSON.
         * @function toJSON
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImmersiveRecordResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImmersiveRecordResponse
         * @function getTypeUrl
         * @memberof MapCloudProto.ImmersiveRecordResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImmersiveRecordResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.ImmersiveRecordResponse";
        };

        return ImmersiveRecordResponse;
    })();

    MapCloudProto.VideoData = (function() {

        /**
         * Properties of a VideoData.
         * @memberof MapCloudProto
         * @interface IVideoData
         * @property {Uint8Array} packet VideoData packet
         * @property {boolean} keyFrame VideoData keyFrame
         * @property {number|null} [packetIndex] VideoData packetIndex
         * @property {number|null} [timestamp] VideoData timestamp
         * @property {number|null} [chunkCount] VideoData chunkCount
         * @property {number|null} [chunkIndex] VideoData chunkIndex
         */

        /**
         * Constructs a new VideoData.
         * @memberof MapCloudProto
         * @classdesc Represents a VideoData.
         * @implements IVideoData
         * @constructor
         * @param {MapCloudProto.IVideoData=} [properties] Properties to set
         */
        function VideoData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VideoData packet.
         * @member {Uint8Array} packet
         * @memberof MapCloudProto.VideoData
         * @instance
         */
        VideoData.prototype.packet = $util.newBuffer([]);

        /**
         * VideoData keyFrame.
         * @member {boolean} keyFrame
         * @memberof MapCloudProto.VideoData
         * @instance
         */
        VideoData.prototype.keyFrame = false;

        /**
         * VideoData packetIndex.
         * @member {number} packetIndex
         * @memberof MapCloudProto.VideoData
         * @instance
         */
        VideoData.prototype.packetIndex = 0;

        /**
         * VideoData timestamp.
         * @member {number} timestamp
         * @memberof MapCloudProto.VideoData
         * @instance
         */
        VideoData.prototype.timestamp = 0;

        /**
         * VideoData chunkCount.
         * @member {number} chunkCount
         * @memberof MapCloudProto.VideoData
         * @instance
         */
        VideoData.prototype.chunkCount = 0;

        /**
         * VideoData chunkIndex.
         * @member {number} chunkIndex
         * @memberof MapCloudProto.VideoData
         * @instance
         */
        VideoData.prototype.chunkIndex = 0;

        /**
         * Creates a new VideoData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {MapCloudProto.IVideoData=} [properties] Properties to set
         * @returns {MapCloudProto.VideoData} VideoData instance
         */
        VideoData.create = function create(properties) {
            return new VideoData(properties);
        };

        /**
         * Encodes the specified VideoData message. Does not implicitly {@link MapCloudProto.VideoData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {MapCloudProto.IVideoData} message VideoData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideoData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.packet);
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.keyFrame);
            if (message.packetIndex != null && Object.hasOwnProperty.call(message, "packetIndex"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.packetIndex);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.timestamp);
            if (message.chunkCount != null && Object.hasOwnProperty.call(message, "chunkCount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.chunkCount);
            if (message.chunkIndex != null && Object.hasOwnProperty.call(message, "chunkIndex"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.chunkIndex);
            return writer;
        };

        /**
         * Encodes the specified VideoData message, length delimited. Does not implicitly {@link MapCloudProto.VideoData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {MapCloudProto.IVideoData} message VideoData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideoData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VideoData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.VideoData} VideoData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideoData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.VideoData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.packet = reader.bytes();
                        break;
                    }
                case 2: {
                        message.keyFrame = reader.bool();
                        break;
                    }
                case 3: {
                        message.packetIndex = reader.int32();
                        break;
                    }
                case 4: {
                        message.timestamp = reader.int32();
                        break;
                    }
                case 5: {
                        message.chunkCount = reader.int32();
                        break;
                    }
                case 6: {
                        message.chunkIndex = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("packet"))
                throw $util.ProtocolError("missing required 'packet'", { instance: message });
            if (!message.hasOwnProperty("keyFrame"))
                throw $util.ProtocolError("missing required 'keyFrame'", { instance: message });
            return message;
        };

        /**
         * Decodes a VideoData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.VideoData} VideoData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideoData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VideoData message.
         * @function verify
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VideoData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!(message.packet && typeof message.packet.length === "number" || $util.isString(message.packet)))
                return "packet: buffer expected";
            if (typeof message.keyFrame !== "boolean")
                return "keyFrame: boolean expected";
            if (message.packetIndex != null && message.hasOwnProperty("packetIndex"))
                if (!$util.isInteger(message.packetIndex))
                    return "packetIndex: integer expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp))
                    return "timestamp: integer expected";
            if (message.chunkCount != null && message.hasOwnProperty("chunkCount"))
                if (!$util.isInteger(message.chunkCount))
                    return "chunkCount: integer expected";
            if (message.chunkIndex != null && message.hasOwnProperty("chunkIndex"))
                if (!$util.isInteger(message.chunkIndex))
                    return "chunkIndex: integer expected";
            return null;
        };

        /**
         * Creates a VideoData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.VideoData} VideoData
         */
        VideoData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.VideoData)
                return object;
            let message = new $root.MapCloudProto.VideoData();
            if (object.packet != null)
                if (typeof object.packet === "string")
                    $util.base64.decode(object.packet, message.packet = $util.newBuffer($util.base64.length(object.packet)), 0);
                else if (object.packet.length >= 0)
                    message.packet = object.packet;
            if (object.keyFrame != null)
                message.keyFrame = Boolean(object.keyFrame);
            if (object.packetIndex != null)
                message.packetIndex = object.packetIndex | 0;
            if (object.timestamp != null)
                message.timestamp = object.timestamp | 0;
            if (object.chunkCount != null)
                message.chunkCount = object.chunkCount | 0;
            if (object.chunkIndex != null)
                message.chunkIndex = object.chunkIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from a VideoData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {MapCloudProto.VideoData} message VideoData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VideoData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.packet = "";
                else {
                    object.packet = [];
                    if (options.bytes !== Array)
                        object.packet = $util.newBuffer(object.packet);
                }
                object.keyFrame = false;
                object.packetIndex = 0;
                object.timestamp = 0;
                object.chunkCount = 0;
                object.chunkIndex = 0;
            }
            if (message.packet != null && message.hasOwnProperty("packet"))
                object.packet = options.bytes === String ? $util.base64.encode(message.packet, 0, message.packet.length) : options.bytes === Array ? Array.prototype.slice.call(message.packet) : message.packet;
            if (message.keyFrame != null && message.hasOwnProperty("keyFrame"))
                object.keyFrame = message.keyFrame;
            if (message.packetIndex != null && message.hasOwnProperty("packetIndex"))
                object.packetIndex = message.packetIndex;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                object.timestamp = message.timestamp;
            if (message.chunkCount != null && message.hasOwnProperty("chunkCount"))
                object.chunkCount = message.chunkCount;
            if (message.chunkIndex != null && message.hasOwnProperty("chunkIndex"))
                object.chunkIndex = message.chunkIndex;
            return object;
        };

        /**
         * Converts this VideoData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.VideoData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VideoData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VideoData
         * @function getTypeUrl
         * @memberof MapCloudProto.VideoData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VideoData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.VideoData";
        };

        return VideoData;
    })();

    MapCloudProto.FocusData = (function() {

        /**
         * Properties of a FocusData.
         * @memberof MapCloudProto
         * @interface IFocusData
         * @property {boolean} focus FocusData focus
         */

        /**
         * Constructs a new FocusData.
         * @memberof MapCloudProto
         * @classdesc Represents a FocusData.
         * @implements IFocusData
         * @constructor
         * @param {MapCloudProto.IFocusData=} [properties] Properties to set
         */
        function FocusData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FocusData focus.
         * @member {boolean} focus
         * @memberof MapCloudProto.FocusData
         * @instance
         */
        FocusData.prototype.focus = false;

        /**
         * Creates a new FocusData instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {MapCloudProto.IFocusData=} [properties] Properties to set
         * @returns {MapCloudProto.FocusData} FocusData instance
         */
        FocusData.create = function create(properties) {
            return new FocusData(properties);
        };

        /**
         * Encodes the specified FocusData message. Does not implicitly {@link MapCloudProto.FocusData.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {MapCloudProto.IFocusData} message FocusData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FocusData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.focus);
            return writer;
        };

        /**
         * Encodes the specified FocusData message, length delimited. Does not implicitly {@link MapCloudProto.FocusData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {MapCloudProto.IFocusData} message FocusData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FocusData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FocusData message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.FocusData} FocusData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FocusData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.FocusData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.focus = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("focus"))
                throw $util.ProtocolError("missing required 'focus'", { instance: message });
            return message;
        };

        /**
         * Decodes a FocusData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.FocusData} FocusData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FocusData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FocusData message.
         * @function verify
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FocusData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (typeof message.focus !== "boolean")
                return "focus: boolean expected";
            return null;
        };

        /**
         * Creates a FocusData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.FocusData} FocusData
         */
        FocusData.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.FocusData)
                return object;
            let message = new $root.MapCloudProto.FocusData();
            if (object.focus != null)
                message.focus = Boolean(object.focus);
            return message;
        };

        /**
         * Creates a plain object from a FocusData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {MapCloudProto.FocusData} message FocusData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FocusData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.focus = false;
            if (message.focus != null && message.hasOwnProperty("focus"))
                object.focus = message.focus;
            return object;
        };

        /**
         * Converts this FocusData to JSON.
         * @function toJSON
         * @memberof MapCloudProto.FocusData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FocusData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FocusData
         * @function getTypeUrl
         * @memberof MapCloudProto.FocusData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FocusData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.FocusData";
        };

        return FocusData;
    })();

    MapCloudProto.TimerMapInfo = (function() {

        /**
         * Properties of a TimerMapInfo.
         * @memberof MapCloudProto
         * @interface ITimerMapInfo
         * @property {number|null} [zoomLevel] TimerMapInfo zoomLevel
         * @property {number|null} [pitchAngle] TimerMapInfo pitchAngle
         * @property {number|null} [rollAngle] TimerMapInfo rollAngle
         * @property {number|null} [lon] TimerMapInfo lon
         * @property {number|null} [lat] TimerMapInfo lat
         */

        /**
         * Constructs a new TimerMapInfo.
         * @memberof MapCloudProto
         * @classdesc Represents a TimerMapInfo.
         * @implements ITimerMapInfo
         * @constructor
         * @param {MapCloudProto.ITimerMapInfo=} [properties] Properties to set
         */
        function TimerMapInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimerMapInfo zoomLevel.
         * @member {number} zoomLevel
         * @memberof MapCloudProto.TimerMapInfo
         * @instance
         */
        TimerMapInfo.prototype.zoomLevel = 0;

        /**
         * TimerMapInfo pitchAngle.
         * @member {number} pitchAngle
         * @memberof MapCloudProto.TimerMapInfo
         * @instance
         */
        TimerMapInfo.prototype.pitchAngle = 0;

        /**
         * TimerMapInfo rollAngle.
         * @member {number} rollAngle
         * @memberof MapCloudProto.TimerMapInfo
         * @instance
         */
        TimerMapInfo.prototype.rollAngle = 0;

        /**
         * TimerMapInfo lon.
         * @member {number} lon
         * @memberof MapCloudProto.TimerMapInfo
         * @instance
         */
        TimerMapInfo.prototype.lon = 0;

        /**
         * TimerMapInfo lat.
         * @member {number} lat
         * @memberof MapCloudProto.TimerMapInfo
         * @instance
         */
        TimerMapInfo.prototype.lat = 0;

        /**
         * Creates a new TimerMapInfo instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {MapCloudProto.ITimerMapInfo=} [properties] Properties to set
         * @returns {MapCloudProto.TimerMapInfo} TimerMapInfo instance
         */
        TimerMapInfo.create = function create(properties) {
            return new TimerMapInfo(properties);
        };

        /**
         * Encodes the specified TimerMapInfo message. Does not implicitly {@link MapCloudProto.TimerMapInfo.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {MapCloudProto.ITimerMapInfo} message TimerMapInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimerMapInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.zoomLevel != null && Object.hasOwnProperty.call(message, "zoomLevel"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message.zoomLevel);
            if (message.pitchAngle != null && Object.hasOwnProperty.call(message, "pitchAngle"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.pitchAngle);
            if (message.rollAngle != null && Object.hasOwnProperty.call(message, "rollAngle"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.rollAngle);
            if (message.lon != null && Object.hasOwnProperty.call(message, "lon"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.lon);
            if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.lat);
            return writer;
        };

        /**
         * Encodes the specified TimerMapInfo message, length delimited. Does not implicitly {@link MapCloudProto.TimerMapInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {MapCloudProto.ITimerMapInfo} message TimerMapInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimerMapInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimerMapInfo message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.TimerMapInfo} TimerMapInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimerMapInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.TimerMapInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.zoomLevel = reader.float();
                        break;
                    }
                case 2: {
                        message.pitchAngle = reader.float();
                        break;
                    }
                case 3: {
                        message.rollAngle = reader.float();
                        break;
                    }
                case 4: {
                        message.lon = reader.float();
                        break;
                    }
                case 5: {
                        message.lat = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimerMapInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.TimerMapInfo} TimerMapInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimerMapInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimerMapInfo message.
         * @function verify
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimerMapInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.zoomLevel != null && message.hasOwnProperty("zoomLevel"))
                if (typeof message.zoomLevel !== "number")
                    return "zoomLevel: number expected";
            if (message.pitchAngle != null && message.hasOwnProperty("pitchAngle"))
                if (typeof message.pitchAngle !== "number")
                    return "pitchAngle: number expected";
            if (message.rollAngle != null && message.hasOwnProperty("rollAngle"))
                if (typeof message.rollAngle !== "number")
                    return "rollAngle: number expected";
            if (message.lon != null && message.hasOwnProperty("lon"))
                if (typeof message.lon !== "number")
                    return "lon: number expected";
            if (message.lat != null && message.hasOwnProperty("lat"))
                if (typeof message.lat !== "number")
                    return "lat: number expected";
            return null;
        };

        /**
         * Creates a TimerMapInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.TimerMapInfo} TimerMapInfo
         */
        TimerMapInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.TimerMapInfo)
                return object;
            let message = new $root.MapCloudProto.TimerMapInfo();
            if (object.zoomLevel != null)
                message.zoomLevel = Number(object.zoomLevel);
            if (object.pitchAngle != null)
                message.pitchAngle = Number(object.pitchAngle);
            if (object.rollAngle != null)
                message.rollAngle = Number(object.rollAngle);
            if (object.lon != null)
                message.lon = Number(object.lon);
            if (object.lat != null)
                message.lat = Number(object.lat);
            return message;
        };

        /**
         * Creates a plain object from a TimerMapInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {MapCloudProto.TimerMapInfo} message TimerMapInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimerMapInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.zoomLevel = 0;
                object.pitchAngle = 0;
                object.rollAngle = 0;
                object.lon = 0;
                object.lat = 0;
            }
            if (message.zoomLevel != null && message.hasOwnProperty("zoomLevel"))
                object.zoomLevel = options.json && !isFinite(message.zoomLevel) ? String(message.zoomLevel) : message.zoomLevel;
            if (message.pitchAngle != null && message.hasOwnProperty("pitchAngle"))
                object.pitchAngle = options.json && !isFinite(message.pitchAngle) ? String(message.pitchAngle) : message.pitchAngle;
            if (message.rollAngle != null && message.hasOwnProperty("rollAngle"))
                object.rollAngle = options.json && !isFinite(message.rollAngle) ? String(message.rollAngle) : message.rollAngle;
            if (message.lon != null && message.hasOwnProperty("lon"))
                object.lon = options.json && !isFinite(message.lon) ? String(message.lon) : message.lon;
            if (message.lat != null && message.hasOwnProperty("lat"))
                object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
            return object;
        };

        /**
         * Converts this TimerMapInfo to JSON.
         * @function toJSON
         * @memberof MapCloudProto.TimerMapInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimerMapInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TimerMapInfo
         * @function getTypeUrl
         * @memberof MapCloudProto.TimerMapInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TimerMapInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.TimerMapInfo";
        };

        return TimerMapInfo;
    })();

    /**
     * LogLevel enum.
     * @name MapCloudProto.LogLevel
     * @enum {number}
     * @property {number} LogLevelInfo=0 LogLevelInfo value
     * @property {number} LogLevelError=1 LogLevelError value
     */
    MapCloudProto.LogLevel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LogLevelInfo"] = 0;
        values[valuesById[1] = "LogLevelError"] = 1;
        return values;
    })();

    MapCloudProto.Log = (function() {

        /**
         * Properties of a Log.
         * @memberof MapCloudProto
         * @interface ILog
         * @property {MapCloudProto.LogLevel|null} [level] Log level
         * @property {string|null} [content] Log content
         */

        /**
         * Constructs a new Log.
         * @memberof MapCloudProto
         * @classdesc Represents a Log.
         * @implements ILog
         * @constructor
         * @param {MapCloudProto.ILog=} [properties] Properties to set
         */
        function Log(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Log level.
         * @member {MapCloudProto.LogLevel} level
         * @memberof MapCloudProto.Log
         * @instance
         */
        Log.prototype.level = 0;

        /**
         * Log content.
         * @member {string} content
         * @memberof MapCloudProto.Log
         * @instance
         */
        Log.prototype.content = "";

        /**
         * Creates a new Log instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.Log
         * @static
         * @param {MapCloudProto.ILog=} [properties] Properties to set
         * @returns {MapCloudProto.Log} Log instance
         */
        Log.create = function create(properties) {
            return new Log(properties);
        };

        /**
         * Encodes the specified Log message. Does not implicitly {@link MapCloudProto.Log.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.Log
         * @static
         * @param {MapCloudProto.ILog} message Log message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Log.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.level);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
            return writer;
        };

        /**
         * Encodes the specified Log message, length delimited. Does not implicitly {@link MapCloudProto.Log.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.Log
         * @static
         * @param {MapCloudProto.ILog} message Log message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Log.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Log message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.Log
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.Log} Log
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Log.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.Log();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.level = reader.int32();
                        break;
                    }
                case 2: {
                        message.content = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Log message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.Log
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.Log} Log
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Log.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Log message.
         * @function verify
         * @memberof MapCloudProto.Log
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Log.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.level != null && message.hasOwnProperty("level"))
                switch (message.level) {
                default:
                    return "level: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            return null;
        };

        /**
         * Creates a Log message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.Log
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.Log} Log
         */
        Log.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.Log)
                return object;
            let message = new $root.MapCloudProto.Log();
            switch (object.level) {
            default:
                if (typeof object.level === "number") {
                    message.level = object.level;
                    break;
                }
                break;
            case "LogLevelInfo":
            case 0:
                message.level = 0;
                break;
            case "LogLevelError":
            case 1:
                message.level = 1;
                break;
            }
            if (object.content != null)
                message.content = String(object.content);
            return message;
        };

        /**
         * Creates a plain object from a Log message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.Log
         * @static
         * @param {MapCloudProto.Log} message Log
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Log.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.level = options.enums === String ? "LogLevelInfo" : 0;
                object.content = "";
            }
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = options.enums === String ? $root.MapCloudProto.LogLevel[message.level] === undefined ? message.level : $root.MapCloudProto.LogLevel[message.level] : message.level;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            return object;
        };

        /**
         * Converts this Log to JSON.
         * @function toJSON
         * @memberof MapCloudProto.Log
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Log.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Log
         * @function getTypeUrl
         * @memberof MapCloudProto.Log
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Log.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.Log";
        };

        return Log;
    })();

    MapCloudProto.RealTimeVisualization = (function() {

        /**
         * Properties of a RealTimeVisualization.
         * @memberof MapCloudProto
         * @interface IRealTimeVisualization
         * @property {MapCloudProto.ServiceEnum} service RealTimeVisualization service
         * @property {MapCloudProto.CommandEnum} command RealTimeVisualization command
         * @property {MapCloudProto.ILoginData|null} [loginData] RealTimeVisualization loginData
         * @property {MapCloudProto.ISetMapModeData|null} [setMapModeData] RealTimeVisualization setMapModeData
         * @property {MapCloudProto.ISetMapCenterData|null} [setMapCenterData] RealTimeVisualization setMapCenterData
         * @property {MapCloudProto.IUpdateStyleData|null} [updateStyleData] RealTimeVisualization updateStyleData
         * @property {MapCloudProto.IUpdateIconData|null} [updateIconData] RealTimeVisualization updateIconData
         * @property {MapCloudProto.ISetMapZoomToData|null} [setMapZoomToData] RealTimeVisualization setMapZoomToData
         * @property {number|Long|null} [timeStamp] RealTimeVisualization timeStamp
         * @property {MapCloudProto.ITimeStampData|null} [timeStampData] RealTimeVisualization timeStampData
         * @property {MapCloudProto.ISetSurfaceChangeData|null} [surfaceChangeData] RealTimeVisualization surfaceChangeData
         * @property {MapCloudProto.ISetMouseEventData|null} [mouseEventData] RealTimeVisualization mouseEventData
         * @property {MapCloudProto.ISetMapParameterData|null} [mapParameterData] RealTimeVisualization mapParameterData
         * @property {MapCloudProto.IRequestParameterData|null} [requestParameter] RealTimeVisualization requestParameter
         * @property {MapCloudProto.IMapBaseData|null} [mapBaseData] RealTimeVisualization mapBaseData
         * @property {MapCloudProto.IGestureInfo|null} [gestureInfo] RealTimeVisualization gestureInfo
         * @property {MapCloudProto.IUpdateCustomFileConfigData|null} [updateCustomFileConfigData] RealTimeVisualization updateCustomFileConfigData
         * @property {MapCloudProto.IModifyModelPropertiesData|null} [modifyModelPropertiesData] RealTimeVisualization modifyModelPropertiesData
         * @property {MapCloudProto.IMapControllerStates|null} [mapControllerData] RealTimeVisualization mapControllerData
         * @property {MapCloudProto.ISetWebRtcParameterData|null} [webRtcParameterData] RealTimeVisualization webRtcParameterData
         * @property {MapCloudProto.ISetMapStatusData|null} [mapStatusData] RealTimeVisualization mapStatusData
         * @property {MapCloudProto.IShellCmdData|null} [shellCmdData] RealTimeVisualization shellCmdData
         * @property {MapCloudProto.IWebrtcData|null} [webrtcData] RealTimeVisualization webrtcData
         * @property {MapCloudProto.IZoomLevelData|null} [zoomLevelData] RealTimeVisualization zoomLevelData
         * @property {MapCloudProto.IPitchAngleData|null} [pitchAngleData] RealTimeVisualization pitchAngleData
         * @property {MapCloudProto.IRollAngleData|null} [rollAngleData] RealTimeVisualization rollAngleData
         * @property {MapCloudProto.ISetMapModeStateTime|null} [setMapModeStateTime] RealTimeVisualization setMapModeStateTime
         * @property {string|null} [immersiveData] RealTimeVisualization immersiveData
         * @property {MapCloudProto.IOssKeyData|null} [ossKeyData] RealTimeVisualization ossKeyData
         * @property {string|null} [immersiveResponse] RealTimeVisualization immersiveResponse
         * @property {MapCloudProto.IOssReqData|null} [ossReqData] RealTimeVisualization ossReqData
         * @property {string|null} [traceId] RealTimeVisualization traceId
         * @property {MapCloudProto.IVideoData|null} [videoData] RealTimeVisualization videoData
         * @property {MapCloudProto.IFocusData|null} [focusData] RealTimeVisualization focusData
         * @property {MapCloudProto.ITimerMapInfo|null} [timerMapInfo] RealTimeVisualization timerMapInfo
         * @property {MapCloudProto.ILog|null} [log] RealTimeVisualization log
         */

        /**
         * Constructs a new RealTimeVisualization.
         * @memberof MapCloudProto
         * @classdesc Represents a RealTimeVisualization.
         * @implements IRealTimeVisualization
         * @constructor
         * @param {MapCloudProto.IRealTimeVisualization=} [properties] Properties to set
         */
        function RealTimeVisualization(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RealTimeVisualization service.
         * @member {MapCloudProto.ServiceEnum} service
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.service = 0;

        /**
         * RealTimeVisualization command.
         * @member {MapCloudProto.CommandEnum} command
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.command = 0;

        /**
         * RealTimeVisualization loginData.
         * @member {MapCloudProto.ILoginData|null|undefined} loginData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.loginData = null;

        /**
         * RealTimeVisualization setMapModeData.
         * @member {MapCloudProto.ISetMapModeData|null|undefined} setMapModeData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.setMapModeData = null;

        /**
         * RealTimeVisualization setMapCenterData.
         * @member {MapCloudProto.ISetMapCenterData|null|undefined} setMapCenterData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.setMapCenterData = null;

        /**
         * RealTimeVisualization updateStyleData.
         * @member {MapCloudProto.IUpdateStyleData|null|undefined} updateStyleData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.updateStyleData = null;

        /**
         * RealTimeVisualization updateIconData.
         * @member {MapCloudProto.IUpdateIconData|null|undefined} updateIconData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.updateIconData = null;

        /**
         * RealTimeVisualization setMapZoomToData.
         * @member {MapCloudProto.ISetMapZoomToData|null|undefined} setMapZoomToData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.setMapZoomToData = null;

        /**
         * RealTimeVisualization timeStamp.
         * @member {number|Long} timeStamp
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.timeStamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RealTimeVisualization timeStampData.
         * @member {MapCloudProto.ITimeStampData|null|undefined} timeStampData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.timeStampData = null;

        /**
         * RealTimeVisualization surfaceChangeData.
         * @member {MapCloudProto.ISetSurfaceChangeData|null|undefined} surfaceChangeData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.surfaceChangeData = null;

        /**
         * RealTimeVisualization mouseEventData.
         * @member {MapCloudProto.ISetMouseEventData|null|undefined} mouseEventData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.mouseEventData = null;

        /**
         * RealTimeVisualization mapParameterData.
         * @member {MapCloudProto.ISetMapParameterData|null|undefined} mapParameterData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.mapParameterData = null;

        /**
         * RealTimeVisualization requestParameter.
         * @member {MapCloudProto.IRequestParameterData|null|undefined} requestParameter
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.requestParameter = null;

        /**
         * RealTimeVisualization mapBaseData.
         * @member {MapCloudProto.IMapBaseData|null|undefined} mapBaseData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.mapBaseData = null;

        /**
         * RealTimeVisualization gestureInfo.
         * @member {MapCloudProto.IGestureInfo|null|undefined} gestureInfo
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.gestureInfo = null;

        /**
         * RealTimeVisualization updateCustomFileConfigData.
         * @member {MapCloudProto.IUpdateCustomFileConfigData|null|undefined} updateCustomFileConfigData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.updateCustomFileConfigData = null;

        /**
         * RealTimeVisualization modifyModelPropertiesData.
         * @member {MapCloudProto.IModifyModelPropertiesData|null|undefined} modifyModelPropertiesData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.modifyModelPropertiesData = null;

        /**
         * RealTimeVisualization mapControllerData.
         * @member {MapCloudProto.IMapControllerStates|null|undefined} mapControllerData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.mapControllerData = null;

        /**
         * RealTimeVisualization webRtcParameterData.
         * @member {MapCloudProto.ISetWebRtcParameterData|null|undefined} webRtcParameterData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.webRtcParameterData = null;

        /**
         * RealTimeVisualization mapStatusData.
         * @member {MapCloudProto.ISetMapStatusData|null|undefined} mapStatusData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.mapStatusData = null;

        /**
         * RealTimeVisualization shellCmdData.
         * @member {MapCloudProto.IShellCmdData|null|undefined} shellCmdData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.shellCmdData = null;

        /**
         * RealTimeVisualization webrtcData.
         * @member {MapCloudProto.IWebrtcData|null|undefined} webrtcData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.webrtcData = null;

        /**
         * RealTimeVisualization zoomLevelData.
         * @member {MapCloudProto.IZoomLevelData|null|undefined} zoomLevelData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.zoomLevelData = null;

        /**
         * RealTimeVisualization pitchAngleData.
         * @member {MapCloudProto.IPitchAngleData|null|undefined} pitchAngleData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.pitchAngleData = null;

        /**
         * RealTimeVisualization rollAngleData.
         * @member {MapCloudProto.IRollAngleData|null|undefined} rollAngleData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.rollAngleData = null;

        /**
         * RealTimeVisualization setMapModeStateTime.
         * @member {MapCloudProto.ISetMapModeStateTime|null|undefined} setMapModeStateTime
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.setMapModeStateTime = null;

        /**
         * RealTimeVisualization immersiveData.
         * @member {string} immersiveData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.immersiveData = "";

        /**
         * RealTimeVisualization ossKeyData.
         * @member {MapCloudProto.IOssKeyData|null|undefined} ossKeyData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.ossKeyData = null;

        /**
         * RealTimeVisualization immersiveResponse.
         * @member {string} immersiveResponse
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.immersiveResponse = "";

        /**
         * RealTimeVisualization ossReqData.
         * @member {MapCloudProto.IOssReqData|null|undefined} ossReqData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.ossReqData = null;

        /**
         * RealTimeVisualization traceId.
         * @member {string} traceId
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.traceId = "";

        /**
         * RealTimeVisualization videoData.
         * @member {MapCloudProto.IVideoData|null|undefined} videoData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.videoData = null;

        /**
         * RealTimeVisualization focusData.
         * @member {MapCloudProto.IFocusData|null|undefined} focusData
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.focusData = null;

        /**
         * RealTimeVisualization timerMapInfo.
         * @member {MapCloudProto.ITimerMapInfo|null|undefined} timerMapInfo
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.timerMapInfo = null;

        /**
         * RealTimeVisualization log.
         * @member {MapCloudProto.ILog|null|undefined} log
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         */
        RealTimeVisualization.prototype.log = null;

        /**
         * Creates a new RealTimeVisualization instance using the specified properties.
         * @function create
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {MapCloudProto.IRealTimeVisualization=} [properties] Properties to set
         * @returns {MapCloudProto.RealTimeVisualization} RealTimeVisualization instance
         */
        RealTimeVisualization.create = function create(properties) {
            return new RealTimeVisualization(properties);
        };

        /**
         * Encodes the specified RealTimeVisualization message. Does not implicitly {@link MapCloudProto.RealTimeVisualization.verify|verify} messages.
         * @function encode
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {MapCloudProto.IRealTimeVisualization} message RealTimeVisualization message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RealTimeVisualization.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.service);
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.command);
            if (message.loginData != null && Object.hasOwnProperty.call(message, "loginData"))
                $root.MapCloudProto.LoginData.encode(message.loginData, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.setMapModeData != null && Object.hasOwnProperty.call(message, "setMapModeData"))
                $root.MapCloudProto.SetMapModeData.encode(message.setMapModeData, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.setMapCenterData != null && Object.hasOwnProperty.call(message, "setMapCenterData"))
                $root.MapCloudProto.SetMapCenterData.encode(message.setMapCenterData, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.updateStyleData != null && Object.hasOwnProperty.call(message, "updateStyleData"))
                $root.MapCloudProto.UpdateStyleData.encode(message.updateStyleData, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updateIconData != null && Object.hasOwnProperty.call(message, "updateIconData"))
                $root.MapCloudProto.UpdateIconData.encode(message.updateIconData, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.setMapZoomToData != null && Object.hasOwnProperty.call(message, "setMapZoomToData"))
                $root.MapCloudProto.SetMapZoomToData.encode(message.setMapZoomToData, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.timeStamp != null && Object.hasOwnProperty.call(message, "timeStamp"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.timeStamp);
            if (message.timeStampData != null && Object.hasOwnProperty.call(message, "timeStampData"))
                $root.MapCloudProto.TimeStampData.encode(message.timeStampData, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.surfaceChangeData != null && Object.hasOwnProperty.call(message, "surfaceChangeData"))
                $root.MapCloudProto.SetSurfaceChangeData.encode(message.surfaceChangeData, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.mouseEventData != null && Object.hasOwnProperty.call(message, "mouseEventData"))
                $root.MapCloudProto.SetMouseEventData.encode(message.mouseEventData, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.mapParameterData != null && Object.hasOwnProperty.call(message, "mapParameterData"))
                $root.MapCloudProto.SetMapParameterData.encode(message.mapParameterData, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.requestParameter != null && Object.hasOwnProperty.call(message, "requestParameter"))
                $root.MapCloudProto.RequestParameterData.encode(message.requestParameter, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.mapBaseData != null && Object.hasOwnProperty.call(message, "mapBaseData"))
                $root.MapCloudProto.MapBaseData.encode(message.mapBaseData, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.gestureInfo != null && Object.hasOwnProperty.call(message, "gestureInfo"))
                $root.MapCloudProto.GestureInfo.encode(message.gestureInfo, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.updateCustomFileConfigData != null && Object.hasOwnProperty.call(message, "updateCustomFileConfigData"))
                $root.MapCloudProto.UpdateCustomFileConfigData.encode(message.updateCustomFileConfigData, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.modifyModelPropertiesData != null && Object.hasOwnProperty.call(message, "modifyModelPropertiesData"))
                $root.MapCloudProto.ModifyModelPropertiesData.encode(message.modifyModelPropertiesData, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.mapControllerData != null && Object.hasOwnProperty.call(message, "mapControllerData"))
                $root.MapCloudProto.MapControllerStates.encode(message.mapControllerData, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.webRtcParameterData != null && Object.hasOwnProperty.call(message, "webRtcParameterData"))
                $root.MapCloudProto.SetWebRtcParameterData.encode(message.webRtcParameterData, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.mapStatusData != null && Object.hasOwnProperty.call(message, "mapStatusData"))
                $root.MapCloudProto.SetMapStatusData.encode(message.mapStatusData, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.shellCmdData != null && Object.hasOwnProperty.call(message, "shellCmdData"))
                $root.MapCloudProto.ShellCmdData.encode(message.shellCmdData, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.webrtcData != null && Object.hasOwnProperty.call(message, "webrtcData"))
                $root.MapCloudProto.WebrtcData.encode(message.webrtcData, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.zoomLevelData != null && Object.hasOwnProperty.call(message, "zoomLevelData"))
                $root.MapCloudProto.ZoomLevelData.encode(message.zoomLevelData, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.pitchAngleData != null && Object.hasOwnProperty.call(message, "pitchAngleData"))
                $root.MapCloudProto.PitchAngleData.encode(message.pitchAngleData, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.rollAngleData != null && Object.hasOwnProperty.call(message, "rollAngleData"))
                $root.MapCloudProto.RollAngleData.encode(message.rollAngleData, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.setMapModeStateTime != null && Object.hasOwnProperty.call(message, "setMapModeStateTime"))
                $root.MapCloudProto.SetMapModeStateTime.encode(message.setMapModeStateTime, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.immersiveData != null && Object.hasOwnProperty.call(message, "immersiveData"))
                writer.uint32(/* id 53, wireType 2 =*/426).string(message.immersiveData);
            if (message.ossKeyData != null && Object.hasOwnProperty.call(message, "ossKeyData"))
                $root.MapCloudProto.OssKeyData.encode(message.ossKeyData, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
            if (message.immersiveResponse != null && Object.hasOwnProperty.call(message, "immersiveResponse"))
                writer.uint32(/* id 1053, wireType 2 =*/8426).string(message.immersiveResponse);
            if (message.ossReqData != null && Object.hasOwnProperty.call(message, "ossReqData"))
                $root.MapCloudProto.OssReqData.encode(message.ossReqData, writer.uint32(/* id 1100, wireType 2 =*/8802).fork()).ldelim();
            if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId"))
                writer.uint32(/* id 2000, wireType 2 =*/16002).string(message.traceId);
            if (message.videoData != null && Object.hasOwnProperty.call(message, "videoData"))
                $root.MapCloudProto.VideoData.encode(message.videoData, writer.uint32(/* id 2001, wireType 2 =*/16010).fork()).ldelim();
            if (message.focusData != null && Object.hasOwnProperty.call(message, "focusData"))
                $root.MapCloudProto.FocusData.encode(message.focusData, writer.uint32(/* id 2002, wireType 2 =*/16018).fork()).ldelim();
            if (message.timerMapInfo != null && Object.hasOwnProperty.call(message, "timerMapInfo"))
                $root.MapCloudProto.TimerMapInfo.encode(message.timerMapInfo, writer.uint32(/* id 2003, wireType 2 =*/16026).fork()).ldelim();
            if (message.log != null && Object.hasOwnProperty.call(message, "log"))
                $root.MapCloudProto.Log.encode(message.log, writer.uint32(/* id 2004, wireType 2 =*/16034).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RealTimeVisualization message, length delimited. Does not implicitly {@link MapCloudProto.RealTimeVisualization.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {MapCloudProto.IRealTimeVisualization} message RealTimeVisualization message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RealTimeVisualization.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RealTimeVisualization message from the specified reader or buffer.
         * @function decode
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MapCloudProto.RealTimeVisualization} RealTimeVisualization
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RealTimeVisualization.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MapCloudProto.RealTimeVisualization();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.service = reader.int32();
                        break;
                    }
                case 2: {
                        message.command = reader.int32();
                        break;
                    }
                case 3: {
                        message.loginData = $root.MapCloudProto.LoginData.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.setMapModeData = $root.MapCloudProto.SetMapModeData.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.setMapCenterData = $root.MapCloudProto.SetMapCenterData.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.updateStyleData = $root.MapCloudProto.UpdateStyleData.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.updateIconData = $root.MapCloudProto.UpdateIconData.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.setMapZoomToData = $root.MapCloudProto.SetMapZoomToData.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.timeStamp = reader.int64();
                        break;
                    }
                case 10: {
                        message.timeStampData = $root.MapCloudProto.TimeStampData.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.surfaceChangeData = $root.MapCloudProto.SetSurfaceChangeData.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.mouseEventData = $root.MapCloudProto.SetMouseEventData.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.mapParameterData = $root.MapCloudProto.SetMapParameterData.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.requestParameter = $root.MapCloudProto.RequestParameterData.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message.mapBaseData = $root.MapCloudProto.MapBaseData.decode(reader, reader.uint32());
                        break;
                    }
                case 17: {
                        message.gestureInfo = $root.MapCloudProto.GestureInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.updateCustomFileConfigData = $root.MapCloudProto.UpdateCustomFileConfigData.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.modifyModelPropertiesData = $root.MapCloudProto.ModifyModelPropertiesData.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.mapControllerData = $root.MapCloudProto.MapControllerStates.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.webRtcParameterData = $root.MapCloudProto.SetWebRtcParameterData.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.mapStatusData = $root.MapCloudProto.SetMapStatusData.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.shellCmdData = $root.MapCloudProto.ShellCmdData.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.webrtcData = $root.MapCloudProto.WebrtcData.decode(reader, reader.uint32());
                        break;
                    }
                case 30: {
                        message.zoomLevelData = $root.MapCloudProto.ZoomLevelData.decode(reader, reader.uint32());
                        break;
                    }
                case 31: {
                        message.pitchAngleData = $root.MapCloudProto.PitchAngleData.decode(reader, reader.uint32());
                        break;
                    }
                case 32: {
                        message.rollAngleData = $root.MapCloudProto.RollAngleData.decode(reader, reader.uint32());
                        break;
                    }
                case 33: {
                        message.setMapModeStateTime = $root.MapCloudProto.SetMapModeStateTime.decode(reader, reader.uint32());
                        break;
                    }
                case 53: {
                        message.immersiveData = reader.string();
                        break;
                    }
                case 100: {
                        message.ossKeyData = $root.MapCloudProto.OssKeyData.decode(reader, reader.uint32());
                        break;
                    }
                case 1053: {
                        message.immersiveResponse = reader.string();
                        break;
                    }
                case 1100: {
                        message.ossReqData = $root.MapCloudProto.OssReqData.decode(reader, reader.uint32());
                        break;
                    }
                case 2000: {
                        message.traceId = reader.string();
                        break;
                    }
                case 2001: {
                        message.videoData = $root.MapCloudProto.VideoData.decode(reader, reader.uint32());
                        break;
                    }
                case 2002: {
                        message.focusData = $root.MapCloudProto.FocusData.decode(reader, reader.uint32());
                        break;
                    }
                case 2003: {
                        message.timerMapInfo = $root.MapCloudProto.TimerMapInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 2004: {
                        message.log = $root.MapCloudProto.Log.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("service"))
                throw $util.ProtocolError("missing required 'service'", { instance: message });
            if (!message.hasOwnProperty("command"))
                throw $util.ProtocolError("missing required 'command'", { instance: message });
            return message;
        };

        /**
         * Decodes a RealTimeVisualization message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MapCloudProto.RealTimeVisualization} RealTimeVisualization
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RealTimeVisualization.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RealTimeVisualization message.
         * @function verify
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RealTimeVisualization.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.service) {
            default:
                return "service: enum value expected";
            case 0:
                break;
            }
            switch (message.command) {
            default:
                return "command: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
            case 30:
            case 31:
            case 32:
            case 33:
            case 53:
            case 100:
            case 1053:
            case 1100:
            case 2001:
            case 2002:
            case 2003:
            case 2004:
                break;
            }
            if (message.loginData != null && message.hasOwnProperty("loginData")) {
                let error = $root.MapCloudProto.LoginData.verify(message.loginData);
                if (error)
                    return "loginData." + error;
            }
            if (message.setMapModeData != null && message.hasOwnProperty("setMapModeData")) {
                let error = $root.MapCloudProto.SetMapModeData.verify(message.setMapModeData);
                if (error)
                    return "setMapModeData." + error;
            }
            if (message.setMapCenterData != null && message.hasOwnProperty("setMapCenterData")) {
                let error = $root.MapCloudProto.SetMapCenterData.verify(message.setMapCenterData);
                if (error)
                    return "setMapCenterData." + error;
            }
            if (message.updateStyleData != null && message.hasOwnProperty("updateStyleData")) {
                let error = $root.MapCloudProto.UpdateStyleData.verify(message.updateStyleData);
                if (error)
                    return "updateStyleData." + error;
            }
            if (message.updateIconData != null && message.hasOwnProperty("updateIconData")) {
                let error = $root.MapCloudProto.UpdateIconData.verify(message.updateIconData);
                if (error)
                    return "updateIconData." + error;
            }
            if (message.setMapZoomToData != null && message.hasOwnProperty("setMapZoomToData")) {
                let error = $root.MapCloudProto.SetMapZoomToData.verify(message.setMapZoomToData);
                if (error)
                    return "setMapZoomToData." + error;
            }
            if (message.timeStamp != null && message.hasOwnProperty("timeStamp"))
                if (!$util.isInteger(message.timeStamp) && !(message.timeStamp && $util.isInteger(message.timeStamp.low) && $util.isInteger(message.timeStamp.high)))
                    return "timeStamp: integer|Long expected";
            if (message.timeStampData != null && message.hasOwnProperty("timeStampData")) {
                let error = $root.MapCloudProto.TimeStampData.verify(message.timeStampData);
                if (error)
                    return "timeStampData." + error;
            }
            if (message.surfaceChangeData != null && message.hasOwnProperty("surfaceChangeData")) {
                let error = $root.MapCloudProto.SetSurfaceChangeData.verify(message.surfaceChangeData);
                if (error)
                    return "surfaceChangeData." + error;
            }
            if (message.mouseEventData != null && message.hasOwnProperty("mouseEventData")) {
                let error = $root.MapCloudProto.SetMouseEventData.verify(message.mouseEventData);
                if (error)
                    return "mouseEventData." + error;
            }
            if (message.mapParameterData != null && message.hasOwnProperty("mapParameterData")) {
                let error = $root.MapCloudProto.SetMapParameterData.verify(message.mapParameterData);
                if (error)
                    return "mapParameterData." + error;
            }
            if (message.requestParameter != null && message.hasOwnProperty("requestParameter")) {
                let error = $root.MapCloudProto.RequestParameterData.verify(message.requestParameter);
                if (error)
                    return "requestParameter." + error;
            }
            if (message.mapBaseData != null && message.hasOwnProperty("mapBaseData")) {
                let error = $root.MapCloudProto.MapBaseData.verify(message.mapBaseData);
                if (error)
                    return "mapBaseData." + error;
            }
            if (message.gestureInfo != null && message.hasOwnProperty("gestureInfo")) {
                let error = $root.MapCloudProto.GestureInfo.verify(message.gestureInfo);
                if (error)
                    return "gestureInfo." + error;
            }
            if (message.updateCustomFileConfigData != null && message.hasOwnProperty("updateCustomFileConfigData")) {
                let error = $root.MapCloudProto.UpdateCustomFileConfigData.verify(message.updateCustomFileConfigData);
                if (error)
                    return "updateCustomFileConfigData." + error;
            }
            if (message.modifyModelPropertiesData != null && message.hasOwnProperty("modifyModelPropertiesData")) {
                let error = $root.MapCloudProto.ModifyModelPropertiesData.verify(message.modifyModelPropertiesData);
                if (error)
                    return "modifyModelPropertiesData." + error;
            }
            if (message.mapControllerData != null && message.hasOwnProperty("mapControllerData")) {
                let error = $root.MapCloudProto.MapControllerStates.verify(message.mapControllerData);
                if (error)
                    return "mapControllerData." + error;
            }
            if (message.webRtcParameterData != null && message.hasOwnProperty("webRtcParameterData")) {
                let error = $root.MapCloudProto.SetWebRtcParameterData.verify(message.webRtcParameterData);
                if (error)
                    return "webRtcParameterData." + error;
            }
            if (message.mapStatusData != null && message.hasOwnProperty("mapStatusData")) {
                let error = $root.MapCloudProto.SetMapStatusData.verify(message.mapStatusData);
                if (error)
                    return "mapStatusData." + error;
            }
            if (message.shellCmdData != null && message.hasOwnProperty("shellCmdData")) {
                let error = $root.MapCloudProto.ShellCmdData.verify(message.shellCmdData);
                if (error)
                    return "shellCmdData." + error;
            }
            if (message.webrtcData != null && message.hasOwnProperty("webrtcData")) {
                let error = $root.MapCloudProto.WebrtcData.verify(message.webrtcData);
                if (error)
                    return "webrtcData." + error;
            }
            if (message.zoomLevelData != null && message.hasOwnProperty("zoomLevelData")) {
                let error = $root.MapCloudProto.ZoomLevelData.verify(message.zoomLevelData);
                if (error)
                    return "zoomLevelData." + error;
            }
            if (message.pitchAngleData != null && message.hasOwnProperty("pitchAngleData")) {
                let error = $root.MapCloudProto.PitchAngleData.verify(message.pitchAngleData);
                if (error)
                    return "pitchAngleData." + error;
            }
            if (message.rollAngleData != null && message.hasOwnProperty("rollAngleData")) {
                let error = $root.MapCloudProto.RollAngleData.verify(message.rollAngleData);
                if (error)
                    return "rollAngleData." + error;
            }
            if (message.setMapModeStateTime != null && message.hasOwnProperty("setMapModeStateTime")) {
                let error = $root.MapCloudProto.SetMapModeStateTime.verify(message.setMapModeStateTime);
                if (error)
                    return "setMapModeStateTime." + error;
            }
            if (message.immersiveData != null && message.hasOwnProperty("immersiveData"))
                if (!$util.isString(message.immersiveData))
                    return "immersiveData: string expected";
            if (message.ossKeyData != null && message.hasOwnProperty("ossKeyData")) {
                let error = $root.MapCloudProto.OssKeyData.verify(message.ossKeyData);
                if (error)
                    return "ossKeyData." + error;
            }
            if (message.immersiveResponse != null && message.hasOwnProperty("immersiveResponse"))
                if (!$util.isString(message.immersiveResponse))
                    return "immersiveResponse: string expected";
            if (message.ossReqData != null && message.hasOwnProperty("ossReqData")) {
                let error = $root.MapCloudProto.OssReqData.verify(message.ossReqData);
                if (error)
                    return "ossReqData." + error;
            }
            if (message.traceId != null && message.hasOwnProperty("traceId"))
                if (!$util.isString(message.traceId))
                    return "traceId: string expected";
            if (message.videoData != null && message.hasOwnProperty("videoData")) {
                let error = $root.MapCloudProto.VideoData.verify(message.videoData);
                if (error)
                    return "videoData." + error;
            }
            if (message.focusData != null && message.hasOwnProperty("focusData")) {
                let error = $root.MapCloudProto.FocusData.verify(message.focusData);
                if (error)
                    return "focusData." + error;
            }
            if (message.timerMapInfo != null && message.hasOwnProperty("timerMapInfo")) {
                let error = $root.MapCloudProto.TimerMapInfo.verify(message.timerMapInfo);
                if (error)
                    return "timerMapInfo." + error;
            }
            if (message.log != null && message.hasOwnProperty("log")) {
                let error = $root.MapCloudProto.Log.verify(message.log);
                if (error)
                    return "log." + error;
            }
            return null;
        };

        /**
         * Creates a RealTimeVisualization message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MapCloudProto.RealTimeVisualization} RealTimeVisualization
         */
        RealTimeVisualization.fromObject = function fromObject(object) {
            if (object instanceof $root.MapCloudProto.RealTimeVisualization)
                return object;
            let message = new $root.MapCloudProto.RealTimeVisualization();
            switch (object.service) {
            default:
                if (typeof object.service === "number") {
                    message.service = object.service;
                    break;
                }
                break;
            case "ServiceMap":
            case 0:
                message.service = 0;
                break;
            }
            switch (object.command) {
            default:
                if (typeof object.command === "number") {
                    message.command = object.command;
                    break;
                }
                break;
            case "CommandLogin":
            case 0:
                message.command = 0;
                break;
            case "CommandSetMapMode":
            case 1:
                message.command = 1;
                break;
            case "CommandSetMapCenter":
            case 2:
                message.command = 2;
                break;
            case "CommandSetMapZoomIn":
            case 3:
                message.command = 3;
                break;
            case "CommandSetMapZoomOut":
            case 4:
                message.command = 4;
                break;
            case "CommandUpdateStyle":
            case 5:
                message.command = 5;
                break;
            case "CommandUpdateIcon":
            case 6:
                message.command = 6;
                break;
            case "CommandZoomTo":
            case 7:
                message.command = 7;
                break;
            case "CommandSurfaceChange":
            case 8:
                message.command = 8;
                break;
            case "CommandMouseEvent":
            case 9:
                message.command = 9;
                break;
            case "CommandSetMapParameter":
            case 10:
                message.command = 10;
                break;
            case "CommandRequestParameter":
            case 12:
                message.command = 12;
                break;
            case "CommandMapBaseInfo":
            case 13:
                message.command = 13;
                break;
            case "CommandLogout":
            case 14:
                message.command = 14;
                break;
            case "CommandGesture":
            case 15:
                message.command = 15;
                break;
            case "CommandConfigUpdate":
            case 16:
                message.command = 16;
                break;
            case "CommandModifyModelProperties":
            case 17:
                message.command = 17;
                break;
            case "CommandSetControllerStates":
            case 18:
                message.command = 18;
                break;
            case "CommandSetWebRtcParameter":
            case 19:
                message.command = 19;
                break;
            case "CommandSetMapStatus":
            case 20:
                message.command = 20;
                break;
            case "CommandSetShellCmd":
            case 21:
                message.command = 21;
                break;
            case "CommandWebRtcOffer":
            case 22:
                message.command = 22;
                break;
            case "CommandWebRtcAnswer":
            case 23:
                message.command = 23;
                break;
            case "CommandWebRtcCandidateFromService":
            case 24:
                message.command = 24;
                break;
            case "CommandWebRtcCandidateFromClient":
            case 25:
                message.command = 25;
                break;
            case "CommandSetZoomLevel":
            case 30:
                message.command = 30;
                break;
            case "CommandSetPitchAngle":
            case 31:
                message.command = 31;
                break;
            case "CommandSetRollAngle":
            case 32:
                message.command = 32;
                break;
            case "CommandSetMapModeStateTime":
            case 33:
                message.command = 33;
                break;
            case "CommandImmersiveBusiness":
            case 53:
                message.command = 53;
                break;
            case "CommandOssKeyResponse":
            case 100:
                message.command = 100;
                break;
            case "CommandImmersiveResponse":
            case 1053:
                message.command = 1053;
                break;
            case "CommandOssKeyRequest":
            case 1100:
                message.command = 1100;
                break;
            case "CommandSendVideo":
            case 2001:
                message.command = 2001;
                break;
            case "CommandConsumerFocus":
            case 2002:
                message.command = 2002;
                break;
            case "CommandTimerMapInfo":
            case 2003:
                message.command = 2003;
                break;
            case "CommandLog":
            case 2004:
                message.command = 2004;
                break;
            }
            if (object.loginData != null) {
                if (typeof object.loginData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.loginData: object expected");
                message.loginData = $root.MapCloudProto.LoginData.fromObject(object.loginData);
            }
            if (object.setMapModeData != null) {
                if (typeof object.setMapModeData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.setMapModeData: object expected");
                message.setMapModeData = $root.MapCloudProto.SetMapModeData.fromObject(object.setMapModeData);
            }
            if (object.setMapCenterData != null) {
                if (typeof object.setMapCenterData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.setMapCenterData: object expected");
                message.setMapCenterData = $root.MapCloudProto.SetMapCenterData.fromObject(object.setMapCenterData);
            }
            if (object.updateStyleData != null) {
                if (typeof object.updateStyleData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.updateStyleData: object expected");
                message.updateStyleData = $root.MapCloudProto.UpdateStyleData.fromObject(object.updateStyleData);
            }
            if (object.updateIconData != null) {
                if (typeof object.updateIconData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.updateIconData: object expected");
                message.updateIconData = $root.MapCloudProto.UpdateIconData.fromObject(object.updateIconData);
            }
            if (object.setMapZoomToData != null) {
                if (typeof object.setMapZoomToData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.setMapZoomToData: object expected");
                message.setMapZoomToData = $root.MapCloudProto.SetMapZoomToData.fromObject(object.setMapZoomToData);
            }
            if (object.timeStamp != null)
                if ($util.Long)
                    (message.timeStamp = $util.Long.fromValue(object.timeStamp)).unsigned = false;
                else if (typeof object.timeStamp === "string")
                    message.timeStamp = parseInt(object.timeStamp, 10);
                else if (typeof object.timeStamp === "number")
                    message.timeStamp = object.timeStamp;
                else if (typeof object.timeStamp === "object")
                    message.timeStamp = new $util.LongBits(object.timeStamp.low >>> 0, object.timeStamp.high >>> 0).toNumber();
            if (object.timeStampData != null) {
                if (typeof object.timeStampData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.timeStampData: object expected");
                message.timeStampData = $root.MapCloudProto.TimeStampData.fromObject(object.timeStampData);
            }
            if (object.surfaceChangeData != null) {
                if (typeof object.surfaceChangeData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.surfaceChangeData: object expected");
                message.surfaceChangeData = $root.MapCloudProto.SetSurfaceChangeData.fromObject(object.surfaceChangeData);
            }
            if (object.mouseEventData != null) {
                if (typeof object.mouseEventData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.mouseEventData: object expected");
                message.mouseEventData = $root.MapCloudProto.SetMouseEventData.fromObject(object.mouseEventData);
            }
            if (object.mapParameterData != null) {
                if (typeof object.mapParameterData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.mapParameterData: object expected");
                message.mapParameterData = $root.MapCloudProto.SetMapParameterData.fromObject(object.mapParameterData);
            }
            if (object.requestParameter != null) {
                if (typeof object.requestParameter !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.requestParameter: object expected");
                message.requestParameter = $root.MapCloudProto.RequestParameterData.fromObject(object.requestParameter);
            }
            if (object.mapBaseData != null) {
                if (typeof object.mapBaseData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.mapBaseData: object expected");
                message.mapBaseData = $root.MapCloudProto.MapBaseData.fromObject(object.mapBaseData);
            }
            if (object.gestureInfo != null) {
                if (typeof object.gestureInfo !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.gestureInfo: object expected");
                message.gestureInfo = $root.MapCloudProto.GestureInfo.fromObject(object.gestureInfo);
            }
            if (object.updateCustomFileConfigData != null) {
                if (typeof object.updateCustomFileConfigData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.updateCustomFileConfigData: object expected");
                message.updateCustomFileConfigData = $root.MapCloudProto.UpdateCustomFileConfigData.fromObject(object.updateCustomFileConfigData);
            }
            if (object.modifyModelPropertiesData != null) {
                if (typeof object.modifyModelPropertiesData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.modifyModelPropertiesData: object expected");
                message.modifyModelPropertiesData = $root.MapCloudProto.ModifyModelPropertiesData.fromObject(object.modifyModelPropertiesData);
            }
            if (object.mapControllerData != null) {
                if (typeof object.mapControllerData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.mapControllerData: object expected");
                message.mapControllerData = $root.MapCloudProto.MapControllerStates.fromObject(object.mapControllerData);
            }
            if (object.webRtcParameterData != null) {
                if (typeof object.webRtcParameterData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.webRtcParameterData: object expected");
                message.webRtcParameterData = $root.MapCloudProto.SetWebRtcParameterData.fromObject(object.webRtcParameterData);
            }
            if (object.mapStatusData != null) {
                if (typeof object.mapStatusData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.mapStatusData: object expected");
                message.mapStatusData = $root.MapCloudProto.SetMapStatusData.fromObject(object.mapStatusData);
            }
            if (object.shellCmdData != null) {
                if (typeof object.shellCmdData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.shellCmdData: object expected");
                message.shellCmdData = $root.MapCloudProto.ShellCmdData.fromObject(object.shellCmdData);
            }
            if (object.webrtcData != null) {
                if (typeof object.webrtcData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.webrtcData: object expected");
                message.webrtcData = $root.MapCloudProto.WebrtcData.fromObject(object.webrtcData);
            }
            if (object.zoomLevelData != null) {
                if (typeof object.zoomLevelData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.zoomLevelData: object expected");
                message.zoomLevelData = $root.MapCloudProto.ZoomLevelData.fromObject(object.zoomLevelData);
            }
            if (object.pitchAngleData != null) {
                if (typeof object.pitchAngleData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.pitchAngleData: object expected");
                message.pitchAngleData = $root.MapCloudProto.PitchAngleData.fromObject(object.pitchAngleData);
            }
            if (object.rollAngleData != null) {
                if (typeof object.rollAngleData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.rollAngleData: object expected");
                message.rollAngleData = $root.MapCloudProto.RollAngleData.fromObject(object.rollAngleData);
            }
            if (object.setMapModeStateTime != null) {
                if (typeof object.setMapModeStateTime !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.setMapModeStateTime: object expected");
                message.setMapModeStateTime = $root.MapCloudProto.SetMapModeStateTime.fromObject(object.setMapModeStateTime);
            }
            if (object.immersiveData != null)
                message.immersiveData = String(object.immersiveData);
            if (object.ossKeyData != null) {
                if (typeof object.ossKeyData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.ossKeyData: object expected");
                message.ossKeyData = $root.MapCloudProto.OssKeyData.fromObject(object.ossKeyData);
            }
            if (object.immersiveResponse != null)
                message.immersiveResponse = String(object.immersiveResponse);
            if (object.ossReqData != null) {
                if (typeof object.ossReqData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.ossReqData: object expected");
                message.ossReqData = $root.MapCloudProto.OssReqData.fromObject(object.ossReqData);
            }
            if (object.traceId != null)
                message.traceId = String(object.traceId);
            if (object.videoData != null) {
                if (typeof object.videoData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.videoData: object expected");
                message.videoData = $root.MapCloudProto.VideoData.fromObject(object.videoData);
            }
            if (object.focusData != null) {
                if (typeof object.focusData !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.focusData: object expected");
                message.focusData = $root.MapCloudProto.FocusData.fromObject(object.focusData);
            }
            if (object.timerMapInfo != null) {
                if (typeof object.timerMapInfo !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.timerMapInfo: object expected");
                message.timerMapInfo = $root.MapCloudProto.TimerMapInfo.fromObject(object.timerMapInfo);
            }
            if (object.log != null) {
                if (typeof object.log !== "object")
                    throw TypeError(".MapCloudProto.RealTimeVisualization.log: object expected");
                message.log = $root.MapCloudProto.Log.fromObject(object.log);
            }
            return message;
        };

        /**
         * Creates a plain object from a RealTimeVisualization message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {MapCloudProto.RealTimeVisualization} message RealTimeVisualization
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RealTimeVisualization.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.service = options.enums === String ? "ServiceMap" : 0;
                object.command = options.enums === String ? "CommandLogin" : 0;
                object.loginData = null;
                object.setMapModeData = null;
                object.setMapCenterData = null;
                object.updateStyleData = null;
                object.updateIconData = null;
                object.setMapZoomToData = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timeStamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timeStamp = options.longs === String ? "0" : 0;
                object.timeStampData = null;
                object.surfaceChangeData = null;
                object.mouseEventData = null;
                object.mapParameterData = null;
                object.requestParameter = null;
                object.mapBaseData = null;
                object.gestureInfo = null;
                object.updateCustomFileConfigData = null;
                object.modifyModelPropertiesData = null;
                object.mapControllerData = null;
                object.webRtcParameterData = null;
                object.mapStatusData = null;
                object.shellCmdData = null;
                object.webrtcData = null;
                object.zoomLevelData = null;
                object.pitchAngleData = null;
                object.rollAngleData = null;
                object.setMapModeStateTime = null;
                object.immersiveData = "";
                object.ossKeyData = null;
                object.immersiveResponse = "";
                object.ossReqData = null;
                object.traceId = "";
                object.videoData = null;
                object.focusData = null;
                object.timerMapInfo = null;
                object.log = null;
            }
            if (message.service != null && message.hasOwnProperty("service"))
                object.service = options.enums === String ? $root.MapCloudProto.ServiceEnum[message.service] === undefined ? message.service : $root.MapCloudProto.ServiceEnum[message.service] : message.service;
            if (message.command != null && message.hasOwnProperty("command"))
                object.command = options.enums === String ? $root.MapCloudProto.CommandEnum[message.command] === undefined ? message.command : $root.MapCloudProto.CommandEnum[message.command] : message.command;
            if (message.loginData != null && message.hasOwnProperty("loginData"))
                object.loginData = $root.MapCloudProto.LoginData.toObject(message.loginData, options);
            if (message.setMapModeData != null && message.hasOwnProperty("setMapModeData"))
                object.setMapModeData = $root.MapCloudProto.SetMapModeData.toObject(message.setMapModeData, options);
            if (message.setMapCenterData != null && message.hasOwnProperty("setMapCenterData"))
                object.setMapCenterData = $root.MapCloudProto.SetMapCenterData.toObject(message.setMapCenterData, options);
            if (message.updateStyleData != null && message.hasOwnProperty("updateStyleData"))
                object.updateStyleData = $root.MapCloudProto.UpdateStyleData.toObject(message.updateStyleData, options);
            if (message.updateIconData != null && message.hasOwnProperty("updateIconData"))
                object.updateIconData = $root.MapCloudProto.UpdateIconData.toObject(message.updateIconData, options);
            if (message.setMapZoomToData != null && message.hasOwnProperty("setMapZoomToData"))
                object.setMapZoomToData = $root.MapCloudProto.SetMapZoomToData.toObject(message.setMapZoomToData, options);
            if (message.timeStamp != null && message.hasOwnProperty("timeStamp"))
                if (typeof message.timeStamp === "number")
                    object.timeStamp = options.longs === String ? String(message.timeStamp) : message.timeStamp;
                else
                    object.timeStamp = options.longs === String ? $util.Long.prototype.toString.call(message.timeStamp) : options.longs === Number ? new $util.LongBits(message.timeStamp.low >>> 0, message.timeStamp.high >>> 0).toNumber() : message.timeStamp;
            if (message.timeStampData != null && message.hasOwnProperty("timeStampData"))
                object.timeStampData = $root.MapCloudProto.TimeStampData.toObject(message.timeStampData, options);
            if (message.surfaceChangeData != null && message.hasOwnProperty("surfaceChangeData"))
                object.surfaceChangeData = $root.MapCloudProto.SetSurfaceChangeData.toObject(message.surfaceChangeData, options);
            if (message.mouseEventData != null && message.hasOwnProperty("mouseEventData"))
                object.mouseEventData = $root.MapCloudProto.SetMouseEventData.toObject(message.mouseEventData, options);
            if (message.mapParameterData != null && message.hasOwnProperty("mapParameterData"))
                object.mapParameterData = $root.MapCloudProto.SetMapParameterData.toObject(message.mapParameterData, options);
            if (message.requestParameter != null && message.hasOwnProperty("requestParameter"))
                object.requestParameter = $root.MapCloudProto.RequestParameterData.toObject(message.requestParameter, options);
            if (message.mapBaseData != null && message.hasOwnProperty("mapBaseData"))
                object.mapBaseData = $root.MapCloudProto.MapBaseData.toObject(message.mapBaseData, options);
            if (message.gestureInfo != null && message.hasOwnProperty("gestureInfo"))
                object.gestureInfo = $root.MapCloudProto.GestureInfo.toObject(message.gestureInfo, options);
            if (message.updateCustomFileConfigData != null && message.hasOwnProperty("updateCustomFileConfigData"))
                object.updateCustomFileConfigData = $root.MapCloudProto.UpdateCustomFileConfigData.toObject(message.updateCustomFileConfigData, options);
            if (message.modifyModelPropertiesData != null && message.hasOwnProperty("modifyModelPropertiesData"))
                object.modifyModelPropertiesData = $root.MapCloudProto.ModifyModelPropertiesData.toObject(message.modifyModelPropertiesData, options);
            if (message.mapControllerData != null && message.hasOwnProperty("mapControllerData"))
                object.mapControllerData = $root.MapCloudProto.MapControllerStates.toObject(message.mapControllerData, options);
            if (message.webRtcParameterData != null && message.hasOwnProperty("webRtcParameterData"))
                object.webRtcParameterData = $root.MapCloudProto.SetWebRtcParameterData.toObject(message.webRtcParameterData, options);
            if (message.mapStatusData != null && message.hasOwnProperty("mapStatusData"))
                object.mapStatusData = $root.MapCloudProto.SetMapStatusData.toObject(message.mapStatusData, options);
            if (message.shellCmdData != null && message.hasOwnProperty("shellCmdData"))
                object.shellCmdData = $root.MapCloudProto.ShellCmdData.toObject(message.shellCmdData, options);
            if (message.webrtcData != null && message.hasOwnProperty("webrtcData"))
                object.webrtcData = $root.MapCloudProto.WebrtcData.toObject(message.webrtcData, options);
            if (message.zoomLevelData != null && message.hasOwnProperty("zoomLevelData"))
                object.zoomLevelData = $root.MapCloudProto.ZoomLevelData.toObject(message.zoomLevelData, options);
            if (message.pitchAngleData != null && message.hasOwnProperty("pitchAngleData"))
                object.pitchAngleData = $root.MapCloudProto.PitchAngleData.toObject(message.pitchAngleData, options);
            if (message.rollAngleData != null && message.hasOwnProperty("rollAngleData"))
                object.rollAngleData = $root.MapCloudProto.RollAngleData.toObject(message.rollAngleData, options);
            if (message.setMapModeStateTime != null && message.hasOwnProperty("setMapModeStateTime"))
                object.setMapModeStateTime = $root.MapCloudProto.SetMapModeStateTime.toObject(message.setMapModeStateTime, options);
            if (message.immersiveData != null && message.hasOwnProperty("immersiveData"))
                object.immersiveData = message.immersiveData;
            if (message.ossKeyData != null && message.hasOwnProperty("ossKeyData"))
                object.ossKeyData = $root.MapCloudProto.OssKeyData.toObject(message.ossKeyData, options);
            if (message.immersiveResponse != null && message.hasOwnProperty("immersiveResponse"))
                object.immersiveResponse = message.immersiveResponse;
            if (message.ossReqData != null && message.hasOwnProperty("ossReqData"))
                object.ossReqData = $root.MapCloudProto.OssReqData.toObject(message.ossReqData, options);
            if (message.traceId != null && message.hasOwnProperty("traceId"))
                object.traceId = message.traceId;
            if (message.videoData != null && message.hasOwnProperty("videoData"))
                object.videoData = $root.MapCloudProto.VideoData.toObject(message.videoData, options);
            if (message.focusData != null && message.hasOwnProperty("focusData"))
                object.focusData = $root.MapCloudProto.FocusData.toObject(message.focusData, options);
            if (message.timerMapInfo != null && message.hasOwnProperty("timerMapInfo"))
                object.timerMapInfo = $root.MapCloudProto.TimerMapInfo.toObject(message.timerMapInfo, options);
            if (message.log != null && message.hasOwnProperty("log"))
                object.log = $root.MapCloudProto.Log.toObject(message.log, options);
            return object;
        };

        /**
         * Converts this RealTimeVisualization to JSON.
         * @function toJSON
         * @memberof MapCloudProto.RealTimeVisualization
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RealTimeVisualization.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RealTimeVisualization
         * @function getTypeUrl
         * @memberof MapCloudProto.RealTimeVisualization
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RealTimeVisualization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MapCloudProto.RealTimeVisualization";
        };

        return RealTimeVisualization;
    })();

    return MapCloudProto;
})();

export { $root as default };
