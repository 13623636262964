import logo from "../assets/logo.png";
import React, {FC} from "react";
import {Flex} from "antd";

type LogoProps = {
    style?: React.CSSProperties,
};

const Logo: FC<LogoProps> = ({style}) => {
    return (<Flex justify={"flex-start"} align={"flex-end"} style={style}>
        <img
            src={logo}
            alt="Logo"
            style={{
                width: '100px',
                opacity: 0.8,
            }}
        />
        <span style={{
            padding: "0px 4px",
            background: "white",
            opacity: 0.4,
            fontSize: "12px",
            color: "black",
        }}>APP平台业务中心-渲染研发部 ©2024
        </span>
    </Flex>);
}

export default Logo