import "webrtc-adapter"
import {MapCloudProto} from "./proto/real_time_visualization";
import {message} from 'antd';

export interface WebSocketConfigInit {
    readonly wsUrl: string;
    readonly sessionId: string;
    readonly center?: Array<number>;
    readonly zoomLevel?: number;
    readonly pitchAngle?: number;
    readonly rollAngle?: number;
    readonly businessWs?: string;
    readonly hookUrl?: string;
    readonly hookType?: number;
}

export class Connect {
    private websocket: WebSocket;
    private webrtc: RTCPeerConnection;
    private datachannel: RTCDataChannel;

    private readonly config: WebSocketConfigInit;
    private useLocalPrefer: boolean = false; // 优先使用外界传进来的数据，而非本地存储的数据

    private webrtcStatsInterval: any;

    public onImmersiveMessage: (message: string) => void;
    public onTimerMapInfoMessage: (timerMapInfo: MapCloudProto.ITimerMapInfo) => void;
    public videoSizeProxy: () => VideoSize = null;
    public onRtcTrackEvent: (event: RTCTrackEvent) => void;
    public rtcTrackEvent: RTCTrackEvent = null;

    constructor(config: WebSocketConfigInit, videoSizeProxy: () => VideoSize) {
        console.log("new Connect...")
        this.config = config;
        this.videoSizeProxy = videoSizeProxy;

        // 监听网络状态变化
        window.addEventListener("online", this.handleNetworkOnline.bind(this));
        window.addEventListener("offline", this.handleNetworkOffline.bind(this));

        // 初始化websocket
        this.connectWebSocket();
    }

    public static getInstance(): Connect {
        if (!window.mapCloudConnect) {
            throw new Error("没有Connect连接实例.");
        }
        return (window.mapCloudConnect as Connect)
    }

    public send(msg: MapCloudProto.IRealTimeVisualization) {
        if (this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.send(MapCloudProto.RealTimeVisualization.encode(msg).finish());
        }
    }

    private connectWebSocket() {
        let localMapInfoString = localStorage.getItem("timerMapInfo");
        let localMapInfo: MapCloudProto.ITimerMapInfo = null;
        if (localMapInfoString !== null) {
            localMapInfo = JSON.parse(localMapInfoString) as MapCloudProto.ITimerMapInfo;
        }
        let center: Array<number>;
        let zoomLevel: number;
        if (!this.useLocalPrefer) {
            center = this.config.center || (localMapInfo ? [localMapInfo.lon, localMapInfo.lat] : null);
            zoomLevel = this.config.zoomLevel || (localMapInfo ? localMapInfo.zoomLevel : null);
        } else {
            center = localMapInfo ? [localMapInfo.lon, localMapInfo.lat] : this.config.center;
            zoomLevel = localMapInfo ? localMapInfo.zoomLevel : this.config.zoomLevel;
        }
        let pitchAngle = localMapInfo ? localMapInfo.pitchAngle : null;
        let rollAngle = localMapInfo ? localMapInfo.rollAngle : null;
        this.useLocalPrefer = true;

        let styleIsDayString = localStorage.getItem("styleIsDay");
        let mapMode = 0;
        let mapState = 0;
        let mapTime = styleIsDayString && styleIsDayString === 'true' ? 0 : 1;

        let network = localStorage.getItem("network");
        let bitrate = localStorage.getItem("bitrate");
        let tmc = localStorage.getItem("tmc");

        let videoSize: VideoSize = this.videoSizeProxy ? this.videoSizeProxy() : {width: 500, height: 800}
        let wsUrl = this.config.wsUrl || "acloudrender.com"

        // web创建ws所有的参数都在这里
        let wsProto = "ws:"
        let protocol = document.location.protocol;
        if (protocol === "https:") {
            wsProto = "wss:";
        }
        let wsConnectUrl = wsProto + "//" + wsUrl + "/api/mapcloud/ws/consumer"
            + "?width=" + videoSize.width
            + "&height=" + videoSize.height
            + "&sessionId=" + this.config.sessionId
            + "&devicePixelRatio=" + 1;
        if (network) {
            wsConnectUrl = wsConnectUrl + "&serverType=" + network;
        }
        if (center && center.length >= 2) {
            wsConnectUrl = wsConnectUrl + "&lon=" + center[0];
            wsConnectUrl = wsConnectUrl + "&lat=" + center[1];
        }
        if (zoomLevel) {
            wsConnectUrl = wsConnectUrl + "&zoomLevel=" + zoomLevel;
        }
        if (pitchAngle) {
            wsConnectUrl = wsConnectUrl + "&pitchAngle=" + pitchAngle;
        }
        if (rollAngle) {
            wsConnectUrl = wsConnectUrl + "&rollAngle=" + rollAngle;
        }
        if (styleIsDayString) {
            wsConnectUrl = wsConnectUrl + "&mapMode=" + mapMode + "&mapState=" + mapState + "&mapTime=" + mapTime;
        }
        if (bitrate) {
            wsConnectUrl = wsConnectUrl + "&fluency=" + bitrate;
        }
        if (tmc) {
            wsConnectUrl = wsConnectUrl + "&tmc=" + (tmc === 'true' ? 1 : 0);
        }
        if (this.config.businessWs) {
            wsConnectUrl = wsConnectUrl + "&businessWs=" + this.config.businessWs;
        }
        if (this.config.hookUrl) {
            wsConnectUrl = wsConnectUrl + "&hookUrl=" + this.config.hookUrl;
        }
        if (this.config.hookType) {
            wsConnectUrl = wsConnectUrl + "&hookType=" + this.config.hookType;
        }

        console.log("wsUrl:" + wsConnectUrl)
        this.websocket = new WebSocket(wsConnectUrl);
        this.websocket.onopen = this.handleWebSocketOnOpen.bind(this);
        this.websocket.onmessage = this.handleWebSocketOnMessage.bind(this);
        this.websocket.onclose = this.handleWebSocketOnClose.bind(this);
        this.websocket.onerror = this.handleWebSocketOnError.bind(this);
    }

    private connectWebRTC() {
        this.webrtc = new RTCPeerConnection({
            iceServers: [
                {
                    urls: 'stun:acloudrender.com:3478'
                }
            ]
        })

        // 配置 DataChannel 为不可靠传输
        const dataChannelOptions = {
            ordered: false,        // 不保证消息顺序
            maxRetransmits: 0      // 不进行重传
        };
        this.datachannel = this.webrtc.createDataChannel('mapinfo', dataChannelOptions)
        this.datachannel.onclose = () => console.log((new Date()).toISOString(), 'webrtc datachannel 关闭')
        this.datachannel.onopen = () => console.log((new Date()).toISOString(), 'webrtc datachannel onopen')
        this.datachannel.onmessage = this.handleWebRtcOnMessage.bind(this);

        this.webrtc.ontrack = this.handleWebRtcOntrack.bind(this);
        this.webrtc.oniceconnectionstatechange = this.handleWebRtcOnIceConnectionStateChange.bind(this);
        this.webrtc.onicecandidate = this.handleWebRtcOnIceCandidate.bind(this);
        this.webrtc.addTransceiver('video', {'direction': 'recvonly'})
        this.webrtc.createOffer().then(d => this.webrtc.setLocalDescription(d))
            .then(this.sendWebRtcOffer.bind(this)).catch(console.log)
        this.webrtcStatsInterval = setInterval(() => {
            this.webrtc.getStats().then((stats) => {
                stats.forEach(report => {
                    if (report.type === 'candidate-pair') {
                        // console.log(`Current Round Trip Time: ${report.currentRoundTripTime} ms`);
                    }
                });
            })
            // console.log('##############################');

        }, 1000)
    }

    private handleWebSocketOnOpen(event: Event) {
        console.log((new Date()).toISOString(), "Websocket Connection onopen");
        this.connectWebRTC()

        // 将当前的网页是否隐藏发送给服务端，网页隐藏后服务端不需要发送视频流回来
        let msg = new MapCloudProto.RealTimeVisualization();
        msg.service = 0;
        msg.command = MapCloudProto.CommandEnum.CommandConsumerFocus;
        let data = new MapCloudProto.FocusData();
        data.focus = !document.hidden;
        msg.focusData = data
        this.send(msg)
    }

    private async handleWebSocketOnMessage(event: MessageEvent) {
        const uint8Array = await this.blobToUint8Array(event.data)
        const msg = MapCloudProto.RealTimeVisualization.decode(uint8Array)
        switch (msg.command) {
            case MapCloudProto.CommandEnum.CommandWebRtcAnswer:
                let answer = JSON.parse(new TextDecoder().decode(msg.webrtcData.data))
                console.log((new Date()).toISOString(), '收到answer: ', answer);
                this.webrtc.setRemoteDescription(answer);
                break;
            case MapCloudProto.CommandEnum.CommandWebRtcCandidateFromService:
                let candidate = JSON.parse(new TextDecoder().decode(msg.webrtcData.data))
                console.log((new Date()).toISOString(), '收到ICE候选: ', candidate);
                this.webrtc.addIceCandidate(candidate);
                break;
            case MapCloudProto.CommandEnum.CommandImmersiveResponse:
                if (this.onImmersiveMessage) {
                    this.onImmersiveMessage(msg.immersiveResponse);
                }
                break;
            case MapCloudProto.CommandEnum.CommandLog:
                if (msg.log.level === MapCloudProto.LogLevel.LogLevelError) {
                    message.error(msg.log.content, 5);
                } else {
                    message.info(msg.log.content, 5);
                }
                break;
            default:
                break;
        }
    }

    private async blobToUint8Array(blob: Blob): Promise<Uint8Array> {
        // 创建一个 FileReader 实例
        const reader = new FileReader();

        // 返回一个 Promise，以便在 FileReader.onload 事件触发时解析结果
        return new Promise((resolve, reject) => {
            // 当读取操作完成时的回调
            reader.onloadend = () => {
                // 获取到的数据是一个 ArrayBuffer
                const arrayBuffer = reader.result as ArrayBuffer;
                if (arrayBuffer) {
                    // 从 ArrayBuffer 创建 Uint8Array
                    const uint8Array = new Uint8Array(arrayBuffer);
                    resolve(uint8Array);
                } else {
                    reject(new Error('Failed to read the blob.'));
                }
            };

            // 开始读取 Blob，指定为 'arraybuffer' 类型
            reader.readAsArrayBuffer(blob);

            // 可以考虑添加 onloaderror 事件处理以捕获读取失败的情况
            reader.onerror = (error) => reject(error);
        });
    }

    private handleNetworkOnline() {
        console.log("网络已连接");

        // 重新连接websocket，断开后会自动重连
        this.websocket.close();
        // this.connectWebSocket();
    }

    private handleNetworkOffline() {
        console.log("网络已断开");
    }

    private handleWebSocketOnClose(event: CloseEvent) {
        console.log((new Date()).toISOString(), 'WebSocket连接关闭，尝试重连...');
        setTimeout(this.connectWebSocket.bind(this), 1000);
    }


    private handleWebSocketOnError(event: Event) {
        console.error((new Date()).toISOString(), 'WebSocket错误: ', event);
    }

    private handleWebRtcOntrack(event: RTCTrackEvent) {
        this.rtcTrackEvent = event;
        if (this.onRtcTrackEvent) {
            this.onRtcTrackEvent(event);
        }
    }

    private handleWebRtcOnMessage(event: MessageEvent) {
        const uint8Array = new Uint8Array(event.data)
        const msg = MapCloudProto.RealTimeVisualization.decode(uint8Array)
        // console.log((new Date()).toISOString(), msg)

        switch (msg.command) {
            case MapCloudProto.CommandEnum.CommandTimerMapInfo:
                if (this.onTimerMapInfoMessage) {
                    this.onTimerMapInfoMessage(msg.timerMapInfo);
                }
                break;
        }
    }

    private handleWebRtcOnIceConnectionStateChange() {
        console.log((new Date()).toISOString(), "WebRTC状态改变", this.webrtc.iceConnectionState)
        if (this.webrtc.iceConnectionState === 'disconnected') {
            console.log((new Date()).toISOString(), 'WebRTC连接断开，尝试重连...');
            setTimeout(() => {
                this.connectWebRTC();
            }, 1000);
        }
    }

    private handleWebRtcOnIceCandidate(event: RTCPeerConnectionIceEvent) {
        console.log((new Date()).toISOString(), '发送ICE候选: ', event.candidate);
        if (event.candidate) {
            let msg = new MapCloudProto.RealTimeVisualization();
            msg.service = 0;
            msg.command = MapCloudProto.CommandEnum.CommandWebRtcCandidateFromClient;
            msg.webrtcData = new MapCloudProto.WebrtcData({
                data: new TextEncoder().encode(JSON.stringify(event.candidate))
            });

            this.send(msg);
        }
    }

    private sendWebRtcOffer() {
        console.log((new Date()).toISOString(), "发送offer", this.webrtc.localDescription)
        let msg = new MapCloudProto.RealTimeVisualization();
        msg.service = 0;
        msg.command = MapCloudProto.CommandEnum.CommandWebRtcOffer;
        msg.webrtcData = new MapCloudProto.WebrtcData({
            data: new TextEncoder().encode(JSON.stringify(this.webrtc.localDescription))
        });

        this.send(msg);
    }
}
