import React, {forwardRef, memo, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Connect, WebSocketConfigInit} from "./Connect"
import {MouseEventHandler} from "./MouseEvent"
import {MapCloudRef, setCenter, setImmersiveData, setZoom, setZoomAndCenter} from "./MapCloudApi";
import {MapCloudProto} from "./proto/real_time_visualization";
import ZoomBar from "./components/ZoomBar";
import {Spin} from "antd";
import Logo from "./components/Logo";
import SettingBar from "./components/SettingBar";
import {VideoPlayer} from "./VideoPlayer";

type MapCloudProps = {
    sessionId: string,
    streamUrl?: string,
    zoomLevel?: number,
    center?: Array<number>,
    zoomBar?: boolean,
    resolutionBar?: boolean,
    settingBar?: boolean,
    businessWs?: string,
    hookUrl?: string,
    hookType?: number,

    onImmersiveMessage?: (msg: string) => void
};

const MapCloud = memo(forwardRef<MapCloudRef, MapCloudProps>((props, ref) => {
    const [timerMapInfo, setTimerMapInfo] = useState<MapCloudProto.ITimerMapInfo>(null);

    const containerRef = useRef<HTMLDivElement>(null)
    const layoutElementRef = useRef<HTMLDivElement>(null)
    const videoElementRef = useRef<HTMLVideoElement>(null)
    const mouseEventHandlerRef = useRef<MouseEventHandler>(null)
    const videoPlayerRef = useRef<VideoPlayer>(null)

    let isTimerMapInfoThrottled = false; // 标志变量

    // 初始化
    useEffect(() => {
        console.log("MapCloud init...")

        // VideoPlayer
        if (!videoPlayerRef.current) {
            videoPlayerRef.current = new VideoPlayer(containerRef.current, layoutElementRef.current, videoElementRef.current);
        }

        // 开始连接
        const webSocketConfig: WebSocketConfigInit = {
            wsUrl: props.streamUrl,
            sessionId: props.sessionId,
            center: props.center,
            zoomLevel: props.zoomLevel,
            businessWs: props.businessWs,
            hookUrl: props.hookUrl,
            hookType: props.hookType
        }
        if (!window.mapCloudConnect) {
            window.mapCloudConnect = new Connect(webSocketConfig, videoPlayerRef.current.getVideoSize.bind(videoPlayerRef.current),);
        }
        let connect: Connect = Connect.getInstance();
        if (props.onImmersiveMessage) {
            connect.onImmersiveMessage = props.onImmersiveMessage;
        }
        connect.onTimerMapInfoMessage = setTimerMapInfo;
        connect.videoSizeProxy = videoPlayerRef.current.getVideoSize.bind(videoPlayerRef.current);

        if (connect.rtcTrackEvent) {
            videoPlayerRef.current.handleWebRtcOntrack(connect.rtcTrackEvent);
        }
        connect.onRtcTrackEvent = videoPlayerRef.current.handleWebRtcOntrack.bind(videoPlayerRef.current);

        // 监听事件
        if (!mouseEventHandlerRef.current) {
            mouseEventHandlerRef.current = new MouseEventHandler(videoElementRef.current, connect);
        }
    }, [])

    // 监听timerMapInfo，保存到localStorage
    useEffect(() => {
        if (timerMapInfo === null) {
            return;
        }

        // 节流
        if (!isTimerMapInfoThrottled) {
            isTimerMapInfoThrottled = true
            setTimeout(() => {
                isTimerMapInfoThrottled = false; // 重置标志
                localStorage.setItem("timerMapInfo", JSON.stringify(timerMapInfo));
            }, 1000);
        }

    }, [timerMapInfo]);

    // 对外接口
    useImperativeHandle(ref, () => ({
        setZoom: (zoom: number) => setZoom(Connect.getInstance(), zoom),
        setCenter: (center: Array<number>) => setCenter(Connect.getInstance(), center),
        setZoomAndCenter: (zoom: number, center: Array<number>) => setZoomAndCenter(Connect.getInstance(), zoom, center),
        setImmersiveData: (data: string) => setImmersiveData(Connect.getInstance(), data),
    }));

    return (
        <div ref={containerRef}
             style={{width: '100%', height: '100%', overflow: 'hidden', margin: 0, padding: 0}}>
            <div ref={layoutElementRef} style={{
                overflow: 'hidden', margin: 0, padding: 0, width: '100%',
                height: '100%', position: 'relative'
            }}>
                <Spin style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}/>
                <video ref={videoElementRef}
                       style={{
                           position: 'absolute',
                           "objectPosition": "top left",
                           // "objectFit": "none",
                           "transform": "scaleY(-1)",
                           width: '100%',
                           height: '100%'
                       }}
                       x5-video-orientation="portraint"
                       x5-video-player-fullscreen="true"
                       webkit-playsinline="true"
                       playsInline
                       muted
                       autoPlay
                />
                <Logo style={{
                    position: 'absolute',
                    bottom: '2px',
                    left: '4px',
                }}/>
                {props.zoomBar ?
                    <ZoomBar
                        timerMapInfo={timerMapInfo}
                        style={{
                            position: 'absolute',
                            bottom: '8px',
                            right: '8px',
                        }}/> : null}
                <SettingBar
                    resolutionBar={props.resolutionBar}
                    settingBar={props.settingBar}
                    style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                    }}
                    onResolutionChange={() => videoPlayerRef.current.resize()}
                />
            </div>
        </div>
    );
}));

export default MapCloud;
export type {MapCloudRef};

