import {MapCloudProto} from "./proto/real_time_visualization"
import {Connect} from "./Connect";

enum MouseEventTypeEnum {
    Down = 0, // 按下
    Up = 1, // 松开
    Drag = 2, // 拖动
    Enter = 3, // 进入
    Leave = 4, // 离开
    Move = 5 // 移动
}

export class MouseEventHandler {
    private videoElement: HTMLElement;
    private connect: Connect;

    private leftButtonClick = false;
    private rightButtonClick = false;


    private isDragging = false;
    private lastClientX: number;
    private lastClientY: number;


    constructor(videoElement: HTMLElement, connect: Connect) {
        this.videoElement = videoElement;
        this.connect = connect;

        videoElement.addEventListener("mousedown", this.handleMouseDown.bind(this));
        videoElement.addEventListener('wheel', this.handleWheel.bind(this));
        videoElement.addEventListener("contextmenu", this.handleContextmenu.bind(this));
        videoElement.addEventListener("dblclick", this.handleDoubleClick.bind(this));

        document.addEventListener('visibilitychange', this.handleVisibilitychange.bind(this));
        document.addEventListener('selectstart', this.handleSelectStart.bind(this));

        window.addEventListener("mousemove", this.handleMouseMove.bind(this));
        window.addEventListener('mouseup', this.handleMouseUp.bind(this));
        window.addEventListener('mouseout', this.handleMouseOut.bind(this));

    }

    private handleContextmenu(event: any) {
        event.preventDefault();
    }

    private handleDoubleClick(event: MouseEvent) {
        event.stopPropagation();

        // 适配y翻转导致的event.offsetY也跟着翻转
        const rect = this.videoElement.getBoundingClientRect()
        let offsetY = rect.height - event.offsetY

        let msg = new MapCloudProto.RealTimeVisualization();
        msg.service = 0;
        msg.command = MapCloudProto.CommandEnum.CommandSetZoomLevel;
        msg.zoomLevelData = new MapCloudProto.ZoomLevelData({
            increment: 1,
            pointX: event.offsetX * 1,
            pointY: offsetY * 1,
            animate: true
        });
        this.connect.send(msg)

    }

    // mousedown,mouseup使用button，其余事件使用buttons
    private handleMouseDown(event: MouseEvent) {
        event.stopPropagation();
        const target = event.target as HTMLElement; // 类型断言为HTMLElement
        let offsetX = event.clientX - target.offsetLeft
        let offsetY = event.clientY - target.offsetTop

        let button = 0;
        if (event.button === 0) {
            this.leftButtonClick = true;
            button = 0;
        } else if (event.button === 2) {
            this.rightButtonClick = true;
            button = 1;
        }
        this.lastClientX = event.clientX;
        this.lastClientY = event.clientY;

        this.doMoveEvent(button, MouseEventTypeEnum.Down, offsetX, offsetY)
        this.isDragging = true;
    }

    private handleMouseMove(event: MouseEvent) {
        const target = event.target as HTMLElement; // 类型断言为HTMLElement
        let offsetX = event.clientX - target.offsetLeft
        let offsetY = event.clientY - target.offsetTop

        if (this.leftButtonClick) {
            let button = 0;
            if (event.buttons === 1) {
                button = 0;
            } else if (event.buttons === 2) {
                button = 1;
            }
            if (this.lastClientX != event.clientX || this.lastClientY != event.clientY) {
                this.doMoveEvent(button, MouseEventTypeEnum.Drag, offsetX, offsetY)
            }
        } else if (this.rightButtonClick) {
            let incrementX = this.lastClientX - event.clientX;
            let incrementY = this.lastClientY - event.clientY;
            // console.log("increment:" + incrementX + ", " + incrementY)

            if (Math.abs(incrementX) > Math.abs(incrementY)) {
                if (offsetY > this.videoElement.clientHeight / 2) {
                    incrementX = -incrementX;
                }
                let msg = new MapCloudProto.RealTimeVisualization();
                msg.service = 0;
                msg.command = MapCloudProto.CommandEnum.CommandSetRollAngle;
                msg.rollAngleData = new MapCloudProto.RollAngleData({
                    increment: incrementX / 4
                });
                this.connect.send(msg)

            } else {
                let msg = new MapCloudProto.RealTimeVisualization();
                msg.service = (0);
                msg.command = MapCloudProto.CommandEnum.CommandSetPitchAngle;
                msg.pitchAngleData = new MapCloudProto.PitchAngleData({
                    increment: incrementY / 4
                });
                this.connect.send(msg)
            }
        }
        this.lastClientX = event.clientX;
        this.lastClientY = event.clientY;
    }

    private handleMouseUp(event: MouseEvent) {
        const target = event.target as HTMLElement; // 类型断言为HTMLElement
        let offsetX = event.clientX - target.offsetLeft
        let offsetY = event.clientY - target.offsetTop

        if (this.isDragging) {
            this.isDragging = false;

            if (event.button === 0) {
                this.leftButtonClick = false;
            } else if (event.button === 2) {
                this.rightButtonClick = false;
            }

            this.isDragging = false;
            this.doHandleMouseUp(event, offsetX, offsetY)
        }
    }

    private handleMouseOut(event: MouseEvent) {
        const target = event.target as HTMLElement; // 类型断言为HTMLElement
        let offsetX = event.clientX - target.offsetLeft
        let offsetY = event.clientY - target.offsetTop

        // 左键在窗口外仍可拖动
        if (event.buttons === 1) {
            return;
        }

        // 只有由body到外部，才应用该事件
        if (event.relatedTarget != null) {
            return;
        }

        // 只有右键，在窗口外停止拖动
        if (this.isDragging) {
            this.isDragging = false;

            // 1左建，2右键，可多个组合，但是目前场景只单键
            if (event.buttons === 1) {
                this.leftButtonClick = false;
            } else if (event.buttons === 2) {
                this.rightButtonClick = false;
            }

            this.isDragging = false;
            this.doHandleMouseUp(event, offsetX, offsetY)
        }
    }

    private handleVisibilitychange() {
        console.log('页面激活状态:' + !document.hidden);
        let msg = new MapCloudProto.RealTimeVisualization();
        msg.service = 0;
        msg.command = MapCloudProto.CommandEnum.CommandConsumerFocus;
        let data = new MapCloudProto.FocusData();
        data.focus = !document.hidden;
        msg.focusData = data
        this.connect.send(msg)
    }

    private handleWheel(event: WheelEvent) {
        event.preventDefault();

        // 适配y翻转导致的event.offsetY也跟着翻转
        const rect = this.videoElement.getBoundingClientRect()
        let offsetY = rect.height - event.offsetY

        // console.log(rect, event.offsetX, event.offsetY, offsetY)

        let increment = event.deltaY * -0.01;
        let msg = new MapCloudProto.RealTimeVisualization();
        msg.service = 0;
        msg.command = MapCloudProto.CommandEnum.CommandSetZoomLevel;
        msg.zoomLevelData = new MapCloudProto.ZoomLevelData({
            increment: increment,
            pointX: event.offsetX * 1,
            pointY: offsetY * 1
        });
        this.connect.send(msg)
    }

    private handleSelectStart(event: Event) {
        event.preventDefault();
    }

    private doHandleMouseUp(event: MouseEvent, pointX: number, pointY: number) {
        let button = 0;
        if (event.button === 0) {
            button = 0;
        } else if (event.button === 2) {
            button = 1;
        }
        this.isDragging = false;
        this.doMoveEvent(button, MouseEventTypeEnum.Up, pointX, pointY)
        // console.log("up event")
    }

    // 设置移图
    private doMoveEvent(button: number, type: MouseEventTypeEnum, pointX: number, pointY: number) {
        let msg = new MapCloudProto.RealTimeVisualization();
        msg.service = MapCloudProto.ServiceEnum.ServiceMap;
        msg.command = MapCloudProto.CommandEnum.CommandMouseEvent;
        msg.mouseEventData = new MapCloudProto.SetMouseEventData({
            type: type,
            point: new MapCloudProto.GPoint({x: Math.round(pointX), y: Math.round(pointY)}),
            speed: new MapCloudProto.GPoint({x: 0, y: 0}),
            button: button
        });

        this.connect.send(msg)
    }
}



