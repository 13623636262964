import {Connect} from "./Connect";
import {MapCloudProto} from "./proto/real_time_visualization";

export interface MapCloudRef {
    setZoom: (zoom: number) => void;
    setCenter: (center: Array<number>) => void;
    setZoomAndCenter: (zoom: number, center: Array<number>) => void;

    setImmersiveData: (data: string) => void;
}

export function setZoom(connect: Connect, zoom: number) {
    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = MapCloudProto.ServiceEnum.ServiceMap;
    msg.command = MapCloudProto.CommandEnum.CommandSetZoomLevel;
    msg.zoomLevelData = new MapCloudProto.ZoomLevelData({
        level: zoom,
        animate: true,
    });
    connect.send(msg);
}

export function setCenter(connect: Connect, center: Array<number>) {
    if (center.length < 2) {
        console.error("出入的center参数错误，数组个数必须为2", center)
        return
    }
    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = MapCloudProto.ServiceEnum.ServiceMap;
    msg.command = MapCloudProto.CommandEnum.CommandSetMapCenter;
    msg.setMapCenterData = new MapCloudProto.SetMapCenterData({
        lon: center[0],
        lat: center[1],
    });
    connect.send(msg);
}

export function setZoomAndCenter(connect: Connect, zoom: number, center: Array<number>) {
    if (center.length < 2) {
        console.error("出入的center参数错误，数组个数必须为2", center)
        return
    }
    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = MapCloudProto.ServiceEnum.ServiceMap;
    msg.command = MapCloudProto.CommandEnum.CommandSetMapCenter;
    msg.setMapCenterData = new MapCloudProto.SetMapCenterData({
        lon: center[0],
        lat: center[1],
        level: zoom
    });
    connect.send(msg);
}

export function setImmersiveData(connect: Connect, data: string) {
    let msg = new MapCloudProto.RealTimeVisualization();
    msg.service = MapCloudProto.ServiceEnum.ServiceMap;
    msg.command = MapCloudProto.CommandEnum.CommandImmersiveBusiness;
    msg.immersiveData = data;
    connect.send(msg);
}