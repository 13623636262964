import {MapCloudProto} from "./proto/real_time_visualization";
import {Connect} from "./Connect";

export class VideoPlayer {
    private container: HTMLElement;
    private layoutElement: HTMLElement;
    private videoElement: HTMLVideoElement;

    private resizeObserver: ResizeObserver;
    private isThrottled = false; // 节流标志变量

    constructor(container: HTMLElement, layoutElement: HTMLElement, videoElement: HTMLVideoElement) {
        this.container = container;
        this.layoutElement = layoutElement;
        this.videoElement = videoElement;

        this.resizeObserver = new ResizeObserver(this.handleResizeObserver.bind(this));

        // 开始观察该元素
        this.resizeObserver.observe(container);
    }

    public getVideoSize(): VideoSize {
        let videoSizeString = localStorage.getItem("resolution");
        let videoSize: VideoSize = {width: 10000, height: 10000};
        if (videoSizeString) {
            videoSize = JSON.parse(videoSizeString) as VideoSize;
        }

        // 获取容器宽高
        let clientWidth = this.container.clientWidth
        let clientHeight = this.container.clientHeight
        console.log("容器宽高:", this.container.clientWidth, this.container.clientHeight,
            "地图宽高：", clientWidth, clientHeight)

        return {
            width: Math.min(videoSize.width, clientWidth),
            height: Math.min(videoSize.height, clientHeight)
        };
    }

    public resize(): void {
        // 获取容器宽高
        let videoSize = this.getVideoSize()

        let msg = new MapCloudProto.RealTimeVisualization();
        msg.service = 0;
        msg.command = MapCloudProto.CommandEnum.CommandSurfaceChange;
        msg.surfaceChangeData = new MapCloudProto.SetSurfaceChangeData({
            width: videoSize.width,
            height: videoSize.height,
            devicePixelRatio: 1
        });
        Connect.getInstance().send(msg)
    }

    private handleResizeObserver(entries: ResizeObserverEntry[], observer: ResizeObserver) {
        // 节流
        if (!this.isThrottled) {
            this.isThrottled = true
            setTimeout(() => {
                this.isThrottled = false; // 重置标志
                this.resize();
            }, 1000);
        }
    }

    public handleWebRtcOntrack(event: RTCTrackEvent) {
        this.videoElement.srcObject = event.streams[0]

        // 当视频流开始播放时请求每帧的回调
        this.videoElement.onloadedmetadata = () => {
            this.videoElement.requestVideoFrameCallback(this.requestVideoFrameCallBack.bind(this))
        };
    }

    private requestVideoFrameCallBack(now: DOMHighResTimeStamp, metadata: VideoFrameCallbackMetadata) {
        const frameWidth = metadata.width;  // 获取视频帧宽度
        const frameHeight = metadata.height; // 获取视频帧高度

        // 获取设备像素比
        const pixelRatio = 1;

        // 根据设备像素比调整可视宽高
        const newWidth = frameWidth / pixelRatio;
        const newHeight = frameHeight / pixelRatio;

        // 设置视频的显示尺寸
        this.videoElement.style.width = `${newWidth}px`;
        this.videoElement.style.height = `${newHeight}px`;
        this.videoElement.width = frameWidth;
        this.videoElement.height = frameHeight;

        this.layoutElement.style.width = `${newWidth}px`;
        this.layoutElement.style.height = `${newHeight}px`;

        this.videoElement.requestVideoFrameCallback(this.requestVideoFrameCallBack.bind(this))
    }
}